import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../../utils/contexts';
import Breadcrumb from '../common/Breadcrumb';
import CategoryProductSection from './component/CategoryProductSection';
import CategorySelectionModal from './component/CategorySelectionModal';
import CategorySelectionSideBar from './component/CategorySelectionSideBar';

//To-do SEO adding!!
const CategoryProducts = () => {
    const storeData = useStore();
    const [breadcrumbData, setBreadcrumbData] = useState([{ 
            name: "Home",
            link: "/"
        }]);
    const params = useParams();

    useEffect(() => {
        if(params && params.category){
            setBreadcrumbData([
                { 
                    name: "Home",
                    link: "/"
                },
                { 
                    name: params.category,
                    link: "",
                }
            ]);
        }
    }, [params]);

    return ( <>
        {storeData && storeData.data &&
        <>
            <Breadcrumb data={breadcrumbData}/>
            <div className="mt-5">
                <div className="d-md-none my-4">
                    <CategorySelectionModal categoryList={storeData.data.categories} selectedCateName={params.category} selectedCateId={params.id}/>
                </div>
                <div className='row mx-1 mx-xs-2 mx-md-3 mx-lg-5'>
                    <div className='d-none d-md-block col-5 col-lg-4 ps-lg-4'>
                        <CategorySelectionSideBar categoryList={storeData.data.categories}/>
                    </div>
                    <div className='col-12 col-md-7 col-lg-8'>
                        <CategoryProductSection categoryId={params.id}/>
                    </div>
                </div>
            </div>
        </>}
    </>)
}

export default CategoryProducts