import React from "react";
import { Link } from "react-router-dom";

const BrandCard = ({ brand, className }) => {
  return (
    <div
      className={`card shadow rounded p-2 m-0 mx-sm-2 card-animation brand-container ${
        className ? "mx-1" : "mx-auto"
      } ${className}`}
    >
            <Link className='card-link' to={'/brands/' + brand.name+ '/' + brand.id}>
        <div>
          <div className="brand-image-container">
            <img
              className="brand-image"
              src={brand.icon}
              alt={brand.name}
            />
          </div>
          
        </div>
      </Link>
    </div>
  );
};

export default BrandCard;
