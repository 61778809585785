import React, { useEffect, useRef, useState } from 'react';
import { CalendarOutline, CallOutline, MailOutline, PersonOutline } from 'react-ionicons';
import useAPI from '../../utils/api';
import { useLoader } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import { advEmailValidation, alphaValidation, phoneValidation } from '../../utils/validators';
import Breadcrumb from '../common/Breadcrumb';
import Loader from '../common/Loader';
import ProfileSideBar from './ProfileSideBar';

const Profile = () => {
    const API = useAPI();
    const loader = useLoader();
    const ModalCloseButtonRef = useRef();
    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        dob: null,
        phone: "",
        id: ""
    });
    const [update, setUpdate] = useState(false);
    const today = new Date().toISOString().split('T')[0];
    const {data, status, loading} = useFetch("currentUser", update);
    const [flag,setFlag]=useState(true);
    const [addressValidation, setAddressValidation] = useState({
        first_name: "",
        last_name:"",
        phone:"",
        email:""
       
       
    });

    useEffect(() => {
        if(!loading && status === 'fetched' && data.data){
            setUserData({
                first_name: data.data.first_name,
                last_name: data.data.last_name,
                email: data.data.email,
                dob: data.data.dob,
                phone: data.data.phone,
                id: data.data.id
            })
        }
    }, [data, status, loading,flag])
    const checkValidation = () => {
        let isValid = true;
        let temp = {...addressValidation};
         
        if(!userData.first_name || !alphaValidation(userData.first_name)){
            temp.first_name = "First Name is required and must have only alphabets."
            isValid = false;
        }else{
            temp.first_name = "";
        }
         
        if(!userData.last_name || !alphaValidation(userData.last_name)){
            temp.last_name = "last Name is required and must have only alphabets."
            isValid = false;
        }else{
            temp.last_name = "";
        }
       
       
        if(!userData.phone || userData.phone.length !== 10 || !phoneValidation(userData.phone)){
            temp.phone = "Phone number is required and must have 10 numeric digits."
            isValid = false;
        }else{
            temp.phone = ""
        }
        
        if(!userData.email || advEmailValidation(userData.email)){
            temp.email = "Please enter a valid email."
            isValid = false;
        }else{
            temp.email = ""
        }
      

        setAddressValidation(temp);
      

        return isValid;
    }

    const handleEditProfile = async () => {
        loader.setLoaderOpen(true);
        let isValid = checkValidation();
        if(isValid){
        const [ok, response] = await API.editProfile({
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            profile: {
                dob: userData.dob,
                phone: userData.phone,
                user_id: userData.id
            },
            id: userData.id
        });
        if(ok){
            setUpdate(!update);
            setUserData({
                first_name: "",
                last_name: "",
                email: "",
                dob: null,
                phone: "",
                id: ""
            });
            ModalCloseButtonRef.current.click();
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        // loader.setLoaderOpen(false);
    }
    loader.setLoaderOpen(false);
    }

    return (<>
        <Loader isOpen={loading}/>
        <Breadcrumb data={[
            { 
                name: "Home",
                link: "/"
            },
            { 
                name: "Profile",
                link: "",
            }
        ]}/>
        <div className='row w-100 m-0'>
            <div className='col-3'>
                <ProfileSideBar/>
            </div>
            <div className='col-12 col-sm-9 p-0'>
                <div className='d-flex justify-content-between mx-5'>
                    <h3>Profile</h3>
                    <button className='btn web-secondary-color-button d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#editProfile"><ion-icon name='Create-outline' class="web-secondary-icon-color" style={{fontSize: '22px'}}/><span className='ms-1'>Edit</span></button>
                </div>
                <hr className='mx-5'/>
                {!loading && status === 'fetched' && data.data && <div>
                    <div className="mx-5">
                            <ol className="list-group">
                                <li className='list-group-item'>
                                    <p>
                                        <span className="mx-1"><PersonOutline/></span>
                                        <span>{data.data.first_name ? data.data.first_name : ""} {data.data.last_name ? data.data.last_name : ""}</span>
                                    </p>
                                </li>
                                <li className='list-group-item'>
                                    <p>
                                        <span className="mx-1"><MailOutline/></span>
                                        <span>{data.data.email ? data.data.email : ""}</span>
                                    </p>
                                </li>
                                <li className='list-group-item'>
                                    <p>
                                        <span className="mx-1"><CallOutline/></span>
                                        <span>{data.data.phone ? data.data.phone : ""}</span>
                                    </p>
                                </li>
                                <li className='list-group-item'>
                                    <p>
                                        <span className="mx-1"><CalendarOutline/></span>
                                        <span>{data.data.dob ? data.data.dob : ""}</span>
                                    </p>
                                </li>
                            </ol>
                    </div>
                </div>
                }
            </div>
        </div>

        <div className="modal fade" id="editProfile" tabindex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Profile</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e=> {setFlag(prev=>!prev); setUserData({
                        first_name: "",
                        last_name: "",
                        email: "",
                        dob: null,
                        phone: "",
                        id: ""
                    }); setAddressValidation({
                        first_name:"",
                        last_name:"",
                        email:"",
                        phone:""
                     })
                     }}></button>
                </div>
                <div className="modal-body">
                    <div className='row'>
                        <div className='col-md-6 mb-1'>
                            <label>First Name:</label>
                            <div className="input-group">
                                <span className="input-group-text"><ion-icon name="person"></ion-icon></span>
                                <input type="text" value={userData.first_name} onChange={e=>{
                                    setUserData({...userData, first_name: e.target.value})
                                }} placeholder='Enter First Name' className="form-control" required/>
                            </div>
                            {addressValidation.first_name && <p className='text-danger'>{addressValidation.first_name}</p>}
                        </div>
                        <div className='col-md-6 mb-1'>
                            <label>Last Name:</label>
                            <div className="input-group">
                                <span className="input-group-text"><ion-icon name="person"></ion-icon></span>
                                <input type="text" value={userData.last_name} onChange={e=>{
                                    setUserData({...userData, last_name: e.target.value})
                                }} placeholder='Enter Last Name' className="form-control" required/>
                            </div>
                            {addressValidation.last_name && <p className='text-danger'>{addressValidation.last_name}</p>}
                        </div>
                    </div>
                    <div className='mb-1'>
                        <label>Email:</label>
                        <div className="input-group">
                            <span className="input-group-text"><ion-icon name="mail"></ion-icon></span>
                            <input type="email" value={userData.email} onChange={e=>{
                                    setUserData({...userData, email: e.target.value})
                                }} placeholder='Enter Email' className="form-control" required/>
                        </div>
                        {addressValidation.email && <p className='text-danger'>{addressValidation.email}</p>}
                    </div>
                    <div className='mb-1'>
                        <label>Phone:</label>
                        <div className="input-group">
                            <span className="input-group-text"><ion-icon name="call"></ion-icon></span>
                            <input type="tel" value={userData.phone} onChange={e=>{
                                    setUserData({...userData, phone: e.target.value})
                                }} placeholder='Enter Phone Number' className="form-control" required/>
                        </div>
                        {addressValidation.phone && <p className='text-danger'>{addressValidation.phone}</p>}
                    </div>
                    <div className='mb-1'>
                        <label>Date of Birth:</label>
                        <div className="input-group">
                            <span className="input-group-text"><ion-icon name="calendar"></ion-icon></span>
                            <input type="date" value={userData.dob} onChange={e=>{
                                    setUserData({...userData, dob: e.target.value})
                                }} placeholder='yyyy-mm-dd' className="form-control picker" max={today} required/>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" ref={ModalCloseButtonRef} className="btn btn-secondary" data-bs-dismiss="modal" onClick={e=> {setFlag(prev=>!prev);setUserData({
                        first_name: "",
                        last_name: "",
                        email: "",
                        dob: null,
                        phone: "",
                        id: ""
                    });
                    setAddressValidation({
                       first_name:"",
                       last_name:"",
                       email:"",
                       phone:""
                    });
                    }}
                     >Cancel</button>
                    <button type="button"  className="btn btn-primary" onClick={e=> {handleEditProfile()}}>Save</button>
                </div>
                </div>
            </div>
        </div>
    </>)
}

export default Profile