import React from 'react';
import { Add, InformationCircle, Remove, TrashOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import useAPI from '../../../utils/api';
import { useCart, useLoader } from '../../../utils/contexts';
import { getCurrencySymbol } from '../../../utils/currency';

const CartTable = ({className}) => {
    const cartData = useCart();
    const API = useAPI();
    const loader = useLoader();

    const postCartData = async (item, variant_index, quantity) =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.addProductInCart(
            {
                product: item,
                variant_index: variant_index,
                qty: quantity,
            },
        );
        if(ok && response){
            cartData.setMyCartData(response.data);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    const deleteCartProduct = async (id, variant_index) =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.deleteProductInCart({
            product_id: id,
            variant_index: variant_index,
        });
        if(ok && response){
            cartData.setMyCartData(response.data);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    return (
        <div className={`table-responsive-md px-1 px-md-4 mt-5 border rounded mx-3 ${className}`}>
        <table className="table table-striped table-hover">
            <thead>
                <tr className='bg-dark-subtle'>
                    <th scope="col" className='text-center text-uppercase'>Image</th>
                    <th scope="col" className='text-center text-uppercase'>Product Name</th>
                    <th scope="col" className='text-center text-uppercase'>Price</th>
                    <th scope="col" className='text-center text-uppercase'>Taxes</th>
                    <th scope="col" className='text-center text-uppercase'>Quantity</th>
                    <th scope="col" className='text-center text-uppercase'>Remove</th>
                    <th scope="col" className='text-center text-uppercase'>Total</th>
                </tr>
            </thead>
            <tbody>
                {cartData.myCartData.products.map(product =><tr className='py-2'>
                    <td className='text-center'>
                        <Link to={"/product/" + product.name.replace(/[^a-zA-Z0-9]/g, "") + "/" + product.id}>
                            <img src={product.variants && product.variants[0].image_url_original} alt='product' width='160px' height='160px'/>
                        </Link>
                    </td>
                    <td className='text-center align-middle'>
                        <Link className="text-decoration-none text-dark" to={"/product/" + product.name.replace(/[^a-zA-Z0-9]/g, "") + "/" + product.id }>
                            <p className="h6 text-wrap">{product.name}</p>
                            {product.variants[product.variant_index].attribute_value_mappings && product.variants[product.variant_index].attribute_value_mappings.map(attribute => <p className="mb-1">
                                {attribute.attribute_name} : {attribute.attribute_value}
                            </p> )}
                        </Link>
                    </td>
                    <td className='text-center align-middle'>
                        <p>
                        {getCurrencySymbol()} {product.variants[product.variant_index].mrp}
                        </p>
                    </td>
                    <td className='text-center align-middle'>
                        {product.product_taxes != null && product.taxes ? <span>{product.taxes.toFixed(2)}</span> : <span>0</span> }
                              {product.product_taxes != null && (
                                <>
                                  <div data-bs-toggle="tooltip" data-bs-placement="bottom" title={`
                                  ${product.product_taxes.map((taxItem) => 
                                      `${taxItem.name}: ${taxItem.method==="FLAT" ? getCurrencySymbol() : "" } ${taxItem.value} ${taxItem.method==="PERCENT" ? "%":"" }`
                                    )}
                                    `}>
                                    <InformationCircle />
                                  </div>
                                </>
                              )}
                    </td>
                    <td className='align-middle'>
                        <div className='mx-auto' style={{width: "180px"}}>
                            <div className='input-group'>
                            {(product.product_type=="PHYSICAL" || product.product_type==null) &&  <button type='button' disabled={(product.qty === 1 || (product.variants[product.variant_index].min_order_quantity != null && product.variants[product.variant_index].min_order_quantity != 0 && product.qty <= product.variants[product.variant_index].min_order_quantity)) ? true : false} className='btn border-dark' onClick={() => postCartData(product, product.variant_index, (product.qty - 1))}>
                                    <Remove/>
                                </button>
}
                                <input type='number' name='quantity' value={product.qty} onChange={(e) => {
                                    let value;
                                    if ((product.variants[product.variant_index].available_quantity !== null) && (e.target.value > product.variants[product.variant_index].available_quantity)) {
                                        value = product.variants[product.variant_index].available_quantity
                                    } else if(product.variants[product.variant_index].min_order_quantity != null && product.variants[product.variant_index].min_order_quantity != 0 && e.target.value > product.variants[product.variant_index].min_order_quantity) {
                                        value = product.variants[product.variant_index].min_order_quantity
                                    } else if(product.variants[product.variant_index].max_order_quantity != null && product.variants[product.variant_index].max_order_quantity != 0 && e.target.value < product.variants[product.variant_index].max_order_quantity) {
                                        value = product.variants[product.variant_index].max_order_quantity
                                    } else {
                                        value = e.target.value;
                                    }
                                    postCartData(product, product.variant_index, Math.max(1, parseInt(value)))
                                    }} className='form-control text-center'
                                     disabled={product.product_type=='DIGITAL'}
                                    />
{ (product.product_type=="PHYSICAL" || product.product_type==null) &&  <button className='btn border-dark' onClick={() => postCartData(product, product.variant_index, (product.qty + 1))} disabled={((product.variants[product.variant_index].max_order_quantity != null && product.variants[product.variant_index].max_order_quantity != 0 && product.qty >= product.variants[product.variant_index].max_order_quantity) || (product.variants[product.variant_index].available_quantity !== null && product.qty >= product.variants[product.variant_index].available_quantity)) ? true : false}>
                                        <Add />
                                    </button> }
                                
                                </div>

                                <div>
                                    {product.variants[product.variant_index].min_order_quantity != null && product.variants[product.variant_index].max_order_quantity != null && product.variants[product.variant_index].min_order_quantity != 0 && product.variants[product.variant_index].max_order_quantity != 0 && <div>{`allowed ${product.variants[product.variant_index].min_order_quantity} to ${product.variants[product.variant_index].max_order_quantity}`}</div>}
                                  </div>
                                  <div>
                                    {(product.variants[product.variant_index].max_order_quantity == null || product.variants[product.variant_index].max_order_quantity == 0 ) && product.variants[product.variant_index].min_order_quantity != 0 && product.variants[product.variant_index].min_order_quantity != null  && <div>{`allowed min ${product.variants[product.variant_index].min_order_quantity} `}</div>}
                                  </div>
                                  <div>
                                    {(product.variants[product.variant_index].min_order_quantity === null || product.variants[product.variant_index].min_order_quantity == 0) && product.variants[product.variant_index].max_order_quantity != null   && product.variants[product.variant_index].max_order_quantity != 0  && <div>{`allowed max ${product.variants[product.variant_index].max_order_quantity} `}</div>}
                                  </div>
                              </div>
                    </td>
                    <td className='text-center align-middle'><button className='btn btn-danger' onClick={e=> {deleteCartProduct(product.id, product.variant_index)}}><TrashOutline color="#FFF"/></button></td>
                    <td className='text-center align-middle'><p className="h4">
                    {getCurrencySymbol()}{" "}
                                  {product.totalcost ? product.totalcost.toFixed(2) : product.sum.toFixed(2)}
                              </p></td>

                    </tr>)}
            </tbody>
        </table>
        </div>
    )
}

export default CartTable