import { Splide, SplideSlide } from '@splidejs/react-splide';
import React from 'react';
import styled from "styled-components";
import { useWindowSize } from '../../utils/useWindowSize';
import SmallCategory from './cards/SmallCategory';

const SubcategoryList = ({categories, color, primary_color, secondary_color}) => {
    const [width, height] = useWindowSize();

    const subcategoryListSettings = {
        pagination: false,
        drag: 'free',
        snap: true,
        perMove: 1,
        perPage: 7,
        padding: "50px",
        autoplay: true,
        focus  : 'center',
        breakpoints: {
            1590: {
                perPage: 7
            },
            1460: {
                perPage: 6
            },
            1300: {
                perPage: 5
            },
            1100: {
                perPage: 4
            },
            665: {
                perPage: 3
            },
            530: {
                perPage: 2
            },
            520: {
                perPage: 3
            },
            490: {
                perPage: 2
            },
            371: {
                perPage: 1
            }
        },
    };

    const isSlideNeeded = () => {
        const arr = Object.keys(subcategoryListSettings.breakpoints).reverse();
        let result;
        for(let i=0; i < arr.length; i++){
            if(width >= arr[i]){
                result = subcategoryListSettings.breakpoints[arr[i]].perPage;
                break;
            }
        }
        return result;
    }

    const SubCateContainer = styled.div`
        & .splide__arrow{
            background-color: ${(primary_color || (color && color.primary_color)) + '!important'};
        }
        & .splide__arrow svg{
            fill: white;
        }
    `;

    return (
        <SubCateContainer>
            {categories && categories.map(value => value.subCategory && value.subCategory.length !== 0 && <div className='my-5'>
                <h4 className='ms-4 ms-sm-5 border-bottom pb-1'>{value.name}</h4>
                {value.subCategory.length -1 <= isSlideNeeded() ? 
                <div className="d-flex mx-4 mx-sm-5 my-4">
                    {value.subCategory.map(subCate => <SmallCategory key={subCate.id} categoryData={subCate} isSizeBigger={true}/>)} 
                </div>
                : 
                <Splide options={subcategoryListSettings}>
                    {value.subCategory.map(subCate => <SplideSlide><SmallCategory key={subCate.id} categoryData={subCate} isSizeBigger={true}/></SplideSlide>)}
                </Splide>}
            </div>)}
        </SubCateContainer>
    )
}

export default SubcategoryList