import React, { useEffect, useState } from 'react';
import { ChevronBack, ChevronForward, Heart, Star, Trash } from 'react-ionicons';
import { Link, useHistory } from 'react-router-dom';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import useAPI from '../../../utils/api';
import { useLoader } from '../../../utils/contexts';
import { getCurrencySymbol } from '../../../utils/currency';
import { getAuthToken } from '../../../utils/storageService';
import useFetch from '../../../utils/useFetch';
import Loader from '../../common/Loader';
import { Avatar } from '@material-ui/core';
import ComplimentaryProduct from './ComplimentaryProduct';

const ProductData = ({ product, cart }) => {
    const API = useAPI();
    const loader = useLoader();
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [isWishlisted, setIsWishlisted] = useState(false);
    const [ratings, setRatings] = useState(null);
    const [variantIndex, setVariantIndex] = useState(0);
    const [variantMapping, setVariantMapping] = useState([]);
    const [productInCart, setProductInCart] = useState(false);
    const [qty, setQty] = useState(null);
    const [ShowValue, SetShowValue] = useState(false);
    const history = useHistory();
    const { data, status, loading } = useFetch("produtMetaDataWithAuth", product.id, variantIndex);
    useEffect(() => {
        if (product.attributes) {
            if (product.variants[variantIndex].attribute_value_mappings) {
                setSelectedAttributes(prev => {
                    let value = {};
                    product.variants[variantIndex].attribute_value_mappings.forEach(attr => {
                        value[attr.attribute_name] = attr.attribute_value
                    });
                    return value;
                });
            }
            setVariantMapping(prev => product.variants.map(value => (value.attribute_value_mappings.map(attribute => attribute.attribute_value)).toString()));
        }
    }, [product]);

    useEffect(() => {
        if (cart && cart.myCartData && cart.myCartData.products) {
            const filterProduct = cart.myCartData.products.find((result) => result.id === product.id && result.variant_index === variantIndex);
            if (filterProduct) {
                setProductInCart(true);
                setQty(filterProduct.qty);
            } else {
                setProductInCart(false);
                setQty(null);
            }
        }
    }, [cart, variantIndex]);

    useEffect(() => {
        // Show complimentary product if the main product is in the cart
        if (productInCart) {
            SetShowValue(true);
        }
        else SetShowValue(false);
    }, [productInCart]);

    const HandleOnClick = () => {
        if (product && product.complementary_products && product.complementary_products.length) {
            window.scrollTo({
                top: 750,           // Scroll down by 700px from the top position
                behavior: 'smooth', // Smooth scrolling animation
            });
        }
    }

    useEffect(() => {
        if (!loading && status === "fetched" && data) {
            setIsWishlisted(data.wishlisted);
            setRatings(data.rating)
        }
    }, [data, status, loading]);

    const addorDeleteWishlist = async () => {
        loader.setLoaderOpen(true);
        const [ok, response] = isWishlisted ? await API.deleteWishlist(
            {
                product_id: product.id,
                variant_index: variantIndex
            }
        ) :
            await API.addWishlist(
                {
                    product_id: product.id,
                    variant_index: variantIndex
                },
            );
        if (ok && response) {
            if (response.status === "add" || response.status === "remove") {
                if (response.status === "add") {
                    setIsWishlisted(true)
                }
                if (response.status === "remove") {
                    setIsWishlisted(false)
                }
            }
        } else {
            if (response && response.error) {
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    const handleVariantChange = async (name, value) => {
        let temp = { ...selectedAttributes };
        temp[name] = value;
        let index = variantMapping.indexOf(Object.values(temp).toString());
        setSelectedAttributes(temp);
        setVariantIndex(index);
    }

    const postCartData = async (item, variant_index, quantity) => {
        loader.setLoaderOpen(true);
        const [ok, response] = await API.addProductInCart(
            {
                product: item,
                variant_index: variant_index,
                qty: quantity,
            },
        );
        if (ok && response) {
            setQty(quantity);
            setProductInCart(true);
            cart.setMyCartData(response.data);
        } else {
            if (response && response.error) {
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    const deleteCartProduct = async (id, variant_index) => {
        loader.setLoaderOpen(true);
        const [ok, response] = await API.deleteProductInCart({
            product_id: id,
            variant_index: variant_index,
        });
        if (ok && response) {
            setQty(null);
            setProductInCart(false);
            cart.setMyCartData(response.data);
        } else {
            if (response && response.error) {
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    const handleBuyNow = async (product, variantIndex) => {
        if (getAuthToken()) {
            await postCartData(product, variantIndex, 1);
            history.push("/cart");
        } else {
            history.push("/login");
        }
    }

    return (<>
        <Loader isOpen={loading} />
        {product && <div className="ms-md-5 col-md-5 my-2">
            {product.brands ? <><h6>Brand:{product.brands.name}</h6></> : ""}
            <h3> {product.name} </h3>
            {ratings ? Array.from(Array(ratings), (e) => {
                return <Star color="#ffce00" />
            }) : ""}
            {variantIndex >= 0 && <> <p className="h4" style={{ fontWeight: '400' }}>{getCurrencySymbol()} {product.variants[variantIndex].mrp} </p>
                {product.variants[variantIndex].display_mrp && product.variants[variantIndex].display_mrp !== 0 &&
                    <p className="h6" style={{ fontWeight: '400' }}>
                        <del>{getCurrencySymbol()} {product.variants[variantIndex].display_mrp}</del>
                    </p>}
                <p className="h6" style={{ fontWeight: '400' }}>
                    {product.product_type != 'DIGITAL' ? <> {product.variants[variantIndex].quantity} {product.variants[variantIndex].quantity_unit_name}</> : ""}
                </p>
                {productInCart && product.product_type == "DIGITAL" &&
                    <Link to='/cart' className='btn web-primary-color-button text-decoration-none rounded-pill my-3 mx-2 px-4'>
                        CHECKOUT
                    </Link>}
                {productInCart && (product.product_type == "PHYSICAL" || product.product_type == null) && <div className='my-1' style={{ width: "180px" }}>
                    <div className='input-group'>
                        <button type='button' className='btn border-dark' onClick={() => {
                            if (qty === 1) {
                                deleteCartProduct(product.id, variantIndex);
                            } else { postCartData(product, variantIndex, (qty - 1)) };
                        }}>

                            {qty === 1 ? <Trash /> :
                                <ChevronBack />
                            }
                        </button>
                        <input type='tel' value={qty} onChange={(e) => {
                            let value;
                            if ((product.variants[variantIndex].available_quantity !== null) && (e.target.value > product.variants[variantIndex].available_quantity)) {
                                value = product.variants[variantIndex].available_quantity
                            } else if (product.variants[variantIndex].min_order_quantity != null && product.variants[variantIndex].min_order_quantity != 0 && e.target.value > product.variants[variantIndex].min_order_quantity) {
                                value = product.variants[variantIndex].min_order_quantity
                            } else if (product.variants[variantIndex].max_order_quantity != null && product.variants[variantIndex].max_order_quantity != 0 && e.target.value < product.variants[variantIndex].max_order_quantity) {
                                value = product.variants[variantIndex].max_order_quantity
                            } else {
                                if (e.target.value.match("[^0-9]")) {
                                    value = qty;
                                } else {
                                    value = e.target.value;
                                }
                            }
                            postCartData(product, variantIndex, Math.max(1, parseInt(value)))
                        }} className='form-control text-center' />
                        <button className='btn border-dark' onClick={() => postCartData(product, variantIndex, (qty + 1))} disabled={((product.variants[variantIndex].max_order_quantity != null && product.variants[variantIndex].max_order_quantity != 0 && qty >= product.variants[variantIndex].max_order_quantity) || (product.variants[variantIndex].available_quantity !== null && qty >= product.variants[variantIndex].available_quantity)) ? true : false}>
                            <ChevronForward />
                        </button>
                    </div>
                </div>}
            </>}
            {product.attributes && product.attributes.map(attribute => <div className='w-75 d-flex flex-wrap my-1'>
                <label >
                    <span style={{ fontWeight: 500, display: "block" }}>{attribute.name} :</span>
                </label>
                <select className='mx-1' onChange={e => handleVariantChange(attribute.name, e.target.value)}>
                    {attribute.values.map((value) => (
                        <option value={value.value}>{value.value}</option>
                    ))}
                </select>
            </div>)}

            {variantIndex >= 0 ? <><div>
                {product.variants[variantIndex].available_quantity !== null && product.variants[variantIndex].available_quantity === 0 ?
                    <span className="badge text-bg-danger">OUT OF STOCK</span>

                    :
                    product.product_type == 'DIGITAL' ? <>
                        {!productInCart && <button type="button" className="btn web-secondary-color-button my-3 mx-2 px-4 rounded-pill" onClick={e => { handleBuyNow(product, variantIndex) }}>BUY NOW</button>}
                    </>
                        :
                        productInCart || qty > 0 ?
                            <Link to='/cart' className='btn web-primary-color-button text-decoration-none rounded-pill my-3 mx-2 px-4'>
                                CHECKOUT
                            </Link>
                            :
                            <button type="button" className="btn web-primary-color-button my-3 px-4 rounded-pill" onClick={() => { postCartData(product, variantIndex, 1); HandleOnClick(); }}>ADD TO CART</button>
                }


                {product.product_type != 'DIGITAL' ? product.variants[variantIndex].available_quantity !== null && product.variants[variantIndex].available_quantity === 0 ? ""
                    : !productInCart && <button type="button" className="btn web-secondary-color-button my-3 mx-2 px-4 rounded-pill" onClick={e => { handleBuyNow(product, variantIndex) }}>BUY NOW</button>
                    : ""}
            </div>
                <hr />

                {!getAuthToken() ?
                    <Link to='/login' className="text-decoration-none text-dark">
                        <Heart color={'#D3D3D3'} />
                        <span className="mx-1">
                            Add To Wishlist
                        </span>
                    </Link>
                    :
                    isWishlisted ?
                        <div className='cursor-pointer d-inline-block' onClick={e => addorDeleteWishlist()}>
                            <Heart color={'#a80000'} />
                            <span className="mx-1">
                                Remove From Wishlist
                            </span>
                        </div>
                        :
                        <div className='cursor-pointer d-inline-block' onClick={e => addorDeleteWishlist()}>
                            <Heart color={'#D3D3D3'} />
                            <span className="mx-1">
                                Add To Wishlist
                            </span>
                        </div>
                }

                <hr />
                <div className=''>
                    <span>Share to:{' '}</span>
                    <span className="mx-1">
                        <FacebookShareButton url={String(window.location.href)} windowWidth='800' windowHeight='600'>
                            <FacebookIcon round={true} size='2rem' />
                        </FacebookShareButton>
                    </span>
                    <span className="mx-1">
                        <TwitterShareButton url={String(window.location.href)} windowWidth='800' windowHeight='600'>
                            <TwitterIcon round={true} size='2rem' />
                        </TwitterShareButton>
                    </span>
                    <span className="mx-1">
                        <WhatsappShareButton url={String(window.location.href)} windowWidth='800' windowHeight='600'>
                            <WhatsappIcon round={true} size='2rem' />
                        </WhatsappShareButton>
                    </span>
                    <span className="mx-1">
                        <LinkedinShareButton url={String(window.location.href)} windowWidth='800' windowHeight='600'>
                            <LinkedinIcon round={true} size='2rem' />
                        </LinkedinShareButton>
                    </span>
                </div>
                <hr />

                {product.product_taxes && <div>
                    <strong>Taxes Apply: </strong>

                    <ol>
                        {product.product_taxes.map(tax => <li>
                            {tax.name}: {`${tax.method === "FLAT" ? getCurrencySymbol() : ""} ${tax.value} ${tax.method === "PERCENT" ? '%' : ""}`}
                        </li>)}
                    </ol>
                </div>}


                {product.product_taxes && <hr />}

                {product.variants[variantIndex].min_order_quantity && <div><strong>Minimum Order Quantity: </strong> {product.variants[variantIndex].min_order_quantity}</div>}
                {product.variants[variantIndex].max_order_quantity && <div><strong>Maximum  Order Quantity: </strong> {product.variants[variantIndex].max_order_quantity}</div>}

                {(product.sku || product.desc_html || product.dynamic_product_field) && (
                    <div className="text-secondary">
                        <p><strong>Product Details:</strong></p>

                        {product.sku && <div><strong>Product SKU: </strong> {product.sku}</div>}

                        {product.desc_html && <div dangerouslySetInnerHTML={{ __html: product.desc_html }}>
                        </div>}

                        {product.dynamic_product_field && product.dynamic_product_field.map(field => <div key={field.id}><strong>{field.name}:</strong> {field.value}</div>)}
                    </div>
                )}

            </> : <div className='h5 text-danger'>Product with selected variant is not available.</div>}
        </div>}
        {ShowValue && <ComplimentaryProduct product={product} />}
    </>
    )
}
export default ProductData;