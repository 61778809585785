import React from 'react';
import { Link } from "react-router-dom";
import useAPI from '../../../utils/api';
import { useLoader } from '../../../utils/contexts';
import { getCurrencySymbol } from '../../../utils/currency';

const HeaderCartItem = ({product, cart}) => {
    const loader = useLoader();
    const API = useAPI();

    const deleteCartProduct = async () =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.deleteProductInCart({
            product_id: product.id,
            variant_index: product.variant_index,
        });
        if(ok && response){
            cart.setMyCartData(response.data);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    return (product && product.variants && product.variants[product.variant_index] ? 
        <div className="py-2 my-2 border-bottom">
            <button className="bg-light border-0 float-end" onClick={e => deleteCartProduct()}>X</button>
            <Link className="d-flex text-dark text-decoration-none" to={"/product/" + product.name.replace(/[^a-zA-Z0-9]/g, "") + "/" + product.id }>
                <div className='border border-secondary rounded'>
                    <img width={'100px'} height={'100px'} src={product.variants[0].images[0].image_url_small} alt={product.name}/>
                </div>
                <div className="ms-2">
                    <p className="h6 text-wrap mb-1">{product.name && product.name.length > 10 ? product.name.substring(0, 10) + "..." : product.name}</p>
                    {product.variants[product.variant_index].attribute_value_mappings && product.variants[product.variant_index].attribute_value_mappings.map(attribute => <p className="mb-1">
                        {attribute.attribute_name} : {attribute.attribute_value}
                    </p> )}
                    <p className="h6 text-success mt-auto">
                        <span>
                            <b>{product.qty} {" X "} {getCurrencySymbol()} {product.variants[product.variant_index].mrp}{" "}</b>
                        </span>
                    </p>
                </div>
            </Link>
            </div>
            :<></>
    )
}

export default HeaderCartItem;
