import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useAPI from "../../utils/api";
import { useLoader } from "../../utils/contexts";
import { getCurrencySymbol } from "../../utils/currency";
import useFetch from "../../utils/useFetch";
import Breadcrumb from "../common/Breadcrumb";
import Loader from "../common/Loader";
import TrackOrder from "./component/TrackOrder";

const OrderDetails = () => {
    const [orderId, setOrderId] = useState("");
    const [update, setUpdate] = useState(false);
    const loader = useLoader();

    const params = useParams();
    const API = useAPI();
    const { data, status, loading } = useFetch(
        "orderDetails",
        params.id,
        update
    );
    
    const handleCancellingOrder = async () => {
        loader.setLoaderOpen(true);
        const [ok, response] = await API.cancelOrder(orderId);
        if (ok && response) {
            setUpdate(!update);
        } else {
            if (response && response.error) {
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    };

    return (
      <>
        <Loader isOpen={loading} />
        <Breadcrumb
          data={[
            {
              name: "Home",
              link: "/",
            },
            {
              name: "Order History",
              link: "/pastorders",
            },
            {
              name: `Order ${params.id}`,
              link: "",
            },
          ]}
        />
        {!loading && status === "fetched" && data && data.data && (
          <div className="container">
            <h3>
              {new Date(data.data.created_at).toLocaleString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
                weekday: "short",
              })}
            </h3>
            <div className={`text-end`}>
              <span
                className={`p-2 badge ${
                  data.data.status === "DELIVER"
                    ? "text-bg-success"
                    : data.data.status === "CANCEL"
                    ? "text-bg-danger"
                    : "web-primary-color-button"
                }`}
              >
                {data.data.status}
              </span>
            </div>
            <h6>
<span className={`p-2 badge ${data.data.payment_status === "PAID" ? "text-bg-warning" : data.data.payment_status === "COD" ? "text-bg-danger" : "web-primary-color-button" }`}>
{data.data.payment_status}</span>
              
          
            </h6>
            <h6>
              <b>Order No: </b>
              {data.data.id}
            </h6>
            <h6>
              <b>Address: </b>
              {`${data.data.delivery_address} , ${data.data.delivery_city} , ${data.data.delivery_country} - ${data.data.delivery_zip}`}
            </h6>
            <h6>
              <b>Mobile No.:</b> {data.data.user_phone}
            </h6>
            {data.data.custom_message && (
              <h6>
                <b>Message by client: </b>
                {data.data.custom_message}
              </h6>
            )}
            <hr />
            {data.data.order_images.length > 0 ? (
              <div>
                <h4>Order Image</h4>
                <img
                  src={`https://cdn.intelikart.com/uploads${data.data.order_images[0].image_path}`}
                  alt="product"
                />
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col">Product</th>
                      <th scope="col">Units</th>
                      <th scope="col">Tax</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.products.length > 0 &&
                      data.data.products.map((value) => (
                        <tr>
                          <td>
                            <img
                              src={value.variant_image_url_thumb}
                              alt="product"
                              width="100px"
                              height="100px"
                            />
                          </td>
                          <td>{value.variant_name}</td>
                          <td>
                            {value.variant_ordered_units} Unit *{" "}
                            {value.variant_quantity}{" "}
                            {value.variant_quantity_unit_name}
                          </td>
                          <td>{value.variant_taxes}</td>
                          <td>
                            {getCurrencySymbol()} {value.variant_price}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            <hr />
            <div>
              {" "}
              <strong>
                {" "}
                {`Price Details (${data.data.products.length} item)`}{" "}
              </strong>
            </div>
            <div className="d-flex justify-content-between">
              <bold className="fw-bold">Cart Total</bold>
              <span className="fw-bold">
                {getCurrencySymbol()}{" "}
                {data.data.order_pre_taxes_and_charges_price.toFixed(2)}
              </span>
            </div>
            {data.data.pricing_breakup.discounts && (
              <div>
                CouponCode {data.data.pricing_breakup.discounts.coupon_name}
                <span>
                  {`${data.data.pricing_breakup.discounts.coupon_value_display.slice(
                    0,
                    1
                  )} ${getCurrencySymbol()} ${data.data.pricing_breakup.discounts.coupon_value_display.slice(
                    1
                  )}`}
                </span>
              </div>
            )}
            <div>
              {data.data.pricing_breakup.adjustments.length > 0 &&
                data.data.pricing_breakup.adjustments.map((item) => (
                  <div key={item.adjustment_name}>
                    {item.adjustment_name}
                    <span>
                      {`${item.adjustment_value_display.slice(
                        0,
                        1
                      )} ${getCurrencySymbol()} ${item.adjustment_value_display.slice(
                        1
                      )}`}
                    </span>
                  </div>
                ))}
            </div>
            <div>
              {data.data.pricing_breakup.taxesCharges.length > 0 &&
                data.data.pricing_breakup.taxesCharges.map((item) => (
                  <div
                    key={item.tax_name}
                    className="d-flex justify-content-between"
                  >
                    <span>{item.tax_name}</span>
                    <span>
                      {getCurrencySymbol()} {item.tax_value_display}
                    </span>
                  </div>
                ))}
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <strong>Total </strong>
              <span className="">
                {getCurrencySymbol()} {data.data.pricing_breakup.total}
              </span>
            </div>
            <hr />
            <div>
              <div>
                <strong>Track Order</strong>
              </div>
              <TrackOrder orderHistory={data.data.status_history} />
            </div>
            <hr />
            {(data.data.status === "NEW" ||
              data.data.status === "SCHEDULED" ||
              data.data.status === "PROCESSING" ||
              data.data.status === "CANCEL") && (
              <div>
                {data.data.status === "CANCEL" ? (
                  <p className="text-danger">This Order has been Cancelled!</p>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#cancelModal"
                    onClick={(e) => {
                      setOrderId(data.data.id);
                    }}
                  >
                    Cancel Order
                  </button>
                )}
              </div>
            )}
            <div
              className="modal fade"
              id="cancelModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Order Cancel
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    Are you sure, you want to cancel the order with id:{" "}
                    {orderId}?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={(e) => {
                        handleCancellingOrder();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
};

export default OrderDetails;
