import React from "react";
import { Link } from "react-router-dom";
import useAPI from "../../../utils/api";
import { useLoader } from "../../../utils/contexts";
import { getCurrencySymbol } from "../../../utils/currency";

const WishlistTable = ({ isCard, product, cart, update, setUpdate }) => {
    const API = useAPI();
    const loader = useLoader();

    const removeWishlist = async (addCart = false) => {
        loader.setLoaderOpen(true);
        const [ok, response] = await API.deleteWishlist({
            product_id: product.id,
            variant_index: product.variant_index,
        });
        if (ok && response) {
            if (response.status === "remove") {
                if (response.status === "remove") {
                    setUpdate(!update);
                }
                if(!addCart) {
                    alert(response.message);
                }
            }
        } else {
            if (response && response.error) {
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    };

    const postCartData = async (item, variant_index) => {
        loader.setLoaderOpen(true);
        const [ok, response] = await API.addProductInCart({
            product: item,
            variant_index: variant_index,
            qty: 1,
        });
        if (ok && response) {
            await removeWishlist(true);
            cart.setMyCartData(response.data);
            alert("Product added to the cart")
        } else {
            if (response && response.error) {
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    };

    return isCard ? (
      <div className="card opp-tans m-2" style={{ width: "230px" }}>
        <Link
          to={
            "/product/" +
            product.name.replace(/[^a-zA-Z0-9]/g, "") +
            "/" +
            product.id
          }
        >
          <img
            src={product.variants[product.variant_index].image_url_original}
            className="card-img-top"
            height={"200px"}
            alt="product"
          />
        </Link>
        <div className="card-body">
          <Link
            to={
              "/product/" +
              product.name.replace(/[^a-zA-Z0-9]/g, "") +
              "/" +
              product.id
            }
            className="text-decoration-none text-dark"
          >
            <h6 className="card-title">{product.variant_name}</h6>
            {product.variants[product.variant_index].available_quantity !==
              null &&
            product.variants[product.variant_index].available_quantity == 0 ? (
              <p className="text-danger mb-0">Out of stock</p>
            ) : (
              <p className="text-success mb-0">In stock</p>
            )}
            <span>
              {getCurrencySymbol()}{" "}
              {product.variants[product.variant_index].mrp} {"  "}
            </span>
            {product.variants[product.variant_index].display_mrp &&
              product.variants[product.variant_index].display_mrp !== 0 && (
                <del>
                  {getCurrencySymbol()}{" "}
                  {product.variants[product.variant_index].display_mrp}
                </del>
              )}
          </Link>
          <div className="d-flex justify-content-evenly">
            <button
              className="flex-fill btn btn-sm web-primary-color-button"
              onClick={(e) => {
                postCartData(product, product.variant_index);
              }}
            >
              Add
            </button>
            <button
              className="flex-fill btn btn-sm btn-danger"
              onClick={(e) => {
                removeWishlist();
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    ) : (
      <tr key={product.id}>
        <td className="w-25">
          <Link
            to={
              "/product/" +
              product.name.replace(/[^a-zA-Z0-9]/g, "") +
              "/" +
              product.id
            }
          >
            <img
              src={product.variants[product.variant_index].image_url_original}
              width={"70%"}
              height={"150px"}
              alt="product"
            />
          </Link>
        </td>
        <td className="w-25">
          <Link
            to={
              "/product/" +
              product.name.replace(/[^a-zA-Z0-9]/g, "") +
              "/" +
              product.id
            }
            className="text-decoration-none text-dark"
          >
            <p className="">{product.variant_name}</p>
          </Link>
          <div className="">
            {product.variants[product.variant_index].available_quantity !==
              null &&
            product.variants[product.variant_index].available_quantity == 0 ? (
              <p className="text-danger">Out of stock</p>
            ) : (
              <p className="text-success">In stock</p>
            )}
          </div>
        </td>
        <td className="w-25">
          <span className="">
            {getCurrencySymbol()} {product.variants[product.variant_index].mrp}{" "}
            {"  "}
          </span>
          {product.variants[product.variant_index].display_mrp &&
            product.variants[product.variant_index].display_mrp !== 0 && (
              <del className="">
                {getCurrencySymbol()}{" "}
                {product.variants[product.variant_index].display_mrp}
              </del>
            )}
        </td>
        <td className="w-25">
          <button
            className="btn btn-sm web-primary-color-button my-1 me-2"
            onClick={(e) => {
              postCartData(product, product.variant_index);
            }}
            disabled={
            
              product.variants[product.variant_index].available_quantity === 0
            }
          >
            Add
          </button>
          <button
            className="btn btn-sm btn-danger my-1"
            onClick={(e) => {
              removeWishlist();
            }}
          >
            Remove
          </button>
        </td>
      </tr>
    );
};

export default WishlistTable;
