import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../utils/useFetch';

const PageDetails = () => {
    const params = useParams();
    const {data, status, loading} = useFetch("pageData", params.page_name);

    return (<div>
        {!loading && status === "fetched" && data.data ? <div dangerouslySetInnerHTML={{ __html: data.data.content }} className='container pages'/> : <h4 className='text-center'>Loading...</h4>}
    </div>)
}

export default PageDetails