import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import "./index.css";

// Import custom components

// Extra Pages
// Portfolio Features
// import SignUpWithMobile from "./components/pages/SignUpWithMobile";
import App from "./App";
import Loader from "./pages/common/Loader";
import { handleColorData } from "./utils/colors";
import { CartContext, LoaderContext, StoreContext } from "./utils/contexts";
import useFetch from "./utils/useFetch";
const Root = () =>  {
    const [myCartData, setMyCartData] = useState({products: []});
    const [loaderOpen, setLoaderOpen] = useState(false);
    const [redirected, setRedirected] = useState(false);
    

    let hostname = '';
    if(window.location.hostname.includes("localhost")) {
        hostname = "jgxvhdhug.intelikart.com";
       
        
    
        
        
    } else {
    if (window.location.protocol !== 'https:') {
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }
        hostname = window.location.hostname;
    }


    useEffect(() => {
        if(hostname){
            localStorage.setItem("ik-domain", hostname)
        }
    }, [hostname])

    const storeData = useFetch("storeData", hostname);
    const cartData = useFetch("getCart");
   
    // useEffect(() => {
    //     if (
    //         !redirected &&
    //         !window.location.hostname.includes('localhost') &&
    //         hostname.includes(".intelikart.com") &&
    //         storeData &&
    //         storeData.data &&
    //         storeData.data.data &&
    //         storeData.data.data.store &&
    //         storeData.data.data.store.enable_domain == 1
    //     ) {
    //         setRedirected(true);
    //         window.location.href = `https://${storeData.data.data.store.domain}`;
    //     }
    // }, [redirected, storeData]);


    useEffect(() => {
        if(!cartData.loading && cartData.status === "fetched" && cartData.data && !Array.isArray(cartData.data) && cartData.data.data){
            setMyCartData(cartData.data.data);
        }
    }, [cartData]);

    return ( <>
        <Loader isOpen={storeData.loading || cartData.loading} />
    {
        !storeData.loading && storeData.status === "fetched" && storeData.data && handleColorData(storeData.data.data.store) &&
    <>
        <CartContext.Provider value={{myCartData, setMyCartData}}>
            <StoreContext.Provider value={storeData.data}>
                <Loader isOpen={loaderOpen} />
                    <LoaderContext.Provider value={{setLoaderOpen}}>
                        <Router basename={"/"}>
                            <App/>
                        </Router>
                    </LoaderContext.Provider>
            </StoreContext.Provider>
        </CartContext.Provider>
    </>
    }
    </>
    );
}

export default (Root);

ReactDOM.render(<Root />, document.getElementById("root"));
