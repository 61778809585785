import React from "react";
import { Helmet } from "react-helmet";

const Seo = ({ store, url, product_meta, image, addons }) => {
    let title = `${
        store.seo && store.seo.title && store.seo.title !== "" && addons && addons.addon_seo && addons.addon_seo.status=="active"
            ? store.seo.title
            : store.name +
              (store.city ? " | " + store.city : "") +
              (store.state ? " | " + store.state : "") +
              (store.phone ? " | " + store.phone : "")
    } | powered by Intelikart`;

    let description = `${
        store.seo && store.seo.description && store.seo.description !== "" && addons && addons.addon_seo && addons.addon_seo.status=="active"
            ? store.seo.description
            : store.name +
              (store.city ? " | " + store.city : "") +
              (store.state ? " | " + store.state : "") +
              (store.phone ? " | " + store.phone : "")
    }`;

    let keyword = `${
        store.seo && store.seo.keyword && store.seo.keyword !== "" && addons && addons.addon_seo && addons.addon_seo.status=="active"
            ? store.seo.keyword
            : store.name
    }`;

    return (
        <Helmet>
            <title>
                {product_meta &&
                product_meta.meta_title &&
                product_meta.meta_title != ""
                    ? product_meta.meta_title
                    : title}
            </title>
            <meta
                name="description"
                content={
                    product_meta &&
                    product_meta.meta_description &&
                    product_meta.meta_description != ""
                        ? product_meta.meta_description
                        : description
                }
            />
            <meta
                name="keywords"
                content={
                    product_meta &&
                    product_meta.meta_keyword &&
                    product_meta.meta_keyword != ""
                        ? product_meta.meta_keyword
                        : keyword
                }
            />

            <meta
                property="og:title"
                content={
                    product_meta &&
                    product_meta.meta_title &&
                    product_meta.meta_title != ""
                        ? product_meta.meta_title
                        : title
                }
            />

            <meta
                name="og:description"
                content={
                    product_meta &&
                    product_meta.meta_description &&
                    product_meta.meta_description != ""
                        ? product_meta.meta_description
                        : description
                }
            />

            <meta
                name="og:image"
                content={`${image ? image : store.logo_internet_url}`}
            />

            <meta name="og:url" content={`${url}`} />

            <meta property="og:type" content="website" />

            <meta
                property="twitter:title"
                content={
                    product_meta &&
                    product_meta.meta_title &&
                    product_meta.meta_title != ""
                        ? product_meta.meta_title
                        : title
                }
            />

            <meta
                name="twitter:description"
                content={
                    product_meta &&
                    product_meta.meta_description &&
                    product_meta.meta_description != ""
                        ? product_meta.meta_description
                        : description
                }
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="twitter:image"
                content={`${image ? image : store.logo_internet_url}`}
            />
        </Helmet>
    );
};

export default Seo;
