import React, { useEffect, useState } from "react";
import { useLoader } from "../../utils/contexts";
import { REACT_APP_API_HOST } from "../../utils/api";

const PaymentWebviewPage = () => {
    // cashfree: http://localhost:9001/payment/1?ik-payment-token=42888-1707210799&redirect-url=http://localhost:9001/thankyou/1

    // phone pe: http://localhost:9001/payment/2?ik-payment-token=42885-1707195686&redirect-url=http://localhost:9001/thankyou/2?order_id=42885

    // razorpay: http://localhost:9001/payment/3?ik-payment-token=42895-1707216647&redirect-url=http://localhost:9001/thankyou/3?order_id=42895&order_id=42895

    // http://localhost:9001/payment/3?ik-payment-token=49213-1707970986&redirect-url=http://localhost:9001/thankyou/3?order_id=49213&order_id=49213


    const params = new URLSearchParams(window.location.search);
    let gatewayParams = window.location.pathname.split("/")[2];

    let gateway;
    if (gatewayParams == 1) {
        gateway = "ONLINE_CASHFREE";
    } else if (gatewayParams == 2) {
        gateway = "ONLINE_PHONEPE";
    } else if (gatewayParams == 3) {
        gateway = "ONLINE_RAZORPAY";
    } else if (gatewayParams == 4) {
        gateway = "ONLINE_PHONEPE_TEST";
    }

    const iktoken = params.get("ik-payment-token");
    const redirectUrl = params.get("redirect-url");

    let orderId;

    if (gateway == "ONLINE_RAZORPAY") {
        orderId = params.get("order_id");
    }

    const loader = useLoader();

    async function initializePayment(token) {
        if (
            gateway == "ONLINE_CASHFREE" ||
            gateway == "ONLINE_PHONEPE" ||
            gateway == "ONLINE_RAZORPAY" ||
            gateway == "ONLINE_PHONEPE_TEST"
        ) {
            loader.setLoaderOpen(true);
            const response = await fetch(`${REACT_APP_API_HOST}pay`, {
                method: 'POST',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "x-ik-domain": localStorage.getItem("ik-domain"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    gateway: gateway,
                    "ik-payment-token": iktoken,
                    "redirect-url": redirectUrl,
                })
            });

            const json = await response.json()
            const payResponse = [response.ok, json]

            if (payResponse[0]) {
                if (
                    payResponse[1] &&
                    payResponse[1].gateway &&
                    payResponse[1].gateway === "ONLINE_CASHFREE"
                ) {
                    let paymentSessionId = payResponse[1].payment_session_id;
                    if (!paymentSessionId) {
                        alert(
                            "Cashfree account is blocked by seller. Please try any other method of payment."
                        );
                    } else {
                        const cashfree = new window.Cashfree(paymentSessionId);
                        cashfree.redirect();
                    }
                } else if (
                    (payResponse[1] &&
                        payResponse[1].gateway &&
                        payResponse[1].gateway === "ONLINE_PHONEPE") ||
                    payResponse[1].gateway == "ONLINE_PHONEPE_TEST"
                ) {
                    let phonepe_url = payResponse[1].url;
                    if (!phonepe_url) {
                        alert(
                            "Phonepe account is blocked by seller. Please try any other method of payment."
                        );
                    } else {
                        window.location.href = phonepe_url;
                    }
                } else if (
                    payResponse[1] &&
                    payResponse[1].gateway &&
                    payResponse[1].gateway === "ONLINE_RAZORPAY" &&
                    orderId
                ) {
                    let options = payResponse[1].options;
                    if (!options) {
                        alert(
                            "Razorpay account is blocked by seller. Please try any other method of payment."
                        );
                    } else {
                        let successurl;

                        let handler = function(handleRes) {
                            fetch(
                                `${REACT_APP_API_HOST}pay/confirm?order_id=${orderId}&gateway=${gateway}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${
                                            token
                                        }`,
                                        "x-ik-domain": localStorage.getItem(
                                            "ik-domain"
                                        ),
                                        "X-Razorpay-Payment-Id":
                                            handleRes.razorpay_payment_id,
                                        "X-Razorpay-Signature":
                                            handleRes.razorpay_signature,
                                    },
                                }
                            )
                                .then((res) => res.json())
                                .then((confirmRes) => {
                                    successurl = `${
                                        window.location.origin
                                    }/thankyou/3?order_id=${orderId}&status=${
                                        confirmRes.status
                                    }`;

                                    window.location.href = successurl;
                                });
                        };
                        options = { ...options, handler };

                        const rzp1 = new window.Razorpay(options);
                        rzp1.open();

                        rzp1.on("payment.failed", function(err) {
                            alert(err.error.description);
                        });
                        rzp1.on("payment.closed", () => {
                            loader.setLoaderOpen(false);
                            alert("Payment window closed. Redirecting to the previous page.");
                            window.history.back();  
                        }); 
                    }
                }
            }
            // loader.setLoaderOpen(false);
        } else {
            console.log("doesn't have valid payment gateway");
        }
    }

    useEffect(
        () => {
            const readCookie = (name) => {
                const cookieValue = document.cookie
                .split("; ")
                .find((row) => row.startsWith(name));

            const cookieContent = cookieValue ? cookieValue.split("=")[1] : null;

            return cookieContent ? decodeURIComponent(cookieContent) : null;
            };
    
            // Read token from cookie
            const token = readCookie("token");
            if(token) {
                initializePayment(token);
            } else {
                alert("Unathorized")
            }
        },
        [iktoken, gatewayParams]
    );

    return <></>;
};

export default PaymentWebviewPage;
