import React, { useEffect } from "react";
import { Route, Switch,useHistory} from "react-router-dom";
import "./index.css";
// Import custom components
// Extra Pages
import PaymentStatusPage from "./pages/checkout/PaymentStatusPage";
// Portfolio Features
import ProtectedRoute from "./authorization/ProtectedRoute";
import UnprotectedRoute from "./authorization/UnprotectedRoute";
// import SignUpWithMobile from "./components/pages/SignUpWithMobile";
import {useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Seo from "./components/common/seo";
import Home from "./pages/Home";
import CategoryProducts from "./pages/category/CategoryProducts";
import CartCheckout from "./pages/checkout/CartCheckout";
import Footer from "./pages/common/Footer";
import Header from "./pages/common/Header";
import GoogleLogin from "./pages/login/GoogleLogin";
import Login from "./pages/login/Login";
import MobileSignup from "./pages/login/MobileSignup";
import ProductDetail from "./pages/product-detail/ProductDetail";
import DeliveryAddress from "./pages/profile/DeliveryAddress";
import OrderDetails from "./pages/profile/OrderDetails";
import PastOrder from "./pages/profile/PastOrder";
import Profile from "./pages/profile/Profile";
import Wishlist from "./pages/profile/Wishlist";
import PageDetails from "./pages/webpage/PageDetails";
import { useStore } from "./utils/contexts";
import PaymentWebviewPage from "./pages/checkout/PaymentWebviewPage";
import PaymentSuccessPage from "./pages/checkout/PaymentSuccessPage";
import PaymentFailurePage from "./pages/checkout/PaymentFailurePage";
import StoreOffline from "./pages/store-status/StoreOffline";
import BrandProducts from "./pages/brands/BrandProducts";


const App = () =>  {
    const location = useLocation();
    const storeData = useStore();
    const history = useHistory();
    useEffect(
        () => {
            if (
                storeData.data.store.offline_start_date != null &&
                storeData.data.store.offline_end_date != null
            ) {
                let currentDate = new Date();
                let startDate = new Date(
                    storeData.data.store.offline_start_date
                );
                let endDate = new Date(
                    storeData.data.store.offline_end_date
                );

                if (currentDate >= startDate && currentDate <= endDate) {
                    history.push(`${process.env.PUBLIC_URL}/offline`);
                } else if(location.pathname.includes("/offline")){
                    history.push(`${process.env.PUBLIC_URL}/`);
                }
            } else {
                if(location.pathname.includes("/offline")){
                    history.push(`${process.env.PUBLIC_URL}/`);
                }
            }
        },
        [storeData]
    );
    return ( <>
        {storeData.data && storeData.data.store && <Seo store={storeData.data.store} url={window.location.href} addons={storeData.data.addons}/>}
                {location.pathname !== "/cart" && location.pathname !== "/login" && location.pathname !== "/signupwithmobile" && location.pathname !== "/checking" && !location.pathname.startsWith("/thankyou") && !location.pathname.startsWith("/payment") && !location.pathname.startsWith("/success") && !location.pathname.startsWith("/failure")  && location.pathname !== "/offline" && <Header/>}
                
                    <Switch>
                                        <ProtectedRoute
                                            exact
                    path={`${process.env.PUBLIC_URL}/cart`}
                                            component={CartCheckout}
                                        />
                                        <ProtectedRoute
                                            exact
                    path={`${process.env.PUBLIC_URL}/pastorders`}
                                            component={PastOrder}
                                        />
                                        <ProtectedRoute
                                            exact
                    path={`${process.env.PUBLIC_URL}/pastorders/:id`}
                                            component={OrderDetails}
                                        />
                                        <ProtectedRoute
                                            exact
                    path={`${process.env.PUBLIC_URL}/profile`}
                                            component={Profile}
                                        />
                                        <ProtectedRoute
                                            exact
                    path={`${process.env.PUBLIC_URL}/address`}
                                            component={DeliveryAddress}
                                        />
                                        <ProtectedRoute
                    path={`${process.env.PUBLIC_URL}/wishlist`}
                                            component={Wishlist}
                                        />
                                        <Route
                                            exact
                    path={`${process.env.PUBLIC_URL}/category/:category/:id`}
                                            component={CategoryProducts}
            />
                                        <Route
                                            exact
                    path={`${process.env.PUBLIC_URL}/brands/:brand/:id`}
                                            component={BrandProducts}
            />
                                        
                                        <Route
                                            exact
                                            path={`${
                                                process.env.PUBLIC_URL
                                            }/product/:productname/:id`}
                                            component={ProductDetail}
                                        />
                                        <Route
                                            exact
                                            path={`${
                                                process.env.PUBLIC_URL
                                            }/thankyou/:gateway`}
                                            component={PaymentStatusPage}
                                        />

                                        <Route
                                            exact
                                            path={`${
                                                process.env.PUBLIC_URL
                                            }/success/:gateway`}
                                            component={PaymentSuccessPage}
                                        />

                                        <Route
                                            exact
                                            path={`${
                                                process.env.PUBLIC_URL
                                            }/failure/:gateway`}
                                            component={PaymentFailurePage}
                                        />

                                        <Route
                                            exact
                                            path={`${
                                                process.env.PUBLIC_URL
                                            }/payment/:gateway`}
                                            component={PaymentWebviewPage}
                                        />

                                            <Route
                      path={`${process.env.PUBLIC_URL}/login`}
                                                component={Login}
                                            />

                                            <Route
                      path={`${process.env.PUBLIC_URL}/signupwithmobile`}
                                                component={MobileSignup}
                                            />

                                            <Route
                                                exact
                      path={`${process.env.PUBLIC_URL}/`}
                                                component={Home}
                                            />

                                            <UnprotectedRoute
                      path={`${process.env.PUBLIC_URL}/checking`}
                                                component={GoogleLogin}
                                            />

                                            <Route exact 
                                            path={`${process.env.PUBLIC_URL}/offline`}
                                            component={StoreOffline}
                                            />
                                            <Route
                                                exact
                      path={`${process.env.PUBLIC_URL}/:page_name`}
                                                component={PageDetails}
                                            />
                                    </Switch>
                                    
        {location.pathname !== "/cart" && location.pathname !== "/login" && location.pathname !== "/signupwithmobile" && location.pathname !== "/checking" && !location.pathname.startsWith("/thankyou") && !location.pathname.startsWith("/payment") && !location.pathname.startsWith("/success") && !location.pathname.startsWith("/failure") && location.pathname !== "/offline" && <Footer/>}
    </>
    );
}

export default App;