export function getContrast(color) {
    const r = parseInt(color.slice(1, 3), 16) / 255;
    const g = parseInt(color.slice(3, 5), 16) / 255;
    const b = parseInt(color.slice(5, 7), 16) / 255;
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 0.8 ? 'light' : 'dark';
}

export function handleColorData(data) {
    if(data && data.primary_color && data.secondary_color){
        if(getContrast(data.primary_color) === "dark"){
            document.documentElement.style.setProperty('--website-primary-text-color', '#FFFFFF');
        }else{
            document.documentElement.style.setProperty('--website-primary-text-color', '#000000');
        }
        if(getContrast(data.secondary_color) === "dark"){
            document.documentElement.style.setProperty('--website-secondary-text-color', '#FFFFFF');
        }else{
            document.documentElement.style.setProperty('--website-secondary-text-color', '#000000');
        }
        document.documentElement.style.setProperty('--website-primary-color', data.primary_color);
        document.documentElement.style.setProperty('--website-secondary-color', data.secondary_color);
        document.documentElement.style.setProperty('--website-bg-color', data.background_color);
    }
    return true;
}