import { Splide, SplideSlide } from '@splidejs/react-splide';
import React from 'react';
import styled from 'styled-components';
import { useCart, useStore } from '../../../utils/contexts';
import useFetch from '../../../utils/useFetch';
import { useWindowSize } from '../../../utils/useWindowSize';
import Loader from '../../common/Loader';
import ProductCard from '../../home-components/cards/ProductCard';

const RelatedProducts = ({prdId, catId}) => {
    const storeData = useStore();
    const cartData = useCart();
    const {data, status, loading} = useFetch("relatedProducts", prdId, catId);
    const color = {
        primary_color: storeData.data.store.primary_color,
        secondary_color: storeData.data.store.secondary_color
    }

    const [width, height] = useWindowSize();
    const productSlidesSettings = {
        pagination: false,
        drag: 'free',
        snap: true,
        perMove: 1,
        perPage: 6,
        autoplay: true,
        focus  : 'center',
        breakpoints: {
            1360: {
                perPage: 5
            },
            1155: {
                perPage: 4
            },
            930: {
                perPage: 3
            },
            730: {
                perPage: 4
            },
            715: {
                perPage: 3
            },
            500: {
                perPage: 2
            },
            371: {
                perPage: 1
            }
        },
    };

    const isSlideNeeded = () => {
        const arr = Object.keys(productSlidesSettings.breakpoints).reverse();
        let result;
        for(let i=0; i < arr.length; i++){
            if(width >= arr[i]){
                result = productSlidesSettings.breakpoints[arr[i]].perPage;
                break;
            }
        }
        return result;
    }

    const ProductContainer = styled.div`
        margin-top: 20px;
        margin-bottom: 20px;
        & .splide__arrow{
            background-color: ${((storeData.data && storeData.data.store && storeData.data.store.primary_color) || (color && color.primary_color)) + '!important'};
        }

        & .splide__arrow svg{
            fill: white;
        }
    `;

    return (data && data.data && data.data.length > 0 ? <ProductContainer className='prd-details-padding'>
        <h4 className="mb-3">
            <b>Related Products</b>
        </h4>
        <Loader isOpen={loading}/>
        { !loading && status === "fetched" && data && data.data  &&
        <>
        {data.data.length -1 <= isSlideNeeded() ?
            <div className="d-flex">
                {data.data.map((value, index)=>{
                    return <ProductCard cart={cartData} key={index} color={color} product={value} ></ProductCard>
                })}
            </div>
            :
            <Splide options={productSlidesSettings}>
                {data.data.map((value, index)=>{
                    return <SplideSlide><ProductCard cart={cartData} key={index} color={color} product={value}></ProductCard></SplideSlide>
                })}
            </Splide>}
        </>}
    </ProductContainer> : "")
}

export default RelatedProducts