import React from "react";
import { Link, useParams } from "react-router-dom";

const CategorySelectionSideBar = ({ categoryList }) => {
  const {category} = useParams();
  let show = false;
  return (
    categoryList && (
      <div className="accordion" id="accordionExample">
        {categoryList.map((parentCate,index) => {
          show=false;
          const unique =`cateid-${parentCate.id}`;
          parentCate.subCategory.map((ele)=>{
            if(ele.name===category){
            show=true;
          }
          });
          return(
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button p-2 web-primary-color-button web-primary-color-button-accordian"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${unique}`}
                aria-expanded="true"
                aria-controls={`#${unique}`}
              >
                <img
                  src={parentCate.icon}
                  width="50"
                  height="50"
                  alt="category icon"
                />
                <span className="ms-2 h5">{parentCate.name}</span>
              </button>
            </h2>
            <div
              id={unique}
              className={`accordion-collapse collapse ${show?"show":""} overflow-hidden mt-0`}
            >
              <div className="accordion-body">
                {parentCate.subCategory.length !== 0 ?
                  parentCate.subCategory.map((subCate) => {
                    return(
                    <Link
                      className="d-block mb-2 border-bottom text-decoration-none"
                      key={subCate.id}
                      to={`/category/${subCate.name}/${subCate.id}`}
                      onClick={e=> {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }}
                    >
                      <img
                        src={subCate.icon}
                        width="50"
                        height="50"
                        alt="category icon"
                      />
                      <span className="ms-2" style={{color:"black"}}>{subCate.name}</span>
                    </Link>
                  )}):
                  <div>There is no Sub category</div>
                  }
              </div>
            </div>
          </div>
        )})}
      </div>
    )
  );
};

export default CategorySelectionSideBar;
