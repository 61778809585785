import React from 'react';

const Breadcrumb = ({data}) => {
    return (
        data && <nav aria-label="breadcrumb" className='web-primary-color-button shadow'>
            <ol className="breadcrumb" style={{padding: '20px 6%'}}>
                {data.map(value =>
                    value.link ? <li className="breadcrumb-item website-breadcrumb"><a href={value.link}>{value.name}</a></li>: <li className="breadcrumb-item website-breadcrumb">{value.name}</li>
                )}
            </ol>
        </nav>
    )
}

export default Breadcrumb