import React from 'react';
import useFetch from '../../../utils/useFetch';
import Loader from '../../common/Loader';

const PaymentMethods = ({paymentMethod, setPaymentMethod, cartOption}) => {
    const {data, status, loading} = useFetch("paymentMethods");

    return (<>
    <Loader isOpen={loading}/>
    {!loading && status === "fetched" && data && data.data && <div className="border rounded shadow m-3 p-2">
        <div>
            <h5 className="card-title mb-3 p-3 web-secondary-color-button">Select payment methods</h5>
        </div>
        <div className='ms-3'  onChange={e => {setPaymentMethod(e.target.value);}}>
            {(cartOption === "quick" || data.data.enable_cod === 1) && <div className="form-check">
                <input className="form-check-input checkout-radio-input web-primary-input" type="radio" value='CASH_ON_DELIVERY' checked={paymentMethod === 'CASH_ON_DELIVERY'} name="paymentMethod" id="COD"/>
                <label className="form-check-label" for="COD">
                    Cash On Delivery
                </label>
            </div>}

            {cartOption !== "quick" && data.data.enable_pay_via_paytm != null && <div className="form-check">
                <input className="form-check-input checkout-radio-input web-primary-input" type="radio" value='PAYTM_ON_DELIVERY' checked={paymentMethod === 'PAYTM_ON_DELIVERY'}  name="paymentMethod" id="POD"/>
                <label className="form-check-label" for="POD">
                    Paytm On Delivery
                </label>
            </div>}

            {cartOption !== "quick" && data.data.enable_cashfree_pg == 1 && <div className="form-check">
                <input className="form-check-input checkout-radio-input web-primary-input" type="radio" value='ONLINE_CASHFREE' checked={paymentMethod === 'ONLINE_CASHFREE'} name="paymentMethod" id="cashfree"/>
                <label className="form-check-label" for="cashfree">
                    Pay via Cashfree
                </label>
            </div>}

            {cartOption !== "quick" && data.data.enable_phonepe == 1 && <div className="form-check">
                <input className="form-check-input checkout-radio-input web-primary-input" type="radio" value='ONLINE_PHONEPE' checked={paymentMethod === 'ONLINE_PHONEPE'} name="paymentMethod" id="phonepe"/>
                <label className="form-check-label" for="phonepe">
                    Pay via Phone Pe
                </label>
            </div>}

            {cartOption !== "quick" && data.data.enable_razorpay == 1 && <div className="form-check">
                <input className="form-check-input checkout-radio-input web-primary-input" type="radio" value='ONLINE_RAZORPAY' checked={paymentMethod === 'ONLINE_RAZORPAY'} name="paymentMethod" id="razorpay"/>
                <label className="form-check-label" for="razorpay">
                    Pay via Razorpay
                </label>
            </div>}
        </div>
        {paymentMethod === 'PAYTM_ON_DELIVERY' ? <div className='text-center'>
        <h5>
            Please transfer to {data.data.paytm_phone} and update the message below if you wish{' '}
        </h5>
        </div> : ''}
    </div>}</>
    )
}

export default PaymentMethods