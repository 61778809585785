import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from '../../utils/contexts';
import { getUserId } from '../../utils/storageService';
import useFetch from '../../utils/useFetch';
import Breadcrumb from '../common/Breadcrumb';
import Loader from '../common/Loader';
import ProductData from './components/ProductData';
import ProductDetailCarousel from './components/ProductDetailCarousel';
import ProductReview from './components/ProductReview';
import RelatedProducts from './components/RelatedProducts';

//To-do SEO adding!!
const ProductDetail = () => {
  const cartData = useCart();
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const params = useParams();
  const {data, status, loading} = useFetch("productDetails", params.id);

  useEffect(() => {
    if(!loading && status === "fetched" && data && data.data && data.data[0] ){

      setBreadcrumbData([
        { 
          name: "Home",
          link: "/"
        },
        { 
          name: data.data[0].name,
          link: "",
        }
      ]);
    }
  }, [data, status, loading])

    return ( <>
    <Loader isOpen={loading}/>
    {!loading && status === "fetched" && data && data.data &&
        <>
            <Breadcrumb data={breadcrumbData}/>
            <div className="row prd-details-padding mt-5 w-100 mx-0">
                <ProductDetailCarousel images={data.data[0].variants[0].images}/>
                <ProductData product={data.data[0]} cart={cartData}/>
            </div>
            <RelatedProducts prdId={data.data[0].id} catId={data.data[0].category_id}/>
            <ProductReview prdId={data.data[0].id} userId={getUserId()}/>
        </>}</>
    )
}

export default ProductDetail