import React, { useState } from 'react';
import { HeartDislikeCircleOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import { useCart } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import Breadcrumb from '../common/Breadcrumb';
import Loader from '../common/Loader';
import ProfileSideBar from './ProfileSideBar';
import WishlistTable from './component/WishlistTable';

const Wishlist = () => {
    const cartData = useCart();
    const [update, setUpdate] = useState(false);
    const {data, status, loading} = useFetch("wishlist", update);

    return (<>
        <Loader isOpen={loading}/>
        <Breadcrumb data={[
            { 
                name: "Home",
                link: "/"
            },
            { 
                name: "Wishlist",
                link: "",
            }
        ]}/>
        <div className='row w-100 m-0'>
            <div className='col-3'>
                <ProfileSideBar/>
            </div>
            <div className='col-12 col-sm-9'>
                {!loading && status === "fetched" && data.data && <>
                {data.data.length > 0 ? <>
                <div>
                    <h4 className='text-center'>Wishlist</h4>
                </div>
                <div className='d-none d-lg-block table-responsive'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Image</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.map(product => <WishlistTable isCard={false} product={product} cart={cartData} update={update} setUpdate={setUpdate}/>)}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex flex-wrap justify-content-center justify-content-sm-start d-lg-none'>
                    {data.data.map(product => <WishlistTable isCard={true} product={product} cart={cartData} update={update} setUpdate={setUpdate}/>)}
                    
                </div>
                <div className="my-2 text-center">
                    <Link to={`/`} className="btn web-primary-color-button">Continue Shopping</Link>
                </div>
                </> :
                <div className="card opp-tans py-2">
                    <div className="card-body text-center">
                        <HeartDislikeCircleOutline width={'200px'} height={'200px'}/>
                        <h3>
                            Wishlist is Empty
                        </h3>
                        <h4>Explore more to shortlist some items.</h4>
                    </div>
                    <div className="my-2 text-center">
                    <Link to={`/`} className="btn web-primary-color-button">Continue Shopping</Link>
                </div>
                </div>
                }
                </>}
            </div>
        </div>
    </>)
}

export default Wishlist