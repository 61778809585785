import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useAPI from '../../utils/api';
import { useLoader } from '../../utils/contexts';
import { getCurrencySymbol } from '../../utils/currency';
import useFetch from '../../utils/useFetch';
import Breadcrumb from '../common/Breadcrumb';
import Loader from '../common/Loader';
import ProfileSideBar from './ProfileSideBar';

const PastOrder = () => {
    const [orderId, setOrderId] = useState("");
    const [update, setUpdate] = useState(false);
    const API = useAPI();
    const loader = useLoader();
    const {data, status, loading} = useFetch("orderList", update);

    const handleCancellingOrder = async () =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.cancelOrder(orderId);
        if(ok && response){
            setUpdate(!update);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    return (<>
        <Loader isOpen={loading}/>
        <Breadcrumb data={[
            { 
                name: "Home",
                link: "/"
            },
            { 
                name: "Order History",
                link: "",
            }
        ]}/>
        <div className='row w-100 m-0'>
            <div className='col-3'>
                <ProfileSideBar/>
            </div>
            <div className='col-12 col-sm-9'>
                <div className="mb-2">
                    <h4>Order History</h4>
                </div>
                <div>
                    {!loading && status === "fetched" && data.data  && data.data.length > 0 ?
                    data.data.map(order =>
                        <div className="card opp-tans my-1">
                            <div className="card-body">
                                <div className='d-flex justify-content-between'>
                                    <span>
                                        {new Date(
                                            order.created_at
                                            ).toLocaleString('en-us', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric',
                                                weekday: 'short'
                                            })}
                                    </span>
                                    <span>
                                        <span className={`p-2 badge ${order.status === "DELIVER" ? "text-bg-success" : order.status === "CANCEL" ? "text-bg-danger" : "web-primary-color-button" }`}>{order.status}</span>
                                    </span>
                                </div>
                                <hr/>
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className='d-flex flex-column'>
                                        <span>Order Id</span>
                                        <strong>{order.id}</strong>

                                        <span>Total Items</span>
                                        <strong>{order.products.length}</strong>
                                    </div>
                                    <div>
                                        <strong>
                                        {getCurrencySymbol()}{" "}{parseFloat(order.order_final_price).toFixed(2)}
                                        </strong>
                                    </div>
                                </div>
                                <hr />
                                <div className='d-flex justify-between'>
                                    <Link to={`/pastorders/${order.id}`} className="btn web-secondary-color-button">
                                        Details
                                    </Link>

                                    { (order.status==='NEW' || order.status==='SCHEDULED'  || order.status === 'PROCESSING') && <button
                                        type='button'
                                        className='btn btn-danger ms-2'
                                        data-bs-toggle="modal" 
                                        data-bs-target="#cancelModal"
                                        onClick={e=> {setOrderId(order.id)}}
                                    >
                                        Cancel Order
                                    </button>}
                                </div>
                            </div>
                        </div>
                    ) :
                    <div className="card my-2 opp-tans">
                        <div className="card-body">
                            No order found
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>

        <div className="modal fade" id="cancelModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Order Cancel</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Are you sure, you want to cancel the order with id: {orderId}?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                        <button data-bs-dismiss="modal" type="button" className="btn btn-primary" onClick={e=> {handleCancellingOrder()}}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default PastOrder