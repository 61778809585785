import React from 'react';
import GoogleButton from 'react-google-button';
import { useHistory } from "react-router-dom";

function Login(props) {
    const history = useHistory();
    if(localStorage.getItem('auth'))
    {
    if(JSON.parse(localStorage.getItem("auth")).token)
    {
        history.push("/profile");
    }}

    function handleClick(){
        window.location.replace(`https://accounts.intelikart.com/in?provider=google&redirect=${window.location.origin}/checking`);
    }

    return (
            <section className="sign-up-background min-vh-100 text-center py-5">
                <div className="border text-center mt-5 mx-1 mx-sm-3 mx-md-auto p-5 signup-container rounded">
                    <h3>Login</h3>
                    <h5 className='mt-3'>Sign In To Continue</h5>
                    <p>Sign up for a free account at our store. Registration is quick and easy.<br/> It allows you to be able to order from our shop. To start shopping click register.</p>
                    <div className="d-inline-flex mx-auto flex-column mt-2">
                        <div className="my-4">
                            <GoogleButton className='mx-auto' onClick={handleClick}>Login with google</GoogleButton>
                        </div>
                        {/* <div className="d-flex align-items-center text-bg-primary text-center mb-5" onClick={() =>history.push('/signupwithmobile')} style={{padding: '2px', cursor: 'pointer'}}>
                            <div className="p-3 text-primary bg-white text-center">
                                <ion-icon name='call'/>
                            </div>
                            <span className="mx-auto" style={{fontFamily: 'Roboto, arial, sans-serif'}}>Sign in with Mobile</span>
                        </div> */}
                    </div>
                </div>
                <div className='mt-4' onClick={() =>  history.push('/')}>
                    <button type="button" className="btn web-primary-color-button">
                        Continue shopping
                    </button>
                </div>
            </section>
    )

}

export default Login
