import React from 'react';
import { Link } from 'react-router-dom';

const SmallCategory = ({categoryData, isSizeBigger}) => {
    return ( 
        <div className={`card shadow rounded p-2 mx-2 card-animation ${isSizeBigger ? 'category-card-big' : 'category-card'}`}>
            <Link className='card-link' to={'/category/' + categoryData.name+ '/' + categoryData.id}>
                <div>
                    <div className={`category-image-container`}>
                        <img className="card-img-top category-image" src={categoryData.icon} alt={categoryData.name}/>
                    </div>
                    <div className="card-body text-center px-0 py-1 home-category-body overflow-hidden">
                        <p className="card-text text-wrap">{categoryData.name && categoryData.name.length > 11
                    ? categoryData.name.substring(0, 9) + '...'
                    : categoryData.name}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default SmallCategory