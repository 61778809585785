import React, { useEffect, useRef, useState } from "react";
import {
  CartOutline,
  CloseOutline,
  LocationOutline,
  LogInOutline,
  LogOutOutline,
  Person,
  PersonCircleOutline,
  StarOutline,
  TimerOutline,
  MenuOutline as Menu
} from "react-ionicons";
import { Link } from "react-router-dom";
import useAPI from "../../utils/api";
import { useCart, useStore } from "../../utils/contexts";
import { getAuthToken } from "../../utils/storageService";
import HeaderCartItem from "../home-components/cards/HeaderCartItem";
import HeaderSearchCard from "../home-components/cards/HeaderSearchCard";
import MobileNav from "./MobileNav";

const ProfileData = [
  {
    name: "Profile",
    link: "/profile",
    icon: <PersonCircleOutline />,
  },
  {
    name: "Past Orders",
    link: "/pastorders",
    icon: <TimerOutline />,
  },
  {
    name: "Delivery Addresses",
    link: "/address",
    icon: <LocationOutline />,
  },
  {
    name: "Wishlist",
    link: "/wishlist",
    icon: <StarOutline />,
  },
];

const mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};


const alignmentClasses = {
    "header_left": "justify-content-start",
    "header_right": "justify-content-end",
    "header_center": "justify-content-center",
};

const Header = () => {
  const searchContainerRef = useRef(null);
  const searchInputRef = useRef(null);
  const [query, setQuery] = useState("");
  const [seachedProduct, setSeachedProduct] = useState("");
  const [isBoxOpen, setBoxOpen] = useState(false);
  const API = useAPI();
  const [headerMenu, setHeaderMenu] = useState({})

  const data = useStore();
  const cartData = useCart();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setQuery("");
        setSeachedProduct("");
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data.data && data.data.menus && data.data.menus.length > 0) {
        const validPositions = Object.keys(alignmentClasses);
        const filteredMenu = data.data.menus.find(menu => menu.position != null && validPositions.includes(menu.position));
        if (filteredMenu) {
            setHeaderMenu(filteredMenu);
        }
    }
  }, [data])

  const logoLink =
    data && data.data && data.data.store && data.data.store.logo_internet_url;
  let subCateArray = [];
  data &&
    data.data &&
    data.data.categories &&
    data.data.categories.map((parentCate) =>
      parentCate.subCategory.map((childCate) => subCateArray.push(childCate))
    );

  async function changeQueryHandler(e) {
    setQuery(e.target.value);

    const [ok, response] = await API.searchProducts(e.target.value);
    if (ok && response && response.data) {
      setSeachedProduct(response.data);
    } else {
      if (response && response.error) {
        alert(response.error);
      }
    }
  }
  return (
    <>
      <div className="d-flex flex-column navbar navbar-expand-lg border-bottom shadow mb-4 pb-0">
        <nav className="d-flex justify-content-between w-100 align-items-center">
          <div style={{ marginLeft: "5%" }} className="d-flex align-items-center">
          {headerMenu && headerMenu.links && headerMenu.links.length > 0 && (<div className="d-block d-md-none menu_icon" style={{marginRight: "5%", cursor: 'pointer'}} onClick={toggleMobileMenu}>
                <Menu width="35px" height="35px" />
            </div>)}
            <a className="navbar-brand" href="/">
              <img
                src={logoLink}
                alt="company Logo"
                width="80px"
                height="80px"
              />
            </a>
          </div>
          <div className="d-none d-md-block search-bar">
            <div className="input-group">
              <button
                className="btn web-primary-color-button web-primary-border dropdown-toggle dropdown-width m-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Shop by Category
              </button>
              <ul className="dropdown-menu header-dropdown-menu mt-0">
                {subCateArray.map((cate) => (
                  <li>
                    <Link
                      className="dropdown-item border-bottom text-wrap"
                      to={`/category/${cate.name}/${cate.id}`}
                    >
                      {" "}
                      {cate.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <input
                ref={searchInputRef}
                type="text"
                className="form-control text-center web-primary-input"
                value={query}
                onChange={(e) => changeQueryHandler(e)}
                placeholder="Search for products..."
              />
              <button
                className={`btn border p-1 ${!query && "d-none"}`}
                type="button"
                onClick={(e) => {
                  setQuery("");
                  setSeachedProduct("");
                }}
              >
                <CloseOutline />
              </button>
              <div
                style={{ top: "40px", height: "300px" }}
                ref={searchContainerRef}
                className={`dropdown-menu w-100 mt-0 overflow-y-scroll ${
                  seachedProduct && seachedProduct.length !== 0 ? "show" : ""
                }`}
              >
                {seachedProduct &&
                  seachedProduct.map(
                    (product) =>
                      product &&
                      product.variants &&
                      product.variants[0] &&
                      product.variants.map((variant, index) => (
                        <HeaderSearchCard
                          product={product}
                          cart={cartData}
                          variant={variant}
                          index={index}
                        />
                      ))
                  )}
              </div>
            </div>
          </div>
          <div style={{ marginRight: "5%" }}>
            {getAuthToken() ? (
              <div className="d-inline-block me-3 position-relative">
                <div
                  className="header-profile-icon"
                  onClick={(e) => {
                    mobileCheck() && setBoxOpen(!isBoxOpen);
                  }}
                  onMouseEnter={(e) => {
                    setBoxOpen(true);
                  }}
                  onMouseLeave={(e) => {
                    setBoxOpen(false);
                  }}
                >
                  <Person height={"35px"} width={"35px"} />
                </div>
                {isBoxOpen && (
                  <div
                    className="header-profile-container shadow rounded p-2"
                    onMouseEnter={(e) => {
                      setBoxOpen(true);
                    }}
                    onMouseLeave={(e) => {
                      setBoxOpen(false);
                    }}
                  >
                    {ProfileData.map((data) => (
                      <div className="header-profile-items py-2 my-2 border-bottom">
                        <Link
                          className="d-flex align-items-center text-decoration-none text-dark"
                          to={data.link}
                          onClick={(e) => {
                            setBoxOpen(false);
                          }}
                        >
                          {data.icon} <span className="ms-2">{data.name}</span>
                        </Link>
                      </div>
                    ))}
                    <div
                      className="py-2 my-2 border-bottom header-profile-items"
                      onClick={(e) => {
                        localStorage.removeItem("auth");
                        localStorage.removeItem("cart");
                        window.location.reload();
                      }}
                    >
                      <Link
                        className="d-flex align-items-center text-decoration-none text-dark"
                        to="/"
                      >
                        <LogOutOutline /> <span className="ms-2">Logout</span>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Link to="/login" className="me-3">
                <LogInOutline height={"35px"} width={"35px"} />
              </Link>
            )}
            <div className="d-inline-block me-3 position-relative">
              <div className="header-cart-icon">
                <Link to="/cart">
                  <CartOutline height={"35px"} width={"35px"} />
                </Link>
                <span className="header-cart-number-offset">
                  {cartData.myCartData && cartData.myCartData.products
                    ? cartData.myCartData.products.length
                    : 0}
                </span>
              </div>
              <div className="header-cart-container shadow rounded p-2 overflow-y-scroll">
                {cartData.myCartData &&
                cartData.myCartData.products &&
                cartData.myCartData.products.length !== 0 ? (
                  <>
                    {cartData.myCartData.products.map((product) => (
                      <HeaderCartItem product={product} cart={cartData} />
                    ))}
                    <div className="d-grid gap-2">
                      <Link
                        to="/cart"
                        className="btn web-primary-color-button btn-block"
                      >
                        Checkout
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="py-2 my-2 border-bottom text-center">
                    Your cart is currently empty.
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
            
        <MobileNav headerMenu={headerMenu} isMobileMenuOpen={isMobileMenuOpen} toggleMobileMenu={toggleMobileMenu} setMobileMenuOpen={setMobileMenuOpen} alignmentClasses={alignmentClasses} />

        <div className="d-block d-md-none search-bar w-100 mb-2">
          <hr />
          <div className="input-group mx-auto mb-1" style={{ width: "90%" }}>
            <button
              className="btn web-primary-color-button web-primary-border dropdown-toggle dropdown-width m-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Category
            </button>
            <ul className="dropdown-menu header-dropdown-menu mt-0">
              {subCateArray.map((cate) => (
                <li key={cate.id}>
                  <Link
                    className="dropdown-item border-bottom text-wrap"
                    to={`/category/${cate.name}/${cate.id}`}
                    relative="path"
                  >
                    {cate.name}
                  </Link>
                </li>
              ))}
            </ul>

            <input
              ref={searchInputRef}
              type="text"
              className="form-control text-center web-primary-input"
              value={query}
              onChange={(e) => changeQueryHandler(e)}
              placeholder="Search for products..."
            />
            <button
              className={`btn border p-1 ${!query && "d-none"}`}
              type="button"
              onClick={(e) => {
                setQuery("");
                setSeachedProduct("");
              }}
            >
              <CloseOutline />
            </button>
            <div
              style={{ height: "60vh", top: "38px" }}
              ref={searchContainerRef}
              className={`shadow dropdown-menu w-100 mt-0 overflow-y-scroll overflow-x-hidden ${
                seachedProduct && seachedProduct.length !== 0 ? "show" : ""
              }`}
            >
              {seachedProduct &&
                seachedProduct.map(
                  (product) =>
                    product &&
                    product.variants &&
                    product.variants[0] &&
                    product.variants.map((variant, index) => (
                      <HeaderSearchCard
                        product={product}
                        cart={cartData}
                        variant={variant}
                        index={index}
                      />
                    ))
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
