import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './trackOrder.css'

import { RadioButtonOn } from 'react-ionicons'

const TrackOrder = ({orderHistory}) => {
    let statusElements=[];
    orderHistory.map(history => {
        let h_id=1;
        const localDate = new Date(history.created_at).toLocaleString().replace(/T/, ' ').replace(/\..+/, '');
        if(history.status_name === "NEW") {
            statusElements.push(
                {
                  id: h_id,
                  title: "New",
                  description: history.message,
                  date: localDate,
                  icon: <RadioButtonOn color='#fff' />,
                })
        } 
        else if (history.status_name === "SCHEDULED") {
          statusElements.push(
              {
                id: h_id,
                title: "Scheduled",
                description: history.message,
                date: localDate,
                icon: <RadioButtonOn color='#fff' />,
              })
      }
        else if (history.status_name === "PROCESSING") {
            statusElements.push(
                {
                  id: h_id,
                  title: "Processing",
                  description: history.message,
                  date: localDate,
                  icon: <RadioButtonOn color='#fff' />,
                })
        }
        else if (history.status_name === "CANCEL") {
            statusElements.push(
                {
                  id: h_id,
                  title: "Cancelled",
                  description: history.message,
                  date: localDate,
                  icon: <RadioButtonOn color='#fff' />,
                })
        }
        else if (history.status_name === "DELIVER") {
            statusElements.push(
                {
                  id: h_id,
                  title: "Delivered",
                  description: history.message,
                  date: localDate,
                  icon: <RadioButtonOn color='#fff' />,
                })
        }
        else if (history.status_name === "RETURN") {
            statusElements.push(
                {
                  id: h_id,
                  title: "Returned",
                  description: history.message,
                  date: localDate,
                  icon: <RadioButtonOn color='#fff' />,
                })
        }
        else if (history.status_name === "DENY") {
            statusElements.push(
                {
                  id: h_id,
                  title: "Denied",
                  description: history.message,
                  date: localDate,
                  icon: <RadioButtonOn color='#fff' />,
                })
        }
        h_id++;
    })

    let iconStyle={
        background: 'rgb(66, 134, 245)',
    }
    return (
        <div>
        <VerticalTimeline
        layout="1-column-left"
        lineColor='rgb(66, 134, 245)'
        >
          {
            statusElements.map(element => {
              let showButton = element.buttonText !== undefined && element.buttonText !== null && element.buttonText !== "";
              return (
                <VerticalTimelineElement
                  key={element.key}
                  date={element.date}
                  dateClassName='date'
                  iconStyle={iconStyle}
                  icon={element.icon}
                >
                  <h3 className='vertical-timeline-element-title'>
                    {element.title}
                  </h3>
                  <h5 className='vartical-timeline-element-subtitle'>
                    {element.location}
                  </h5>
                  <p id='description'>
                    {element.description}
                  </p>
                  {showButton && (<a href='/facebook.com' className='rvt-button'>{element.buttonText}</a>)}
                </VerticalTimelineElement>
              )
            })
          }
        </VerticalTimeline>
        </div>
    )
}

export default TrackOrder
