import React, { useEffect } from 'react';
import { CallOutline, HomeOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import useFetch from '../../../utils/useFetch';
import Loader from '../../common/Loader';

const CartUserDetails = ({selectedAddress, address, setAddress}) => {
    const {data, status, loading} = useFetch("deliveryAddress");

    useEffect(() => {
        if(!loading && status === "fetched" && data.data && data.data.length > 0){
            if(selectedAddress){
                let filteredAddress = data.data.find((value) => value.id === selectedAddress);
                if(filteredAddress){
                    setAddress(filteredAddress);
                }else{
                    setAddress(data.data[0]);
                }
            }else{
                setAddress(data.data[0]);
            }
        }
    }, [data, status, loading])
    
    return ( <>
    <Loader isOpen={loading}/>
    {address ?
        <div className="border rounded shadow m-3 p-2">
        <div className="card-body">
            <div>
                <h5 className="card-title mb-3 p-3 web-secondary-color-button">Your Details</h5>
            </div>
            { data.data.length > 0 ?
                <div className='px-2 px-md-4'>
                    <div>
                        <h5 className='d-inline'><b><HomeOutline /> Address:&nbsp;</b></h5>
                        <h5 className='d-inline ms-1'>{address.address}, {address.city}({address.zip}), {address.state}, {address.country}</h5> 
                    </div>
                    <div>
                        <h5 className='d-inline'><b><CallOutline/> Phone Number :&nbsp;</b></h5>
                        <h5 className='d-inline ms-1'>{address.phone}</h5> 
                    </div>
                    <Link className='btn web-primary-color-button mt-2' to={`/address`} >
                        Change Address
                    </Link>
                </div>
                :
                <Link to="/address" className="btn web-primary-color-button">Select Delivery Address</Link>
            }
        </div>
    </div>
    :
    <Link to="/address" className="btn btn-primary m-3">Set Delivery Address</Link>}</>
    )
}

export default CartUserDetails