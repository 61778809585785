import React from "react";

const Poster = ({ poster }) => {
  if (!poster || !poster.image) {
    return null;
  }

  const { image, redirect_link } = poster;

  return (
    <div className="mt-2 p-0 m-0 border border-2" style={{ width: "100%" }}>
      {/* Add a null check for redirect_link to avoid rendering an invalid link */}
      {redirect_link && (
        <a href={redirect_link} rel="noopener noreferrer" target="_blank">
          <img
            src={image}
            className="card-img-top p-0 img-fluid"
            alt="Poster Image"
          />
        </a>
      )}
      {/* Render the image without a link if redirect_link is not provided */}
      {!redirect_link && (
        <img
          src={image}
          className="card-img-top p-0 img-fluid"
          alt="Poster Image"
        />
      )}
    </div>
  );
};

export default Poster;
