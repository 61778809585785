import React, { useEffect, useState } from "react";
import styles from "../styles/Newyear.module.css";
const textShadowColor = [
  styles.white,
  styles.green,
  styles.orange,
  styles.pink,
  styles.violet,
  styles.blue,
  styles.red,
  styles.yellow,
];

const color = [
  "#0c0f0a",
  "#1fd224",
  "#ffaa01",
  "#ff00aa",
  "#aa00ff",
  "#00aaff",
  "#f00",
  "#ffea00",
];
const Newyear = () => {
  const [count, setCount] = useState(1);
  useEffect(() => {
    snowFall();
    glowingText();
  }, [count]);
  const snowFall = () => {
    const body = document.querySelector(`.${styles.body}`);
    const snowWrapper = document.createElement("div");
    snowWrapper.classList.add(styles.snowWrapper);
    body.appendChild(snowWrapper);
    for (let i = 0; i < 300; i++) {
      const snow = document.createElement("span");
      snow.classList.add(styles.snow);
      if (i % 4 === 0) {
        snow.classList.add(styles.snowAnimation1);
      } else if (i % 4 === 1) {
        snow.classList.add(styles.snowAnimation2);
      } else if (i % 4 === 2) {
        snow.classList.add(styles.snowAnimation3);
      } else {
        snow.classList.add(styles.snowAnimation4);
      }
      const aNumber = -50 + randomNumber(150);
      const timeDelay = randomNumber(10);
      const animDuration = 5 + randomNumber(10) + Math.random() + 0.2;
      const snowOpacity = randomNumber(10) * 0.1;
      const heightWidth = randomNumber(11) + 5;
      snow.style.width = `${heightWidth}px`;
      snow.style.height = `${heightWidth}px`;
      snow.style.left = `${aNumber}%`;
      snow.style.animationDelay = `${timeDelay}s`;
      snow.style.animationDuration = `${animDuration}s`;
      snow.style.opacity = `${snowOpacity}`;
      snowWrapper.appendChild(snow);
    }
  };
  const randomNumber = (limit) => {
    return Math.floor(Math.random() * limit);
  };
  const glowingText = () => {
    const newYear = document.querySelector(`.${styles.newYear}`);
    const year = document.querySelector(`.${styles.year}`);
    const snow = document.querySelectorAll(`.${styles.snow}`);
    let count = 0;
    const changeColor = () => {
      newYear.classList.remove(textShadowColor[count]);
      year.classList.remove(textShadowColor[count]);
      snow.forEach((e) => {
        e.classList.remove(textShadowColor[count]);
        e.style.backgroundColor = "transparent";
      });
      count = (count + 1) % textShadowColor.length;
      newYear.classList.add(textShadowColor[count]);
      year.classList.add(textShadowColor[count]);
      snow.forEach((e) => {
        e.classList.add(textShadowColor[count]);
        e.style.backgroundColor = color[count];
      });
    };
    changeColor();
    const interval = setInterval(changeColor, 2000);

    
    const maxIterations = 10;
    let currentIteration = 0;

    const checkMaxIterations = () => {
      currentIteration++;
      if (currentIteration === maxIterations) {
        clearInterval(interval);
      }
    };

    
    const iterationCheckInterval = setInterval(checkMaxIterations, 2000);
  };
  return (
    <div className={styles.body}>
      <div className={styles.bg}></div>
      <main className={styles.newYearBody}>
        <h1 className={`${styles.newYear} ${styles.glow}`}>Happy New Year</h1>
        <p className={`${styles.year} ${styles.glow} ${styles.glows} ` }>2024</p>
      </main>
    </div>
  );
};

export default Newyear;
