

export const isAuthenticated = () => {

    if (localStorage.getItem("auth")) {
        const token = JSON.parse(localStorage.getItem("auth")).token
        // axios.get(`${apiUrl}/?authorization=${token}`).then((response) => {
        //     if (response.status === 200)
        //         return true
        //     else
        //         return false
        // }).catch(err => false)

        return token ? true : false
    }
    else
        return false

}