import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { REACT_APP_API_HOST } from '../../utils/api';
import { useLoader } from '../../utils/contexts';
import { getDomain } from '../../utils/storageService';
import { numericValidation } from '../../utils/validators';

const MobileSignup = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [timer, setTimer] = useState(59);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [validation, setValidation] = useState({
        phone: "",
        otp: ""
    });
    const loader = useLoader();

    const history = useHistory();
    let myTimeout;

    useEffect(() => {
        return () => { 
            if(myTimeout){
                clearTimeout(myTimeout);
            }
        } 
    }, [])

    let myInterval;
    
    const resetTimer = () => {
        clearInterval(myInterval);
        clearTimeout(myTimeout);
    };
    
    const resendOtp = () => {
        resetTimer();
        setIsButtonDisabled(true);
        let count = 59;
        setTimer(count);
        myTimeout = setTimeout(() => {
            setIsButtonDisabled(false);
        }, 59000);
        myInterval = setInterval(() => {
            setTimer((prevState) => {
                if (count > 0) {
                    count -= 1;
                    return count;
                } else {
                    clearInterval(myInterval);
                    setIsButtonDisabled(false);
                    return 0;
                }
            });
        }, 1000);
    };

    const handleChange = (element, index,e) => {
        

        if (isNaN(element.value)) return false;
        setValidation({...validation, otp: ''});
        setOtp([...otp.map((d, idx)=> (idx === index) ? element.value : d)]);
        
        if (element.value === ''){
            if(element.previousSibling)
                element.previousSibling.focus()
        } else {
            if(element.nextSibling)
                element.nextSibling.focus()
        }
    }

  const handleKeyDown = (element, index, e) => {
    const value = e.target.value;
    
    if (e.key === 'Backspace' && value === '') {
        // Move the cursor to the end of the previous block
        if (index > 0 && element.previousSibling) {
            const prevInput = element.previousSibling;
            prevInput.focus();
            prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
            e.preventDefault();
        }
        return;
    }
}


    const signUp = async () => {
      let validationResult = true;
      if (!phoneNumber) {
        setValidation({
          ...validation,
          phone: "Phone number should not be empty.",
        });
        validationResult = false;
        setTimeout(() => {
          setValidation({ ...validation, phone: "" }); // Clear the error after a few seconds
        }, 5000); // Adjust the timing as needed (5 seconds in this case)
      } else {
        if (phoneNumber.length !== 10 || !numericValidation(phoneNumber)) {
          setValidation({
            ...validation,
            phone: "Phone number is invalid.",
          });
          validationResult = false;
          setTimeout(() => {
            setValidation({ ...validation, phone: "" }); // Clear the error after a few seconds
          }, 5000); // Adjust the timing as needed (5 seconds in this case)
        }
      }
      if (!validationResult) {
        return;
      } else {
        setValidation({ ...validation, phone: "" });
      }
      try {
        fetch(`${REACT_APP_API_HOST}oauth/mobile-otp`, {
          method: "POST",
          headers: {
            "x-ik-domain": getDomain(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile: phoneNumber,
            scope: "buyers",
          }),
        }).catch((error) => {
          console.log(error);
        });
        resendOtp();
        setTimer(59);
      } catch (err) {
        console.log(err);
      }
    };

    const verifyOtp = async(e) => {
    let otpp = otp.join('');
    if (!otpp || otpp.length !== 4) {
        setValidation({
            ...validation,
            otp: !otpp ? "Please enter the OTP." : "OTP must be 4 digits."
        });
        return;
    }
        try{
            loader.setLoaderOpen(true);
            fetch(`${REACT_APP_API_HOST}oauth/access-token`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                        username: phoneNumber,
                        password: otpp,
                        scope: 'buyers',
                        thirdparty: 'mobile-otp'
                    })
            }).then(response => response.json())
            .then(data => {
                if(data && data.data){
                    loader.setLoaderOpen(false);
                    localStorage.setItem("auth", JSON.stringify(data.data));
                    history.push('/');
                }
                if(data && data.message && data.message === "invalid credentials"){
                    setValidation({...validation, otp: "OTP is invalid."});
                }
                loader.setLoaderOpen(false);
            })
            .catch(error => {
                loader.setLoaderOpen(false);
                console.log(error);
            });
        }
        catch(err) {
            loader.setLoaderOpen(false);
            console.log(err);
        } 
    }

    return <section className="sign-up-background min-vh-100 text-center py-5">
        <div className="container border text-center mt-5 p-5  mx-1 mx-sm-auto signup-container rounded">
            <h3 className="text-center">Sign In</h3>
            <div className="text-center mt-3">Enter Mobile number</div>
                <div className="input-group mt-3">
                    <span className="input-group-text">+91</span>
                        <input type="text" 
                        className="form-control" 
                        placeholder="Enter mobile number" 
                        onChange={e => setPhoneNumber(e.target.value)}
                        value={phoneNumber}                        
                        required
                        />
                </div>
                {validation.phone && <p className='text-danger'>{validation.phone}</p>}
                {isButtonDisabled ? 
                    <div>
                        <b>Resend OTP in</b>
                        <b className='ml-2 text-danger'>00:{timer<=9 ? `0${timer}`: `${timer}`}</b>
                    </div> :
                    <div className="pt-4">
                        <button type='button' onClick={e=> {signUp()}} className='btn btn-primary'>Send OTP</button>
                    </div>
                }
                <div className="inputLabel text-center pt-4">Enter OTP</div>
                <br/>
                <div className='d-flex'>
                    {otp.map( (data, index) => {
                        return <input 
                    className="form-control text-center mx-2 bg-light border-dark-subtle"
                    type='text'
                    name='otp'
                    maxLength= '1'
                    required
                    key={index}
                    value={data}
                    onInput={e => handleChange(e.target, index,e)}
                            onFocus={e => e.target.select()}
                            onKeyDown={(e)=>handleKeyDown(e.target,index,e)}
                    />
                    })}
                </div>
                    {validation.otp && <p className='text-danger'>{validation.otp}</p>}
                    <div className="text-center pt-5">
                        <button type="button"  onClick={e=> {verifyOtp()}} className="btn btn-primary">Verify Now</button>
                    </div>
        </div>
    </section>
}

export default MobileSignup