import React, { useEffect } from "react";
import styles from "../styles/ChristmasCard.module.css";
const ChristmasCard = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      window.particlesJS("snow-particles-js", {
        particles: {
          number: {
            value: 400,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#fff",
          },
          shape: {
            type: "circle",  
            stroke: {
              width: 0,
              color: "#000000",
            },
            polygon: {
              nb_sides: 5,
            },
          },
          opacity: {
            value: 1,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 10,
            random: true,
            anim: {
              enable: false,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "bottom",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse",
            },
            onclick: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5,
              },
            },
            bubble: {
              distance: 100,
              size: 4,
              duration: 0.3,
              opacity: 1,
              speed: 3,
            },
            repulse: {
              distance: 100,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      });
    };
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
   playAudio(); 
  },[]);
  const playAudio = () => {
    const audio = new Audio("/jingle_bells.mp3"); 
    audio.play().catch(error => {
      console.error("Error playing audio:", error);
    });
  };

  return (
    <>
      <div className={styles.snowContainer}>
        <div id="snow-particles-js" className={styles.snow}></div>
        <div className={styles.trees}>
          <div className={styles.merry}>
            Merry Christmas
          </div>
          <div className={styles.fox}>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/25839/critters.svg" width="300px" alt="Fox" />
          </div>
          <div className={styles.tree}>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/25839/tree.svg" width="200px" alt="Tree" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChristmasCard;
