import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import WhatsappChatWidget from '../../components/common/whatsapp-chat-widget';
import { useStore } from '../../utils/contexts';

const Footer = () => {
    const [whatsappNumber, setWhatsappNumber] = useState("");
    const storeData = useStore();

    const [footerMenu, setFooterMenu] = useState({})

    const componentOrder = {
        footer_left: ["Menu", "AppDownload", "Address", "Pages", "PhoneSocial"],
        footer_middle_left: ["AppDownload", "Menu", "Address", "Pages", "PhoneSocial"],
        footer_right: ["AppDownload", "Address", "Pages", "PhoneSocial", "Menu"],
        footer_center: ["AppDownload", "Address", "Menu", "Pages", "PhoneSocial"],
        footer_middle_right: ["AppDownload", "Address", "Pages", "Menu", "PhoneSocial"],
    };

    useEffect(() => {
        if(storeData.data && storeData.data.addons && storeData.data.addons.whatsapp_support && storeData.data.addons.whatsapp_support.status === 'active') {
            setWhatsappNumber(storeData.data.addons.whatsapp_support.whatsapp_phone);
        }
        if (storeData.data && storeData.data.menus && storeData.data.menus.length > 0) {
            const validPositions = Object.keys(componentOrder);
            const filteredMenu = storeData.data.menus.find(menu => menu.position != null && validPositions.includes(menu.position));
            if (filteredMenu) {
                setFooterMenu(filteredMenu);
            }
        }
    }, [storeData])

    const renderFooterComponents = (position) => {
        const order = componentOrder[position] || [];
        return order.map((component) => {
            return renderComponent(component);
        });
    };

    const renderComponent = (component) => {
        switch (component) {
            case "Menu":
                return <Menu key={component} footerMenu={footerMenu} />;
            case "AppDownload":
                return <AppDownload key={component} apkUrl={storeData.data.store.apk_url} />;
            case "Address":
                return <Address key={component} address={storeData.data.store.address} />;
            case "Pages":
                return <Pages key={component} pages={storeData.data.pages} />;
            case "PhoneSocial":
                return  <PhoneSocial key={component} phone={storeData.data.store.phone} socialLinks={storeData.data.social_link} footerMenu={footerMenu} />;
            default:
                return null;
        }
    };

    return (<footer className='mt-3'>
        <div style={{ backgroundColor: '#333333',color:"white" }}>
            <div className="container py-3 ">
                {storeData && storeData.data && storeData.data.store &&
                    <div className="row">
                        { renderFooterComponents((footerMenu && footerMenu.position != null) ? footerMenu.position : "footer_right")}

                    </div>}
            </div>
        </div>

        <div style={{ backgroundColor: '#111111',color:"white" }} className=''>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <p className='m-0 px-3 py-2 d-flex'>
                        <span>&copy;</span> <span className='px-2' dangerouslySetInnerHTML={{__html: storeData.data.footer.footer_text}}></span>
                        </p>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className=''>
                            <img src={`../../images/footerImage.png`} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    { (whatsappNumber) && <WhatsappChatWidget url={window.location.href} phone={whatsappNumber} />}
    </footer>
    )
}

export default Footer

const AppDownload = ({ apkUrl }) => {
    return apkUrl ? (
        <div className="col-md mb-2">
            <p className="mb-0">Download our Mobile App</p>
            <a href={apkUrl} target="_blank" rel="noopener noreferrer">
                <img src={"http://seller.inteliheads.com/uploads/logo/buuntpdmop_google-play.png"} alt="android app" width="115px" height="50px" />
            </a>
        </div>
    ): null;
}

const Address = ({ address }) => {
    return address ? (
        <div className="my-1 col-md mb-2">
            <h5><ion-icon name='location-sharp' /> Address</h5>
            <h6>{address}</h6>
        </div>
    ): null;
}

const Pages = ({pages}) => {
    return pages && pages.length > 0 ? (
        <div className=" my-1 col-md mb-2">
            <h5>Pages</h5>
            {pages.map((page)=>
            <div key={page.slug}>
                <Link to={`/${page.slug}`} className="text-light text-decoration-none">
                    {page.name}
                </Link>
            </div> 
            )}
        </div>
    ): null
}

const Menu = ({footerMenu}) => {
    return footerMenu && footerMenu.links && footerMenu.links.length > 0 ? (
        <div className=" my-1 col-md mb-2">
            <h5>{footerMenu.name}</h5>
            {footerMenu.links.map((menu)=>
            <div key={menu.link}>
                <a href={`${menu.link}`} rel="noopener noreferrer" target="_blank" className="text-light text-decoration-none">
                    {menu.text}
                </a>
            </div> 
            )}
        </div>
    ): null
}

const PhoneSocial = ({ footerMenu, phone, socialLinks }) => {
    return (
        <>
            {footerMenu && footerMenu.links && footerMenu.links.length > 0 ? (
                <div className="my-1 col-md mb-2">
                    {phone && (
                        <>
                            <h5>
                                <ion-icon name="call-sharp" /> Phone
                            </h5>
                            <a href={`tel:${phone}`} className="text-light fs-4 text-decoration-none" style={{ color: "white" }}>
                                <h6>{phone}</h6>
                            </a>
                        </>
                    )}
                    {(socialLinks.facebook != null || socialLinks.twitter != null || socialLinks.instagram != null || socialLinks.linkedin != null || socialLinks.youtube != null) && (
                        <ul className="d-flex list-unstyled my-1">
                            {socialLinks.facebook != null && (
                                <li className="mx-1">
                                    <a href={socialLinks.facebook} className="footer_logo text-light fs-4 text-decoration-none" title="facebook" target="_blank">
                                        <ion-icon name="logo-facebook" />
                                    </a>
                                </li>
                            )}
                            {socialLinks.twitter != null && (
                                <li className="mx-1">
                                    <a href={socialLinks.twitter} className="footer_logo text-light fs-4 text-decoration-none" title="twitter" target="_blank">
                                        <ion-icon name="logo-twitter" />
                                    </a>
                                </li>
                            )}
                            {socialLinks.instagram != null && (
                                <li className="mx-1">
                                    <a href={socialLinks.instagram} className="footer_logo text-light fs-4 text-decoration-none" title="instagram" target="_blank">
                                        <ion-icon name="logo-instagram" />
                                    </a>
                                </li>
                            )}
                            {socialLinks.linkedin != null && (
                                <li className="mx-1">
                                    <a href={socialLinks.linkedin} className="footer_logo text-light fs-4 text-decoration-none" title="linkedin" target="_blank">
                                        <ion-icon name="logo-linkedin" />
                                    </a>
                                </li>
                            )}
                            {socialLinks.youtube != null && (
                                <li className="mx-1">
                                    <a href={socialLinks.youtube} className="footer_logo text-light fs-4 text-decoration-none" title="youtube" target="_blank">
                                        <ion-icon name="logo-youtube" />
                                    </a>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            ) : (
                <>
                    {phone && (
                        <div className="my-1 col-md mb-2">
                            <h5>
                                <ion-icon name="call-sharp" /> Phone
                            </h5>
                            <a href={`tel:${phone}`} className="text-light fs-4 text-decoration-none" style={{ color: "white" }}>
                                <h6>{phone}</h6>
                            </a>
                        </div>
                    )}
                    {(socialLinks.facebook != null || socialLinks.twitter != null || socialLinks.instagram != null || socialLinks.linkedin != null || socialLinks.youtube != null) && (
                        <div className="col-md mb-2">
                            <ul className="d-flex list-unstyled my-1">
                                {socialLinks.facebook != null && (
                                    <li className="mx-1">
                                        <a href={socialLinks.facebook} className="footer_logo text-light fs-4 text-decoration-none" title="facebook" target="_blank">
                                            <ion-icon name="logo-facebook" />
                                        </a>
                                    </li>
                                )}
                                {socialLinks.twitter != null && (
                                    <li className="mx-1">
                                        <a href={socialLinks.twitter} className="footer_logo text-light fs-4 text-decoration-none" title="twitter" target="_blank">
                                            <ion-icon name="logo-twitter" />
                                        </a>
                                    </li>
                                )}
                                {socialLinks.instagram != null && (
                                    <li className="mx-1">
                                        <a href={socialLinks.instagram} className="footer_logo text-light fs-4 text-decoration-none" title="instagram" target="_blank">
                                            <ion-icon name="logo-instagram" />
                                        </a>
                                    </li>
                                )}
                                {socialLinks.linkedin != null && (
                                    <li className="mx-1">
                                        <a href={socialLinks.linkedin} className="footer_logo text-light fs-4 text-decoration-none" title="linkedin" target="_blank">
                                            <ion-icon name="logo-linkedin" />
                                        </a>
                                    </li>
                                )}
                                {socialLinks.youtube != null && (
                                    <li className="mx-1">
                                        <a href={socialLinks.youtube} className="footer_logo text-light fs-4 text-decoration-none" title="youtube" target="_blank">
                                            <ion-icon name="logo-youtube" />
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
