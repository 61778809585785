import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { REACT_APP_API_HOST } from '../../utils/api';
import { getDomain } from '../../utils/storageService';
import Loader from '../common/Loader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLoader } from '../../utils/contexts';

const GoogleLogin = (props) => {
  const [checking, setchecking] = useState("loading");
  const history = useHistory();
  const loader = useLoader(); // Get the loader object from the useLoader hook

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get("email");
    let token = params.get("token");
    var bodyFormData = new FormData();

    const data = {
      username: email,
      password: token,
      scope: "buyers",
      thirdparty: "google",
    };
    for (const key in data) bodyFormData.append(key, data[key]);

    // Set loader to open before making the API call
    loader.setLoaderOpen(true);

    fetch(`${REACT_APP_API_HOST}oauth/access-token`, {
      method: "POST",
      headers: {
        "x-ik-domain": getDomain(),
      },
      body: bodyFormData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          localStorage.setItem("auth", JSON.stringify(data.data));
          setchecking(true);
          history.push("/");
        }
      })
      .catch((error) => {
        console.log(error);
        setchecking(false);
      })
      .finally(() => {
        // Set loader to close after API call completes (regardless of success or failure)
        loader.setLoaderOpen(false);
      });
  }, [loader, history]);

  // Conditional rendering using JavaScript
  if (checking === "loading") {
    return <Loader isOpen={true} />;
  } else if (checking === true) {
    return <Redirect to="/" />;
  } else {
    return <Redirect to="/login" />;
  }
}

export default GoogleLogin