import React from "react";
import { useStore } from "../../utils/contexts";

function StoreOffline() {
    const storeData = useStore();

    const logoLink =
    storeData && storeData.data && storeData.data.store && storeData.data.store.logo_internet_url;

    return (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center bg-secondary">
            <div className="d-flex flex-column bg-white p-4">
                <div className="d-flex justify-content-center mb-3">
                    <img
                        src={logoLink}
                        alt="company Logo"
                        width="80px"
                        height="80px"
                    />
                </div>
                <h2 className="text-secondary text-center">Store is Offline</h2>
                <div className="text text-center">
                    <p>Sorry for the inconvenience!</p>
                    <p>
                        We are Offline from{" "}
                        <span className="text-decoration-underline text-secondary fw-bold">
                            {new Date(
                                storeData.data.store.offline_start_date
                            ).toLocaleString("en-us", {
                                day: "numeric",
                                month: "short",
                                hour: "numeric",
                                minute: "numeric",
                            })}
                        </span>{" "}
                        to{" "}
                        <span className="text-decoration-underline text-secondary fw-bold">
                            {new Date(
                                storeData.data.store.offline_end_date
                            ).toLocaleString("en-us", {
                                day: "numeric",
                                month: "short",
                                hour: "numeric",
                                minute: "numeric",
                            })}
                        </span>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
}

export default StoreOffline;
