import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useAPI from '../../../utils/api';
import { useLoader } from '../../../utils/contexts';
import { getCurrencySymbol } from '../../../utils/currency';

const ProductCard = ({color, product, cart, category, className}) => {
    const API = useAPI();
    const loader = useLoader();
    const [productInCart, setProductInCart] = useState(null);
    const [qty, setQty] = useState(null);

    const postCartData = async (item, variant_index, quantity) =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.addProductInCart(
            {
                product: item,
                variant_index: variant_index,
                qty: quantity,
            },
        );
        if(ok && response){
            setQty(quantity);
            cart.setMyCartData(response.data);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    const deleteCartProduct = async (id, variant_index) =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.deleteProductInCart({
            product_id: id,
            variant_index: variant_index,
        });
        if(ok && response){
            setQty(null);
            setProductInCart(null);
            cart.setMyCartData(response.data);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    useEffect(() => {
        if(cart && cart.myCartData && cart.myCartData.products){
            const filterProduct = cart.myCartData.products.find((result) => result.id === product.id && result.variant_index === 0);
            if(filterProduct){
                setProductInCart(filterProduct);
                setQty(filterProduct.qty);
            }
        }
    }, []);

    return (product && product.variants && product.variants[0] ? 
        <div className={`card shadow rounded p-2 m-0 mx-sm-2 card-animation product-card ${className ? 'mx-1' : 'mx-auto'} ${className}`}>
            <Link className='card-link' to={"/product/" + product.name.replace(/[^a-zA-Z0-9]/g, "") + "/" + product.id }>
                <div className='product-image-container'>
                    {product.variants[0].display_mrp &&
                    product.variants[0].display_mrp >
                        product.variants[0].mrp &&
                        <div className="off-sticker">
                            <p>
                                {(
                                    ((product.variants[0].display_mrp -
                                        product.variants[0].mrp) *
                                        100) /
                                    product.variants[0].display_mrp
                                ).toFixed(0)}
                                % OFF
                            </p>
                        </div>
                    }
                    <img className="card-img-top category-image" src={product.variants[0].images[0].image_url_small} alt={product.name}/>
                </div>
                <div className="mt-3">
                    <p className="h6 text-wrap">{product.name && product.name.length > 14 ? product.name.substring(0, 12) + "..." : product.name}</p>
                    <p className="h6">
                        <span>
                        {getCurrencySymbol()} {product.variants[0].mrp}{" "}
                        </span>
                        {product.variants[0].display_mrp &&
                        product.variants[0].display_mrp > product.variants[0].mrp && <>
                        <span>
                            <del>{getCurrencySymbol()} {product.variants[0].display_mrp}{" "}</del>
                        </span>
                        <br/>
                        <span style={{fontSize: "15px", color: "green"}}>
                            You save {getCurrencySymbol()}{" "}
                            {(
                                product.variants[0].display_mrp -
                                product.variants[0].mrp
                            ).toFixed(2)}{" "}
                        </span>
                        </>}
                    </p>
                </div>
            </Link>
                <div className="prd-card-body text-center px-0 py-1">
                <div className='d-flex'>
                        {product.attributes == null ? (
                            product.variants[0].available_quantity !== null &&
                            product.variants[0].available_quantity === 0 ?
                                <p className='out-of-stock-text text-danger mb-2'><b> Out of Stock</b></p>
                            : 
                            product.product_type=="DIGITAL" ? <>
                            
                            <Link to={`${process.env.PUBLIC_URL}/cart`}   className="view-detail-button d-block w-100 rounded text-decoration-none web-secondary-color-button" 
                            onClick={() => postCartData( product, 0, 1 )}>
                        Buy now
                    </Link>
                            </>
                            : <button className='add-button'
                                        title="Add to cart"
                                        onClick={() =>
                                            {
                                                (productInCart || (qty && qty > 0))
                                                    ? postCartData(product, 0, qty + 1 )
                                                    : postCartData( product, 0, 1 );
                                            }
                                        }
                                        disabled={
                                            product.variants[0].available_quantity !== null &&
                                            (productInCart || qty) && qty && 
                                            qty >= product.variants[0].available_quantity
                                        }
                                    >
                                        <ion-icon
                                            class="add-button-icon web-primary-color-button"
                                            name="add"
                                        />
                                    </button>
                        ) : product.variants.every(
                                    (v) =>
                                        v.available_quantity != null &&
                                        v.available_quantity === 0
                                ) ?
                                    <p className='out-of-stock-text text-danger'> Out of Stock </p>
                                : (
                                    <Link
                                        className="view-detail-button d-block w-100 rounded text-decoration-none web-secondary-color-button"
                                        to={
                                            "/" +
                                            "product/" +
                                            product.name.replace(
                                                /[^a-zA-Z0-9]/g,
                                                ""
                                            ) +
                                            "/" +
                                            product.id
                                        }
                                    >
                                        View Details
                                    </Link>
                                )
                        }
                        {product.attributes == null && (product.product_type=="PHYSICAL" || product.product_type==null) &&
                        (productInCart || (qty && qty > 0)) &&
                            <div className='d-inline px-sm-1'>
                                <input
                                    style={{
                                        width: "100%",
                                        borderRadius: "3px",
                                        height: "1.8rem",
                                        display: "inline",
                                        textAlign: "center"
                                    }}
                                    type="tel"
                                    name="quantity"
                                    value={qty}
                                    min="1"
                                    max={
                                        product.variants[0].max_order_quantity
                                    }
                                    onChange={(e) => {
                                        let { value } = e.target;
                                        if (value.match("[^0-9]")) {
                                            value = "";
                                        }
                                        let stock = product.variants[0].available_quantity;
                                        if (stock != null && value > stock) {
                                            value = stock;
                                        }
                                        if (value !== "")
                                            postCartData(
                                                product,
                                                0,
                                                Math.max(1, parseInt(value))
                                            );
                                    }}
                                    className="form-control"
                                />
                            </div>
                        }

                        {product.attributes == null && (product.product_type=="PHYSICAL" || product.product_type==null) && (productInCart || (qty && qty > 0)) &&
                            <button
                                className='delete-button'
                                title="Decrease Quantity By 1"
                                onClick = {() => {
                                    if(qty === 1){
                                        deleteCartProduct(product.id, 0);
                                    } else {
                                        postCartData(
                                            product,
                                            0,
                                            qty - 1
                                        );
                                    }
                                }}
                            >
                                <ion-icon
                                    class="delete-button-icon"
                                    name={qty === 1 ? "trash" : "remove"}
                                />
                            </button>
                        }
                    </div>
                </div>
            </div> : <></>
    )
}

export default ProductCard;
