import {
  Splide,
  SplideSlide
} from '@splidejs/react-splide';
import React from 'react';

const BannerSlides = ({banners}) => {
  const options = {
    type: 'loop',
    autoplay: true,
    arrows: false,
    pagination: false
  }

  const customizedArray = (array)=> {
    let arr = [];
    let arrForNull = [];
    for (const slide of array) {
      if (slide) {
        if(slide.display_order){
          arr[slide.display_order - 1] = slide;
        }else{
          arrForNull.push(slide);
        }
      }
    }
    return [...arr, ...arrForNull];
  }

  return (
    <Splide aria-label="My Favorite Images" options={options}>
      {banners && customizedArray(banners).map((banner) => (
        <SplideSlide>
          <a href={banner.redirect_link} rel="noopener noreferrer" target="_blank">
            <img
              width={'100%'}
              src={banner.image_link}
              loading='lazy'
              alt='banner'
            />
          </a>
        </SplideSlide>
      ))}
    </Splide>
  )
}

export default BannerSlides