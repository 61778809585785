import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAPI from "../../../utils/api";
import { useLoader } from "../../../utils/contexts";
import { getCurrencySymbol } from '../../../utils/currency';

const HeaderSearchCard = ({ product, cart, variant, index }) => {
  const API = useAPI();
  const loader = useLoader();
  const [productInCart, setProductInCart] = useState(null);
  const [qty, setQty] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 766);

  const postCartData = async (item, variant_index, quantity) => {
    loader.setLoaderOpen(true);
    const [ok, response] = await API.addProductInCart({
      product: item,
      variant_index: variant_index,
      qty: quantity,
    });
    if (ok && response) {
      setQty(quantity);
      cart.setMyCartData(response.data);
    } else {
      if (response && response.error) {
        alert(response.error);
      }
    }
    loader.setLoaderOpen(false);
  };

  const deleteCartProduct = async (id, variant_index) => {
    loader.setLoaderOpen(true);
    const [ok, response] = await API.deleteProductInCart({
      product_id: id,
      variant_index: variant_index,
    });
    if (ok && response) {
      setQty(null);
      setProductInCart(null);
      cart.setMyCartData(response.data);
    } else {
      if (response && response.error) {
        alert(response.error);
      }
    }
    loader.setLoaderOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 766) setIsSmallScreen(true);
      else {
        setIsSmallScreen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (cart && cart.myCartData && cart.myCartData.products) {
      const filterProduct = cart.myCartData.products.find(
        (result) => result.id === product.id && result.variant_index === index
      );
      if (filterProduct) {
        setProductInCart(filterProduct);
        setQty(filterProduct.qty);
      }
    }
  }, []);

  return (
    <div className="search-header-drop dropdown-item d-flex justify-content-between align-items-center py-2 border-bottom">
      <Link
        className="d-flex text-decoration-none text-dark"
        to={
          "/product/" +
          product.name.replace(/[^a-zA-Z0-9]/g, "") +
          "/" +
          product.id
        }
      >
        <div className="p-1 border border-secondary rounded">
          <img
            width={isSmallScreen ? "50px" : "60px"}
            height={isSmallScreen ? "50px" : "60px"}
            src={product.variants[0].images[0].image_url_small}
            alt={product.name}
          />
        </div>
        <div className="ms-2">
          {!isSmallScreen && (
            <p className="h6 text-wrap">
              <b>
                {product.name && product.name.length > 20
                  ? product.name.substring(0, 20) + "..."
                  : product.name}
              </b>
            </p>
          )}
          {isSmallScreen && (
            <p className="h6 text-wrap">
              <b>
                {product.name && product.name.length > 10
                  ? product.name.substring(0, 10) + "..."
                  : product.name}
              </b>
            </p>
          )}
          {variant.attribute_value_mappings &&
            variant.attribute_value_mappings.map((attribute) => (
              <p className="mb-0">
                {attribute.attribute_name} : {attribute.attribute_value}
              </p>
            ))}
        </div>

        {!isSmallScreen && (
          <div className="ms-2 text-center">
            <p className="h6">
              <span>
                <b>
                  {getCurrencySymbol()} {variant.mrp}{" "}
                </b>
              </span>
              <br />
              {variant.display_mrp && variant.display_mrp > variant.mrp && (
                <>
                  <span>
                    <del>
                      {getCurrencySymbol()} {variant.display_mrp}{" "}
                    </del>
                  </span>
                  <br />
                  <span style={{ fontSize: "15px", color: "green" }}>
                    You save {getCurrencySymbol()}{" "}
                    {(variant.display_mrp - variant.mrp).toFixed(2)}{" "}
                  </span>
                </>
              )}
            </p>
          </div>
        )}
      </Link>
      <div className="d-flex flex-column">
        {isSmallScreen && (
          <p className="text-center">
            <b>
              {getCurrencySymbol()} {variant.mrp}{" "}
            </b>
          </p>
        )}
        <div className="d-flex align-items-center">
        {variant.available_quantity !== null &&
        variant.available_quantity === 0 ? (
          <p className="out-of-stock-text text-danger mb-2">
            <b> Out of Stock</b>
          </p>
        ) :
        
        product.product_type !="DIGITAL"?
        
        (
          <button
            className="add-button"
            title="Add to cart"
            onClick={() => {
              productInCart || (qty && qty > 0)
                ? postCartData(product, index, qty + 1)
                : postCartData(product, index, 1);
            }}
            disabled={
              variant.available_quantity !== null &&
              (productInCart || qty) &&
              qty &&
              qty >= variant.available_quantity
            }
          >
            <ion-icon
              class="add-button-icon web-primary-color-button"
              name="add"
            />
          </button>
        ):""}
        { (product.product_type !="DIGITAL") && (productInCart || (qty && qty > 0)) && (
          <div className="d-inline px-2">
            <input
              style={{
                height: "1.8rem",
                width: "50px",
              }}
              type="tel"
              name="quantity"
              value={qty}
              min="1"
              max={variant.max_order_quantity}
              onChange={(e) => {
                let { value } = e.target;
                if (value.match("[^0-9]")) {
                  value = "";
                }
                let stock = variant.available_quantity;
                if (stock != null && value > stock) {
                  value = stock;
                }
                if (value !== "")
                  postCartData(product, index, Math.max(1, parseInt(value)));
              }}
              className="form-control rounded d-inline text-center"
            />
          </div>
        )}

        { (product.product_type !="DIGITAL") && (productInCart || (qty && qty > 0)) && (
          <button
            className="delete-button"
            title="Decrease Quantity By 1"
            onClick={() => {
              if (qty === 1) {
                deleteCartProduct(product.id, index);
              } else {
                postCartData(product, index, qty - 1);
              }
            }}
          >
            <ion-icon
              class="delete-button-icon"
              name={qty === 1 ? "trash" : "remove"}
            />
          </button>
        )}
      </div>
      </div>
    </div>
  );
};

export default HeaderSearchCard;
