import {
    Splide,
    SplideSlide
} from '@splidejs/react-splide';
import React, { useEffect, useRef } from 'react';

const ProductDetailCarousel = ({images}) => {
    const main = useRef();
    const thumb = useRef();
    const mainOptions = {
        type       : 'fade',
        pagination : false,
        autoHeight: true,
        arrows     : false,
        cover      : true,
    }
    const productSlidesSettings = {
        rewind          : true,
        fixedWidth      : 104,
        fixedHeight     : 58,
        isNavigation    : true,
        gap             : 10,
        pagination      : false,
        focus           : 'center',
        cover           : true,
        dragMinThreshold: {
            mouse: 4,
            touch: 10,
        },
        breakpoints : {
            640: {
            fixedWidth  : 66,
            fixedHeight : 38,
            },
        },
    };

    useEffect(() => {
        main.current.sync(thumb.current.splide);
    }, [main, thumb])
    
    return (
        <div className='px-3 border col-md-4'>
            <div className='large-splide'>
                <Splide options={mainOptions} ref={(slider) => (main.current = slider)}>
                    {images.map((value)=>{
                        return <SplideSlide><img className='d-inline mw-100' src={value.image_url_original} alt="Product"/></SplideSlide>
                    })}
                </Splide>
            </div>
            <Splide options={productSlidesSettings} ref={(slider) => (thumb.current = slider)}>
                {images.map((value)=>{
                    return <SplideSlide><img src={value.image_url_small} alt="Product"/></SplideSlide>
                })}
            </Splide>
        </div>
    )
}

export default ProductDetailCarousel