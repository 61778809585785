import { CheckmarkCircleOutline } from "react-ionicons";
import React from "react";

const PaymentSuccessPage = () => {
    const readCookie = (name) => {
        const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith(name));

        const cookieContent = cookieValue ? cookieValue.split("=")[1] : null;

        return cookieContent ? decodeURIComponent(cookieContent) : null;
    };

    let order_id, gateway
    const token = readCookie("token");
    const queryString = window.location.search;
    if (queryString.includes("order_id")) {
        const queryParams = new URLSearchParams(window.location.search);
        order_id = queryParams.get("order_id");
        let params = window.location.pathname.split("/")[2];
        if (params == 1) {
            gateway = "ONLINE_CASHFREE";
        } else if (params == 2) {
            gateway = "ONLINE_PHONEPE";
        } else if (params == 3) {
            gateway = "ONLINE_RAZORPAY";
        }
    }

    return (
        <div>
            {order_id && token && gateway ? (
                <div className="text-center pt-5">
                    <CheckmarkCircleOutline
                        color="#008000"
                        width="120px"
                        height="120px"
                    />
                    <p>Payment is successful. Order Placed Successfully!</p>
                </div>
            ) : (
                "Invalid URL"
            )}
        </div>
    );
};

export default PaymentSuccessPage;
