import React from 'react'

const Loader = ({ isOpen }) => {
    return (
        <div className={`global-loader-overlay ${isOpen ? 'show-loader' : 'hide-loader'}`}>
            <div className="global-loader-content">
                <div className="spinner-border" role="status"></div>
                <div className="ms-2">Loading...</div>
            </div>
        </div>
    )
}

export default Loader