import React from 'react';
import { Link } from 'react-router-dom';

const ProfileSideBar = () => {
    return (
        <div className='d-none d-sm-block mb-5'>
            <ul className="list-group text-center text-dark" >
                <li className='list-group-item py-3' style={{ cursor: 'pointer'}}>
                    <Link className="d-block text-decoration-none fs-5 text-dark"  to='/profile'>
                        Profile
                    </Link>                 
              </li>
                <li className='d-block list-group-item py-3' style={{ cursor: 'pointer'}}>
                    <Link className="text-decoration-none fs-5 text-dark"  to='/pastorders'>
                        Past Orders
                    </Link>
              </li>
                <li className='d-block list-group-item py-3' style={{ cursor: 'pointer'}}>
                    <Link className="text-decoration-none fs-5 text-dark"  to='/address'>
                        Delivery Addresses
                    </Link>
              </li>
                <li className='d-block list-group-item py-3' style={{ cursor: 'pointer'}}>
                    <Link className="text-decoration-none fs-5 text-dark"  to='/wishlist'>
                        Wishlist
                    </Link>
                </li>
                <li className='d-block list-group-item py-3 mb-5' onClick={() => {
                    localStorage.removeItem('auth');
                    this.props.removeAllFromCart();
                    window.location.reload(false);
                }} style={{ cursor: 'pointer'}}>
                    <Link className="text-decoration-none fs-5 text-dark"  to='/'>Logout</Link>
                </li>
            </ul>
        </div>
    )
}

export default ProfileSideBar