import React from 'react'
import { CloseOutline } from 'react-ionicons';
import { Link } from "react-router-dom";

const MobileNav = ({
    headerMenu,
    isMobileMenuOpen,
    toggleMobileMenu,
    setMobileMenuOpen,
    alignmentClasses
}) => {
    if (headerMenu && headerMenu.links && headerMenu.links.length > 0) {
        if (isMobileMenuOpen) {
            return (
                <div className="d-block d-md-none">
                    <div
                        className="navigation-backdrop"
                        onClick={toggleMobileMenu}
                    />
                    <aside className="p-4 pt-5">
                        <div className="close_icon">
                            <CloseOutline
                                width="35px"
                                height="35px"
                                onClick={() => setMobileMenuOpen(false)}
                            />
                        </div>
                        <ul className="navbar-nav ml-auto">
                            {headerMenu.links.map((menu) => (
                                <li className="nav-item" key={menu.link}>
                                    <a
                                        href={`${menu.link}`}
                                        rel="noopener noreferrer" target="_blank"
                                        className="nav-link"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        {menu.text}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </aside>
                </div>
            );
        } else {
            const alignmentClass =
                headerMenu && headerMenu.position
                    ? alignmentClasses[headerMenu.position]
                    : "justify-content-center";
            return (
                <div className="d-none d-md-block nav-menu w-100 shadow">
                    <ul
                        className={`navbar-nav d-flex ${alignmentClass} flex-row`}
                    >
                        {headerMenu.links.map((menu) => (
                            <li className="nav-item" key={menu.link}>
                                <a
                                    href={`${menu.link}`}
                                    rel="noopener noreferrer" target="_blank"
                                    className="text-light text-decoration-none"
                                >
                                    {menu.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
    }
    return null;
};

export default MobileNav