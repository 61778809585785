import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import useAPI from '../utils/api';
import { handleColorData } from '../utils/colors';
import { useCart, useLoader, useStore } from '../utils/contexts';
import { getAuthToken, getCartProducts, getUserId } from '../utils/storageService';
import useFetch from '../utils/useFetch';
import Loader from './common/Loader';
import BannerSlides from './home-components/BannerSlides';
import CategorySlides from './home-components/CategorySlides';
import QuickOrder from './home-components/QuickOrder';
import RecentProductSlides from './home-components/RecentProductSlides';
import SubcategoryList from './home-components/SubcategoryList';
import Poster from './home-components/Poster';
import StickyProductSlides from './home-components/StickyProductSlides';
import StickyCategorySlides from './home-components/StickyCategorySlides';
import BrandSlides from "./home-components/BrandSlides";


const defaultData = {
  header: {
    display: 1,
    display_order: 1,
  },
  quick_orders: {
    display: 0,
    display_order: 2,
    component_heading: "Quick Order",
  },
  category_sliders: {
    display: 1,
    display_order: 3,
    component_heading: "Shop by Categories",
  },
  banner_sliders: {
    display: 1,
    display_order: 4,
    component_heading: "Banner",
  },
  product_sliders: {
    display: 1,
    display_order: 5,
    component_heading: "Recent Products",
  },
  brand_sliders: {
    display: 1,
    display_order: 6,
    component_heading: "Shop by Brands",
  },
  category_subcategory_lists: {
    display: 1,
    display_order: 7,
    component_heading: "Category List",
  },
  footer: {
    display: 1,
    display_order: 8,
  },
};


const Home = () => {
    const storeData = useStore();
    const API = useAPI();
    const loader = useLoader();
    const cartData = useCart();

    const [componentSettings, setComponentSettings] = useState();
    useEffect(() => {
        const handler = (event) => {
            if (typeof event.data !== "object") return;
            if (!event.data.type) return;
            if (event.data.type !== "click") return;
            if (!event.data.home) return;

            setComponentSettings(event.data.home);
        };
        window.addEventListener("message", handler);

        // remove addEventListener
        return () => window.removeEventListener("message", handler);
    }, []);

    useEffect(() => {
        if (getAuthToken()) {
            
                
                (async () => {
                    const currentCart = getCartProducts();
                    if (currentCart.data.products.length > 0) {
                        const cartBody = currentCart.data.products.map(product => ({
                        id: product.id,
                        variant_index: product.variant_index,
                        qty: product.qty,
                    }));
                    loader.setLoaderOpen(true);
                    const [ok, response] = await API.addProductInCart(cartBody);
                    if (ok && response) {
                        cartData.setMyCartData(response.data);
                        localStorage.removeItem("cart");
                    } else {
                        if (response && response.error) {
                            alert(response.error);
                        }
                    }
                    loader.setLoaderOpen(false);
                }
            
            })();
        
            
                
                (async () => {
                    
                    loader.setLoaderOpen(true);
                    const [ok, response] = await API.getCart();
                    if (ok && response) {
                        cartData.setMyCartData(response.data);
                        localStorage.removeItem("cart");
                    } else {
                        if (response && response.error) {
                            alert(response.error);
                        }
                    }
                    loader.setLoaderOpen(false);
                }
            )();
        
    }
    }, [getAuthToken()])
    

    let hostname = '';
    if(window.location.hostname.includes("localhost")) {
        hostname = "jgxvhdhug.intelikart.com";
        
            
       
    } else {
        if (window.location.protocol !== 'https:') {
        window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }
        hostname = window.location.hostname;
    }
    const themeData = useFetch("theme", hostname);
    

    const objectToArray = (object)=> {
        let arr = [];
        for (const property in object) {
            if(object[property]){
                arr[object[property].display_order - 1] = property;
            }
        }
        return arr;
    }

    // *********************google Analytics impliment ***************************
    useEffect(
        () => {
                        if (
                storeData && storeData.data &&
                storeData.data.addons
            ) {
                // google analytics
                if (
                    storeData.data.addons.google_analytics &&
                    storeData.data.addons.google_analytics.status ==
                        "active"
                ) {
                    let {
                        google_measurement_id,
                    } = storeData.data.addons.google_analytics;
                    ReactGA.initialize(google_measurement_id);
                    ReactGA.pageview(
                        window.location.pathname + window.location.search
                    );
                }

                // optimonk
                if (
                    storeData.data.addons.optimonk &&
                    storeData.data.addons.optimonk.status == "active"
                ) {
                    let { account_id } = storeData.data.addons.optimonk;
                    const script = document.createElement("script");
                    script.src = `https://onsite.optimonk.com/script.js?account=${account_id}`;
                    script.async = true;
                    document.head.appendChild(script);
                }

                // Tawk.to Script
                if (
                    storeData.data.addons.tawk &&
                    storeData.data.addons.tawk.status == "active"
                ) {
                    let { property_id, widget_id } = storeData.data.addons.tawk;
                    const s1=document.createElement("script");
                    s1.src=`https://embed.tawk.to/${property_id}/${widget_id}`;
                    s1.async=true;
                    s1.setAttribute('crossorigin','*');
                    document.head.appendChild(s1);
                }
            }
        },
        [storeData]
    );
// *********************!cartData.loading && cartData.status === "fetched" &&**************************************************************
    return (<>
            {componentSettings && componentSettings.header && handleColorData(componentSettings.global_settings.branding_colors) ? 
                objectToArray(componentSettings).map((value)=> {
                    switch (value) {
                      case "quick_orders":
                        return (
                          componentSettings.quick_orders.display === 1 && (
                            <QuickOrder
                              customizedData={componentSettings.quick_orders}
                            />
                          )
                        );
                      case "category_sliders":
                        return (
                          (componentSettings.category_sliders.display === 1 ||
                            (storeData.data.addons &&
                              storeData.data.addons.festival &&
                              (storeData.data.addons.festival.status ===
                                "active" ||
                                storeData.data.addons.festival
                                  .christmas_theme === "active"))) &&
                          storeData.data.categories && (
                            <CategorySlides
                              addon={storeData.data.addons.festival}
                              customizedData={
                                componentSettings.category_sliders
                              }
                              categories={storeData.data.categories}
                              color={storeData.data.store}
                              primary_color={
                                componentSettings.global_settings
                                  .branding_colors &&
                                componentSettings.global_settings
                                  .branding_colors.primary_color
                              }
                            />
                          )
                        );

                      case "banner_sliders":
                        return (
                          componentSettings.banner_sliders.display === 1 && (
                            <BannerSlides
                              banners={componentSettings.banner_sliders.slides}
                            />
                          )
                        );
                      case "poster":
                        return (
                          componentSettings.poster.display === 1 && (
                            <Poster
                              poster={componentSettings.poster.poster_info}
                            />
                          )
                        );
                      case "product_sliders":
                        return (
                          componentSettings.product_sliders.display === 1 &&
                          storeData.data.products && (
                            <RecentProductSlides
                              customizedData={componentSettings.product_sliders}
                              color={storeData.data.store}
                              primary_color={
                                componentSettings.global_settings
                                  .branding_colors &&
                                componentSettings.global_settings
                                  .branding_colors.primary_color
                              }
                              secondary_color={
                                componentSettings.global_settings
                                  .branding_colors &&
                                componentSettings.global_settings
                                  .branding_colors.secondary_color
                              }
                            />
                          )
                        );

                      case "brand_sliders":
                        return (
                          componentSettings.brand_sliders.display === 1 &&
                          storeData.data.products && (
                            <BrandSlides
                              customizedData={componentSettings.brand_sliders}
                              color={storeData.data.store}
                              primary_color={
                                componentSettings.global_settings
                                  .branding_colors &&
                                componentSettings.global_settings
                                  .branding_colors.primary_color
                              }
                              secondary_color={
                                componentSettings.global_settings
                                  .branding_colors &&
                                componentSettings.global_settings
                                  .branding_colors.secondary_color
                              }
                            />
                          )
                        );

                      case "category_subcategory_lists":
                        return (
                          componentSettings.category_subcategory_lists
                            .display === 1 &&
                          storeData.data.categories && (
                            <SubcategoryList
                              categories={storeData.data.categories}
                              color={storeData.data.store}
                              primary_color={
                                componentSettings.global_settings
                                  .branding_colors &&
                                componentSettings.global_settings
                                  .branding_colors.primary_color
                              }
                              secondary_color={
                                componentSettings.global_settings
                                  .branding_colors &&
                                componentSettings.global_settings
                                  .branding_colors.secondary_color
                              }
                            />
                          )
                        );
                      default:
                        return null;
                    }
                }) : <>
                    <Loader isOpen={themeData.loading}/>
                    {
                        !themeData.loading && themeData.status === 'fetched' && themeData.data.data.header ? objectToArray(themeData.data.data).map((value)=>{
                            switch (value) {
                              case "quick_orders":
                                return (
                                  themeData.data.data.quick_orders.display ===
                                    1 &&
                                  localStorage.getItem("auth") && (
                                    <QuickOrder
                                      customizedData={
                                        themeData.data.data.quick_orders
                                      }
                                    />
                                  )
                                );
                              case "category_sliders":
                                return (
                                  (themeData.data.data.category_sliders
                                    .display === 1 ||
                                    (storeData.data.addons &&
                                      storeData.data.addons.festival &&
                                      (storeData.data.addons.festival.status ===
                                        "active" ||
                                        storeData.data.addons.festival
                                          .christmas_theme === "active"))) &&
                                  storeData.data.categories && (
                                    <CategorySlides
                                      addon={storeData.data.addons.festival}
                                      customizedData={
                                        themeData.data.data.category_sliders
                                      }
                                      categories={storeData.data.categories}
                                      color={storeData.data.store}
                                    />
                                  )
                                );
                              case "banner_sliders":
                                return (
                                  themeData.data.data.banner_sliders.display ===
                                    1 && (
                                    <BannerSlides
                                      banners={storeData.data.banners}
                                    />
                                  )
                                );
                              case "poster":
                                return (
                                  themeData.data.data.poster.display === 1 && (
                                    <Poster
                                      poster={
                                        themeData.data.data.poster.poster_info
                                      }
                                    />
                                  )
                                );
                              case "product_sliders":
                                return (
                                  themeData.data.data.product_sliders
                                    .display === 1 &&
                                  storeData.data.products && (
                                    <RecentProductSlides
                                      customizedData={
                                        themeData.data.data.product_sliders
                                      }
                                      color={storeData.data.store}
                                    />
                                  )
                                );

                              case "brand_sliders":
                                return (
                                  themeData.data.data.brand_sliders.display ===
                                    1 &&
                                  storeData.data.products && (
                                    <BrandSlides
                                      customizedData={
                                        themeData.data.data.brand_sliders
                                      }
                                      color={storeData.data.store}
                                    />
                                  )
                                );

                              case "category_subcategory_lists":
                                return (
                                  themeData.data.data.category_subcategory_lists
                                    .display === 1 &&
                                  storeData.data.categories && (
                                    <SubcategoryList
                                      categories={storeData.data.categories}
                                      color={storeData.data.store}
                                    />
                                  )
                                );
                              case "sticky_product":
                                return (
                                  themeData.data.data.sticky_product.display ===
                                    1 && (
                                    <StickyProductSlides
                                      sticky_product={
                                        themeData.data.data.sticky_product
                                      }
                                      customizedData={
                                        themeData.data.data.sticky_product
                                          .sticky_product_info
                                      }
                                      color={storeData.data.store}
                                    />
                                  )
                                );
                              case "sticky_category_sliders":
                                return (
                                  themeData.data.data.sticky_category_sliders
                                    .display === 1 && (
                                    <StickyCategorySlides
                                      sticky_category_sliders={
                                        themeData.data.data
                                          .sticky_category_sliders
                                      }
                                      customizedData={
                                        themeData.data.data
                                          .sticky_category_sliders.slides
                                      }
                                      color={storeData.data.store}
                                    />
                                  )
                                );
                              default:
                                return null;
                            }
                        }) : objectToArray(defaultData).map((value)=>{
                            switch (value) {
                              case "quick_orders":
                                return (
                                  defaultData.quick_orders.display === 1 &&
                                  localStorage.getItem("auth") && (
                                    <QuickOrder
                                      customizedData={defaultData.quick_orders}
                                    />
                                  )
                                );
                              case "category_sliders":
                                return (
                                  defaultData.category_sliders.display === 1 &&
                                  storeData.data.categories && (
                                    <CategorySlides
                                      addon={storeData.data.addons.festival}
                                      customizedData={
                                        defaultData.category_sliders
                                      }
                                      categories={storeData.data.categories}
                                      color={storeData.data.store}
                                    />
                                  )
                                );
                              case "banner_sliders":
                                return (
                                  defaultData.banner_sliders.display === 1 && (
                                    <BannerSlides
                                      banners={storeData.data.banners}
                                    />
                                  )
                                );
                              case "product_sliders":
                                return (
                                  defaultData.product_sliders.display === 1 &&
                                  storeData.data.products && (
                                    <RecentProductSlides
                                      customizedData={
                                        defaultData.product_sliders
                                      }
                                      color={storeData.data.store}
                                    />
                                  )
                                );

                              case "brand_sliders":
                                return (
                                  defaultData.brand_sliders.display === 1 &&
                                  storeData.data.products && (
                                    <BrandSlides
                                      customizedData={
                                        defaultData.brand_sliders
                                      }
                                      color={storeData.data.store}
                                    />
                                  )
                                );

                              case "category_subcategory_lists":
                                return (
                                  defaultData.category_subcategory_lists
                                    .display === 1 &&
                                  storeData.data.categories && (
                                    <SubcategoryList
                                      categories={storeData.data.categories}
                                      color={storeData.data.store}
                                    />
                                  )
                                );
                              default:
                                return null;
                            }
                        })
                    }
                        </>
            }
    </>)
}

export default Home;

// export default Home