// import {UserContext} from "./contexts";
// import {auth} from "./auth";

import { deleteCartItem, getAuthToken, getCartProducts, getDomain, updateCartItem } from "./storageService";

// const { REACT_APP_API_HOST } = process.env;
export const REACT_APP_API_HOST = "https://api2.intelikart.in/";

const pathForAuths = ['cart', 'cart/product', 'orders/v2', 'cart/coupon', 'wishlists', 'product-ratings', 'delivery-addresses', 'users', 'pay']

async function GET(path, userDomain) {
  const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-ik-domain':`${userDomain}`
    }
  });

  try {
    if (response.status === 401) {
      return [false, {status: 401, message: 'Unauthorized'}];
    }

    if (response.status === 204) {
      return [response.ok, null];
    }

    const json = await response.json();
    return [response.ok, json];
  } catch (e) {
    return [response.ok, {status: response.status}];
  }
}

async function GETWITHAUTH(path) {
  const auth = getAuthToken();
  const domain = getDomain();

  if(auth && domain){
    const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        "x-ik-domain": domain,
        Authorization: `Bearer ${auth}`
      }
    });

    try {
      if (response.status === 401) {
        return [false, {status: 401, message: 'Unauthorized'}];
      }
      if (response.status === 204) {
        return [response.ok, null];
      }
      const json = await response.json();
      return [response.ok, json];
    } catch (e) {
      return [response.ok, {status: response.status}];
    }
  } else {
    if(path === "cart"){
      const cartProducts = getCartProducts();
      return [true, cartProducts];
    }
  }
}

async function GETWITHDomain(path) {
  const domain = getDomain();
  if(domain){

    const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        "x-ik-domain": domain,
      }
    });
    if(response){
      try {
        if (response.status === 401) {
          return [false, {status: 401, message: 'Unauthorized'}];
        }
        if (response.status === 204) {
          return [response.ok, null];
        }
        const json = await response.json();
        return [response.ok, json];
      } catch (e) {
        return [response.ok, {status: response.status}];
      }
    }else {
      return [false, {message: "Some error occurred!"}]
    }
    
  } else {
    if(path === "cart"){
      const cartProducts = getCartProducts();
      return [true, cartProducts];
    }
  }
}

// async function DOWNLOAD(path, token, filename) {
//   const response = await fetch(`${API_ROOT}${path}`, {
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `${token}`
//     }
//   });

//   if (response.status === 401) {
//     return [false, {message: 'Unauthorized'}];
//   }

//   const blob = await response.blob();
//   const file = window.URL.createObjectURL(blob);
//   const link = document.createElement('a');
//   link.href = file;
//   link.download = filename;
//   link.click();
// }

async function DELETEWITHAUTH(path, data={}) {
  const auth = getAuthToken();
  const domain = getDomain();
  if(auth && domain) {

    let fetchData = {
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth}`,
        "x-ik-domain": domain,
      }
    }
    let modifiedData = data;
    const body = JSON.stringify(modifiedData);
    try {
      const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
        method: 'DELETE',
        ...fetchData,
        body: body
      });
  
      if (response.status === 401) {
        return [false, {message: 'Unauthorized'}];
      }
  
      if (response.status === 404) {
        return [false, {message: 'Not Found'}];
      }
  
      if (response.status === 204) {
        return [response.ok, null];
      }
  
      const json = await response.json();
      return [response.ok, json];
    } catch (e) {
      return [false, { message: e.message }]
    }
  }
}

async function UPDATE(method, path, data={}) {
  const auth = getAuthToken();
  const domain = getDomain();
  let headers;
  const isFormData = data instanceof FormData;

  if(!isFormData){
    headers = {'Content-Type': 'application/json'};
  }

  if(pathForAuths.indexOf(path) !== -1 && auth && domain) {
    headers = { 
        ...headers,
        'Authorization': `Bearer ${auth}`,
        "x-ik-domain": domain,
      }

    let modifiedData = data;
    if(path === 'cart'){
      if(Array.isArray(data)){
        modifiedData = {
          products: data,
        }
      } else{
        modifiedData = {
          products: [
              {
                  id: data.product.id,
                  variant_index: data.variant_index,
                  qty: data.qty,
              },
          ],
        }
      }
    }
    const body = isFormData ? data : JSON.stringify(modifiedData);
    try {
      const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
        method: method,
        mode: 'cors',
        headers,
        body: body
      });
  
      if (response.status === 401) {
        return [false, {message: 'Unauthorized'}];
      }
  
      if (response.status === 404) {
        return [false, {message: 'Not Found'}];
      }
  
      if (response.status === 204 || path === 'users') {
        return [response.ok, null];
      }

      const json = await response.json();
      return [response.ok, json];
    } catch (e) {
      return [false, { message: e.message }]
    }
  } else if(pathForAuths.indexOf(path) === -1){

    const body = isFormData ? data : JSON.stringify(data);
    try {
      const response = await fetch(`${REACT_APP_API_HOST}${path}`, {
        method: method,
        mode: 'cors',
        headers,
        body: body
      });
  
      if (response.status === 401) {
        return [false, {message: 'Unauthorized'}];
      }
  
      if (response.status === 404) {
        return [false, {message: 'Not Found'}];
      }
  
      if (response.status === 204) {
        return [response.ok, null];
      }
  
      const json = await response.json();
      return [response.ok, json];
    } catch (e) {
      return [false, { message: e.message }]
    }
  } else {
    if(path === 'cart' && (method === "POST" || method === "PUT") && !(auth && domain)){
      const savedCartData = updateCartItem({...data.product, productId: data.product.id, variant_index: data.variant_index}, data.qty);
      return [true, {data: {products: savedCartData}}];
    }
    if(path === 'cart/product' && (method === "DELETE") && !(auth && domain)){
      const savedCartData = deleteCartItem(data.product_id, data.variant_index);
      return [true, {data: {products: savedCartData}}];
    }
  }
}

async function POST(path, data) {
  return UPDATE('POST', path, data);
}

async function PUT(path, data) {
  return UPDATE('PUT', path, data);
}

async function DELETE(path, data) {
  return UPDATE('DELETE', path, data);
}

const useAPI = () => {
  // const token = auth.getToken();
  // const user = useContext(UserContext);
  // const storeId = user?.store_id;
  // const userDomain = user?.store?.subdomain;

  return {
    // storeId,
    // pages: () => GET(`pages`, token, userDomain),
    // createPage: (pageData) => POST(`pages`, token, userDomain, pageData),
    // updatePage: (pageId, pageData) => PUT(`pages/${pageId}`, token, userDomain, pageData),
    // deletePage: (pageId) => DELETE(`pages/${pageId}`, userDomain, token),
    // slugAvailable: (url) => GET(`pages/slug-availability/${url}`, token, userDomain),
    // Coupons
    // coupons: () => GET(`api/coupons?storeId=${storeId}`, token, userDomain),
    // createCoupon: (couponData) => POST(`api/coupons`, token, userDomain, couponData),
    // deleteCoupon: (couponData) => DELETEWITHBODY(`api/coupons`, token, userDomain, couponData),
    storeData: (hostname) => GET('store/home', hostname),
    theme: (hostname) => GET(`theme`, hostname),
    getDateTime: () => GETWITHAUTH(`config-time-slot`),
    getCart: (lat) => GETWITHAUTH(`cart`, lat),
    addProductInCart: (data) => POST(`cart`, data),
    deleteProductInCart: (data) => DELETE(`cart/product`, data),
    deleteCart: () => DELETEWITHAUTH(`cart`),
    searchProducts: (query) => {return GETWITHDomain(`search/products?page=1&per_page=10&query=${query}`)},
    productDetails: (id) => GETWITHDomain(`search/products?productId=${id}`),
    relatedProducts: (prdId, catId) => GETWITHDomain(`products/related/${prdId}/${catId}`),
    coupons: () => GETWITHAUTH(`stores/coupons`),
    deliveryAddress: () => GETWITHAUTH(`delivery-addresses`),
    paymentMethods: () => GETWITHAUTH(`store-settings/payment-methods`),
    couponsValid: (code) => GETWITHAUTH(`stores/coupons-validity?coupon=${code}`),
    produtMetaDataWithAuth: (prdId, variant_index) => getAuthToken() ? GETWITHAUTH(`product-meta?product_id=${prdId}&variant_index=${variant_index}`) : GETWITHDomain(`product-meta?product_id=${prdId}&variant_index=${variant_index}`),
    produtRatings: (prdId) => GETWITHDomain(`product-ratings?product_id=${prdId}`),
    searchProductsByCate: (cateId, page) => GETWITHDomain(`search/products?page=${page}&per_page=12&categoryId=${cateId}`),
    searchProductsByBrand: (brandId, page) => GETWITHDomain(`search/products?page=${page}&per_page=12&brandId=${brandId}`),
    onlinePayData: (data) => POST(`pay`, data),
    paymentConfirmation: (order_id, gateway) => GETWITHAUTH(`pay/confirm?order_id=${order_id}&gateway=${gateway}`),


    addReview: (data) => POST(`product-ratings`, data),
    updateReview: (data) => PUT(`product-ratings`, data),
    updateCoupon: (data) => PUT(`cart/coupon`, data),
    deleteCoupon: () => DELETEWITHAUTH(`cart/coupon`),
    deleteReview: (prdId) => DELETEWITHAUTH(`product-ratings/${prdId}`),
    addWishlist: (data) => POST(`wishlists`, data),
    deleteWishlist: (data) => DELETE(`wishlists`, data),

    currentUser: () => GETWITHAUTH(`users/current`),
    orderList: () => GETWITHAUTH(`orders/recent`),
    orderDetails: (ordId) => GETWITHAUTH(`orders/${ordId}`),

    cancelOrder: (cancelOrderId) => GETWITHAUTH(`orders/change/${cancelOrderId}/CANCEL`),
    wishlist: () => GETWITHAUTH(`wishlists`),
    addDeliveryAddress: (data) => POST(`delivery-addresses`, data),
    editDeliveryAddress: (data) => PUT(`delivery-addresses`, data),
    editProfile: (data) => PUT(`users`, data),
    deleteAddress: (addressId) => DELETEWITHAUTH(`delivery-addresses/${addressId}`),

    pageData: (pageId) => GETWITHDomain(`pages/${pageId}`),
    placeOrder: (data) => POST(`orders/v2`, data),

    // Banners
    // banners: () => GET(`stores/banners`, token, userDomain),
    // createBanner: (bannerData) => POST(`stores/banners`, token, userDomain, bannerData),
    // deleteBanner: (bannerId) => DELETE(`stores/banners/${bannerId}`, token, userDomain),

    // Staffs
    // staffs: () => GET(`api/stores-staff?storeId=${storeId}`, token, userDomain),
    // createStaff: (staffData) => POST(`api/stores-staff?storeId=${storeId}`, token, userDomain, staffData),
    // deleteStaff: (staffId) => DELETE(`api/stores-staff/${staffId}?storeId=${storeId}`, token, userDomain),

    // Printables
    // printables: () => GET(`api/store-marketing/${storeId}/printables`, token, userDomain),

    // Announcements
    // announcements: () => GET(`api/announcements-suggestions?storeId=${storeId}`, token, userDomain),
    // createAnnouncement: (announcementData) => POST(`api/announcements`, token, userDomain, announcementData),

    // Orders -> Adjustments
    // adjustments: (orderId) => GET(`api/orders/${orderId}/adjustments`, token, userDomain),
    // createAdjustment: (orderId, adjustmentData) => POST(`api/orders/${orderId}/adjustments`, token, userDomain, adjustmentData),
    // updateAdjustment: (orderId, adjustmentId, adjustmentData) => PUT(`api/orders/${orderId}/adjustments/${adjustmentId}`, token, userDomain, adjustmentData),
    // deleteAdjustment: (orderId, adjustmentId) => DELETE(`api/orders/${orderId}/adjustments/${adjustmentId}`, token, userDomain),


    // Setting page
    // basicInfo: () => GET(`api/store-settings/${storeId}/basic-about`, token, userDomain),
    // createBasicInfo: (basicInfoData) => POST(`api/store-settings/${storeId}/basic-about`, token, userDomain, basicInfoData),
    // serviceDetail: () => GET(`api/store-settings/${storeId}/service-details`, token, userDomain),
    // createServiceDetail: (serviceDetailData) => POST(`api/store-settings/${storeId}/service-details`, token, userDomain, serviceDetailData),
    // quickOrder: () => GET(`api/store-settings/${storeId}/quick-orders`, token, userDomain),
    // createQuickOrder: (quickOrderData) => POST(`api/store-settings/${storeId}/quick-orders`, token, userDomain, quickOrderData),
    // payment: () => GET(`api/store-settings/${storeId}/payments`, token, userDomain),
    // createPayment: (paymentData) => POST(`api/store-settings/${storeId}/payments`, token, userDomain, paymentData),
    // localization: () => GET(`api/store-settings/${storeId}/basic-localisation`, token, userDomain),
    // createLocalization: (localizationData) => POST(`api/store-settings/${storeId}/basic-localisation`, token, userDomain, localizationData),
    // tax: (lat, long) => GET(`api/taxes-charges?storeId=${storeId}&lat=${lat}&long=${long}`, token, userDomain),
    // createTax: (taxData) => POST(`api/taxes-charges`, token, userDomain, taxData),
    // deleteTax: (taxId) => DELETE(`api/taxes-charges/${taxId}?storeId=${storeId}`, token, userDomain),
    // theme: () => GET(`api/store-settings/${storeId}/themes
    // api/store-settings/${storeId}/themes`, token, userDomain),
    // createTheme: (themeData) => POST(`api/store-settings/${storeId}/themes
    // api/store-settings/${storeId}/themes`, token, userDomain, themeData),
    // branding: () => GET(`api/store-settings/${storeId}/branding`, token, userDomain),
    // createBranding: (brandingData) => POST(`api/store-settings/${storeId}/branding`, token, userDomain, brandingData),


    //Catalouge
    // categories: () => GET(`store-categories?parent_id=0`, token, userDomain),
    // createCategory: (categoryData) => POST(`store-categories`, token, userDomain, categoryData),
    // updateCategory: (categoryData) => PUT(`store-categories?parent_id=0`, token, userDomain, categoryData),
    // deleteCategory: (categoryId) => DELETE(`store-categories/${categoryId}`, token, userDomain),
    // categoryTree: (categoryData) => PUT(`store-categories-tree`, token, userDomain, categoryData),


    // userToken: (loginData) => LOGIN_POST(`oauth/access-token`, loginData),
    // getOtp: (email) => LOGIN_POST(`oauth/otp`, email),
    // currentUser: () => GET(`users/current`, token, ''),
    // currentUserDirect: (updatedToken) => GET(`users/current`, updatedToken, ''),
    // storeOnboarding: (onboardingData) => POST(`store-onboarding`, token, userDomain, onboardingData),


    // tipBanners: () => GET(`stores/tips-banners`, token, ''),
    // homeScreen: () => GET(`seller-home-screen/action-data`, token, userDomain),
    // profileCompletion: () => GET(`store-setup-steps`, token, userDomain),
    // homeScreenDays: (days) => GET(`analytics/seller-home-screen/${days}`, token, userDomain),
    // academy: () => GET(`academy`, token, userDomain),
    uploadImage: (file) => {
      if(!file) return "";
      const form = new FormData();
      form.append("file", file);
      return POST(`upload/order-image`, form)
    },
  }
}

export default useAPI;