import { useEffect, useReducer } from 'react';
import useAPI from "./api";

const useFetch = (method, ...props) => {
  const initialState = {
    status: 'idle',
    error: null,
    data: [],
    loading: false
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, loading: true, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, loading: false, status: 'fetched', data: action.payload, [method]: action.payload };
      case 'FETCHED_NO_CONTENT':
        return { ...initialState, loading: false, status: 'no_content' };
      case 'FETCH_ERROR':
        return { ...initialState, loading: false, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);
  const API = useAPI();
  // const user:any = useContext(UserContext);

  useEffect(() => {
    if (!method) return;

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });
      const [ok, json] = await API[method](...props);
      if (ok) {
        if (json) {
          dispatch({ type: 'FETCHED', payload: json });
        } else {
          dispatch({ type: 'FETCHED_NO_CONTENT', payload: json });
        }
      } else {
        dispatch({ type: 'FETCH_ERROR', payload: json });
      }
    };

    fetchData();
  }, [method, ...props]);

  return state;
};

export default useFetch;