import React, { useRef } from 'react';
import useAPI from '../../utils/api';
import { setQuickOrder } from '../../utils/storageService';

const QuickOrder = ({customizedData}) => {
    const fileInputRef = useRef();
    const API = useAPI();

    const onQuickOrderFileUpdate = async (e) =>{
        const [ok, response] = await API.uploadImage(e.target.files[0]);
        if(ok && response && response.platform_path){
            setQuickOrder(response.platform_path);
            window.location.href = `${process.env.PUBLIC_URL}/cart`;
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
    }

    return (
        <div className='d-grid mx-2 mx-sm-5'>
            <input className='d-none' type='file' onChange={(e) => onQuickOrderFileUpdate(e)} ref={fileInputRef} />
            <button className='btn web-primary-color-button d-flex justify-content-center align-items-center' onClick={() => fileInputRef.current && fileInputRef.current.click()} style={{ fontSize: '24px' }}>
                <ion-icon name='camera-outline' style={{ fontSize: '32px', marginRight: '.7rem' }}/>
                {customizedData.component_heading}
            </button>
        </div>
    )
}

export default QuickOrder