import React from 'react';
import { Add, InformationCircle, Remove, TrashOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import useAPI from '../../../utils/api';
import { useCart, useLoader } from '../../../utils/contexts';
import { getCurrencySymbol } from '../../../utils/currency';

const CartCards = ({className}) => {
    const cartData = useCart();
    const API = useAPI();
    const loader = useLoader();

    const postCartData = async (item, variant_index, quantity) =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.addProductInCart(
            {
                product: item,
                variant_index: variant_index,
                qty: quantity,
            },
        );
        if(ok && response){
            cartData.setMyCartData(response.data);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    const deleteCartProduct = async (id, variant_index) =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.deleteProductInCart({
            product_id: id,
            variant_index: variant_index,
        });
        if(ok && response){
            cartData.setMyCartData(response.data);
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    return (
        <div className={`${className}`}>
            <div className="d-flex flex-wrap">
                {cartData.myCartData.products.map((product, index) => <div className={`card shadow rounded p-2 opp-tans mx-auto mx-sm-2 mb-2`}>
                <div className="d-flex flex-column h-100 justify-content-between">
                <div>
                    <Link className='card-link' to={`/product/${product.name}/${product.id}`}>
                    <div className='cart-card-image-container'>
                        <img src={product.variants && product.variants[0].image_url_original} alt='product' width={'100%'} height={'100%'} />
                        </div>
                        <div className="mt-2">
                            <p className="h5 text-wrap">{product.name}</p>
                            {product.variants[product.variant_index].attribute_value_mappings && product.variants[product.variant_index].attribute_value_mappings.map(attribute => <p className="mb-0">
                                {attribute.attribute_name} : {attribute.attribute_value}
                            </p> )}
                            <p className="h6">
                                {getCurrencySymbol()} {product.variants[product.variant_index].mrp}
                            </p>
                        </div>
                    </Link>
                    <p className='mb-1 h6'>Taxes:
                    {product.product_taxes != null && product.taxes ? <span className='ms-1'>{product.taxes.toFixed(2)}</span> : <span className='ms-1'>0</span> }
                    {product.product_taxes != null && (
                    <>
                        <div className='d-inline ms-1' data-bs-toggle="tooltip" data-bs-placement="bottom" title={`
                        ${product.product_taxes.map((taxItem) => 
                            `${taxItem.name}: ${taxItem.method==="FLAT" ? getCurrencySymbol() : "" } ${taxItem.value} ${taxItem.method==="PERCENT" ? "%":"" }`
                        )}
                        `}>
                        <InformationCircle />
                        </div>
                    </>
                    )}
                    </p>
                </div>
                <div>
            <div className='mt-2 cart-card-input-container'>
                            <div className='input-group'>
                            {(product.product_type=="PHYSICAL" || product.product_type==null) &&  <button type='button' disabled={(product.qty === 1 || (product.variants[product.variant_index].min_order_quantity != null && product.variants[product.variant_index].min_order_quantity != 0 && product.qty <= product.variants[product.variant_index].min_order_quantity)) ? true : false} className='btn border-dark' onClick={() => postCartData(product, product.variant_index, (product.qty - 1))}>
                                    <Remove/>
                                </button>
}
                                <input type='tel' name='quantity' value={product.qty} onChange={(e) => {
                                    let value;
                                    if ((product.variants[product.variant_index].available_quantity !== null) && (e.target.value > product.variants[product.variant_index].available_quantity)) {
                                        value = product.variants[product.variant_index].available_quantity
                                    } else if(product.variants[product.variant_index].min_order_quantity != null && product.variants[product.variant_index].min_order_quantity != 0 && e.target.value > product.variants[product.variant_index].min_order_quantity) {
                                        value = product.variants[product.variant_index].min_order_quantity
                                    } else if(product.variants[product.variant_index].max_order_quantity != null && product.variants[product.variant_index].max_order_quantity != 0 && e.target.value < product.variants[product.variant_index].max_order_quantity) {
                                        value = product.variants[product.variant_index].max_order_quantity
                                    } else {
                                        value = e.target.value;
                                    }
                                    postCartData(product, product.variant_index, Math.max(1, parseInt(value)))
                                    }} className='form-control text-center'
                                     disabled={product.product_type=='DIGITAL'}
                                    />
{ (product.product_type=="PHYSICAL" || product.product_type==null) &&  <button className='btn border-dark' onClick={() => postCartData(product, product.variant_index, (product.qty + 1))} disabled={((product.variants[product.variant_index].max_order_quantity != null && product.variants[product.variant_index].max_order_quantity != 0 && product.qty >= product.variants[product.variant_index].max_order_quantity) || (product.variants[product.variant_index].available_quantity !== null && product.qty >= product.variants[product.variant_index].available_quantity)) ? true : false}>
                                        <Add />
                                    </button> }
                                
                                </div>

                                <div>
                                    {product.variants[product.variant_index].min_order_quantity != null && product.variants[product.variant_index].max_order_quantity != null && product.variants[product.variant_index].min_order_quantity != 0 && product.variants[product.variant_index].max_order_quantity != 0 && <div>{`allowed ${product.variants[product.variant_index].min_order_quantity} to ${product.variants[product.variant_index].max_order_quantity}`}</div>}
                                  </div>
                                  <div>
                                    {(product.variants[product.variant_index].max_order_quantity == null || product.variants[product.variant_index].max_order_quantity == 0 ) && product.variants[product.variant_index].min_order_quantity != 0 && product.variants[product.variant_index].min_order_quantity != null  && <div>{`allowed min ${product.variants[product.variant_index].min_order_quantity} `}</div>}
                                  </div>
                                  <div>
                                    {(product.variants[product.variant_index].min_order_quantity === null || product.variants[product.variant_index].min_order_quantity == 0) && product.variants[product.variant_index].max_order_quantity != null   && product.variants[product.variant_index].max_order_quantity != 0  && <div>{`allowed max ${product.variants[product.variant_index].max_order_quantity} `}</div>}
                                  </div>
                              </div>

                <div className='d-flex justify-content-between align-items-center mt-2'>
                    <p className="h5 mb-0">{getCurrencySymbol()}{" "}
                        {product.totalcost ? product.totalcost.toFixed(2) : product.sum.toFixed(2)}
                    </p>
                    <button className='btn btn-danger' onClick={e=> {deleteCartProduct(product.id, product.variant_index)}}><TrashOutline color="#FFF"/></button>
                </div>
                </div>
                </div>
            </div>)}
            </div>
        </div>
    )
}

export default CartCards