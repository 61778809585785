import React, { useRef, useState } from 'react';
import { LocationOutline, TrashOutline } from 'react-ionicons';
import { useHistory } from 'react-router-dom';
import useAPI from '../../utils/api';
import { useLoader } from '../../utils/contexts';
import useFetch from '../../utils/useFetch';
import { alphaValidation, phoneValidation, zipCodeValidation } from '../../utils/validators';
import Breadcrumb from '../common/Breadcrumb';
import Loader from '../common/Loader';
import ProfileSideBar from './ProfileSideBar';

const DeliveryAddress = () => {
    const API = useAPI();
    const loader = useLoader();
    const history = useHistory();
    const ModalCloseButtonRef = useRef();
    const [searchQuery, setSearchQuery] = useState("");

    const [address, setAddress] = useState({
        address: "",
        city: "",
        country: "",
        latitude: null,
        longitude: null,
        name: "",
        phone: "",
        state: "",
        zip: ""
    });
    const [addressValidation, setAddressValidation] = useState({
        address: "",
        city: "",
        country: "",
        name: "",
        phone: "",
        state: "",
        zip: "",
        sameaddress:""
    });
    const [update, setUpdate] = useState(false);
    const {data, status, loading} = useFetch("deliveryAddress", update);

    const checkValidation = () => {
        let isValid = true;
        let temp = {...addressValidation};

        if(!address.address){
            temp.address = "Address is required."
            isValid = false;
        }else{
            temp.address = ""
        }
        if(!address.city || !alphaValidation(address.city)){
            temp.city = "City is required and must have only alphabets"
            isValid = false;
        }else{
            temp.city = ""
        }
        if(!address.country || !alphaValidation(address.country)){
            temp.country = "Country is required and must have only alphabets."
            isValid = false;
        }else{
            temp.country = ""
        }
        if(!address.state || !alphaValidation(address.state)){
            temp.state = "State is required and must have only alphabets."
            isValid = false;
        }else{
            temp.state = ""
        }
       
        if(!address.name || !alphaValidation(address.name)){
            temp.name = "Name is required and must have only alphabets."
            isValid = false;
        }else{
            temp.name = ""
        }
        if(!address.phone || address.phone.length !== 10 || !phoneValidation(address.phone)){
            temp.phone = "Phone number is required and must have 10 numeric digits."
            isValid = false;
        }else{
            temp.phone = ""
        }

        setAddressValidation(temp);

        return isValid;
    }

    const updateAddress=async(location)=>{
        let isValid = checkValidation();
        if(isValid){
        try{
      const[ok,response]= await API.editDeliveryAddress({
            ...address,
            latitude: location.lat,
            longitude: location.lng
        })

        if (ok && response) {
            return [ok,response]
          
        } else {
           
            if (response && response.error) {
                alert(response.error);
            }
        }
    } catch (error) {
        console.error("Async/Await Error:", error);
    } finally {
        setSearchQuery("");
    }
}
    }

    const handleSave = async () => {
        loader.setLoaderOpen(true);
        let isValid = checkValidation();
        if(isValid){
            const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${address.address} ${address.city} ${address.country}`)}&key=AIzaSyDbix6IhpOdD9FO2DlmCFPCp3OrGgtbOvE`, {
                method: "GET"
            });
            const locationdata = await res.json();
            let location = {
                lat: null,
                lng: null
            }
            if (res.ok && locationdata.status === "OK" && locationdata.results.length > 0) {
                location = locationdata.results[0].geometry.location;
            }
            if(location.lat && location.lng) {
                const [ok, response] = address.id ?   
                await updateAddress(location):await checkDuplicateAddress(location);
                if (ok && response) {
                    setUpdate(!update);
                    ModalCloseButtonRef.current.click();
                } else {
                    if (response && response.error) {
                        alert(response.error);
                    }
                }
            }
            
         else{
                alert("Address is not valid")
            }
        }
        loader.setLoaderOpen(false);
        setSearchQuery("");
    }

    const checkDuplicateAddress = async (location) => {
        const duplicateAddress = data.data.some(fetchaddress => {
            return (
                fetchaddress.address === address.address &&
                fetchaddress.city === address.city &&
                fetchaddress.state === address.state &&
                fetchaddress.country === address.country 
            );
        });
    
        if (duplicateAddress) {
            setAddressValidation(prevState => ({
                ...prevState,
                sameaddress: "Address already exists"
            }));
            return [false, "Address already exists"];
            
        } else {
            try {
                const [ok, response]=await API.addDeliveryAddress({
                    ...address,
                    latitude: location.lat,
                    longitude: location.lng
                });
                if (ok && response) {
                    return [ok,response]
                  
                } else {
                   
                    if (response && response.error) {
                        alert(response.error);
                    }
                }
            } catch (error) {
                console.error("Async/Await Error:", error);
            }
        }
    };

    const handleDelete = async (id) => {
        loader.setLoaderOpen(true);
        await API.deleteAddress(id);
        setUpdate(!update);
        loader.setLoaderOpen(false);
    }

    const filteredAddresses = data && data.data && data.data.filter(address => 
        address.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <Loader isOpen={loading} />
            <Breadcrumb
                data={[
                    {
                        name: "Home",
                        link: "/",
                    },
                    {
                        name: "Delivery Addresses",
                        link: "",
                    },
                ]}
            />
            <div className="row w-100 text-center m-0">
                <div className="col-3">
                    <ProfileSideBar />
                </div>
                <div className="col-12 col-sm-9">
                    <div className="d-flex flex-column justify-content-between">
                        <h4>Delivery addresses</h4>
                        <button
                            className="btn web-primary-color-button m-auto d-flex align-items-center"
                            data-bs-toggle="modal"
                            data-bs-target="#editaddress"
                        >
                            <ion-icon
                                name="Location-outline"
                                class="web-secondary-icon-color"
                                style={{ fontSize: "22px" }}
                            />
                            Add New Address
                        </button>
                        <div
                            className="mx-auto my-2"
                            style={{ width: "80%" }}
                        >
                            <input
                                type="text"
                                placeholder="Search address by customer name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="form-control text-center web-primary-input"
                            />
                        </div>
                    </div>
                    {!loading && status === "fetched" && data.data && (
                        <>
                       {data.data.length > 0 ? (
                                searchQuery ? (
                                    filteredAddresses.length > 0 ? (
                                        filteredAddresses.map((address) => (
                                    <div
                                        className="card opp-tans mx-auto my-2"
                                        style={{ width: "80%" }}
                                        key={address.id}
                                    >
                                        <div className="card-body ">
                                            <div className="d-flex flex-row justify-content-center">
                                                <div>
                                                    <LocationOutline
                                                        color={"#FFFFFF"}
                                                    />
                                                </div>
                                                <div>
                                                    <h4>{address.name}</h4>
                                                    <p>
                                                        {address.address}{" "}
                                                        {address.city}{" "}
                                                        {address.zip}{" "}
                                                        {address.state}{" "}
                                                        {address.country} <br />
                                                        Phone Number:{" "}
                                                        {address.phone}
                                                    </p>
                                                    <button
                                                        className="btn web-secondary-color-button d-inline-flex align-items-center"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editaddress"
                                                        onClick={(e) => {
                                                            setAddress(address);
                                                        }}
                                                    >
                                                        <ion-icon
                                                            name="Create-outline"
                                                            class="web-secondary-icon-color"
                                                            style={{
                                                                fontSize:
                                                                    "22px",
                                                            }}
                                                        />
                                                        <span className="ms-1">
                                                            Edit
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="btn btn-danger d-inline-block mx-1"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                address.id
                                                            );
                                                        }}
                                                    >
                                                        <TrashOutline
                                                            color={"#FFFFFF"}
                                                        />{" "}
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                className="btn web-primary-color-button my-1"
                                                onClick={(e) => {
                                                    history.push({
                                                        pathname: "/cart",
                                                        state: {
                                                            addressId:
                                                                address.id,
                                                        },
                                                    });
                                                }}
                                            >
                                                Deliver Here
                                            </button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div
                                    className="card opp-tans mx-auto my-2"
                                    style={{ width: "80%" }}
                                >
                                    <div className="card-body">
                                        <p>No addresses found for "{searchQuery}".</p>
                                    </div>
                                </div>
                            )
                        ) :(
                            data.data.map((address) => (
                                <div
                                        className="card opp-tans mx-auto my-2"
                                        style={{ width: "80%" }}
                                        key={address.id}
                                    >
                                        <div className="card-body ">
                                            <div className="d-flex flex-row justify-content-center">
                                                <div>
                                                    <LocationOutline
                                                        color={"#FFFFFF"}
                                                    />
                                                </div>
                                                <div>
                                                    <h4>{address.name}</h4>
                                                    <p>
                                                        {address.address}{" "}
                                                        {address.city}{" "}
                                                        {address.zip}{" "}
                                                        {address.state}{" "}
                                                        {address.country} <br />
                                                        Phone Number:{" "}
                                                        {address.phone}
                                                    </p>
                                                    <button
                                                        className="btn web-secondary-color-button d-inline-flex align-items-center"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editaddress"
                                                        onClick={(e) => {
                                                            setAddress(address);
                                                        }}
                                                    >
                                                        <ion-icon
                                                            name="Create-outline"
                                                            class="web-secondary-icon-color"
                                                            style={{
                                                                fontSize:
                                                                    "22px",
                                                            }}
                                                        />
                                                        <span className="ms-1">
                                                            Edit
                                                        </span>
                                                    </button>
                                                    <button
                                                        className="btn btn-danger d-inline-block mx-1"
                                                        onClick={(e) => {
                                                            handleDelete(
                                                                address.id
                                                            );
                                                        }}
                                                    >
                                                        <TrashOutline
                                                            color={"#FFFFFF"}
                                                        />{" "}
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                className="btn web-primary-color-button my-1"
                                                onClick={(e) => {
                                                    history.push({
                                                        pathname: "/cart",
                                                        state: {
                                                            addressId:
                                                                address.id,
                                                        },
                                                    });
                                                }}
                                            >
                                                Deliver Here
                                            </button>
                                        </div>
                                    </div>
                            ))
                        )
                    ):
                         (
                                <p>No Delivery addresses found!</p>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="modal fade" id="editaddress" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">
                                {address.id ? "Update Address" : "Add Address"}
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    setAddress({
                                        address: "",
                                        city: "",
                                        country: "",
                                        latitude: null,
                                        longitude: null,
                                        name: "",
                                        phone: "",
                                        state: "",
                                        zip: "",
                                    });
                                    setAddressValidation({
                                        address: "",
                                        city: "",
                                        country: "",
                                        name: "",
                                        phone: "",
                                        state: "",
                                        zip: "",
                                    });
                                    setSearchQuery("");
                                }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-1">
                                <label>Name:</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <ion-icon name="person"></ion-icon>
                                    </span>
                                    <input
                                        type="text"
                                        value={address.name}
                                        onChange={(e) => {
                                            setAddress({
                                                ...address,
                                                name: e.target.value,
                                            });
                                        }}
                                        placeholder="Enter Name"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                {addressValidation.name && (
                                    <p className="text-danger">
                                        {addressValidation.name}
                                    </p>
                                )}
                            </div>
                            <div className="mb-1">
                                <label>Phone:</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <ion-icon name="call"></ion-icon>
                                    </span>
                                    <input
                                        type="tel"
                                        value={address.phone}
                                        onChange={(e) => {
                                            setAddress({
                                                ...address,
                                                phone: e.target.value,
                                            });
                                        }}
                                        placeholder="Enter Phone Number"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            {addressValidation.phone && (
                                <p className="text-danger">
                                    {addressValidation.phone}
                                </p>
                            )}
                            <div className="mb-1">
                                <label>Address:</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <ion-icon name="location-sharp"></ion-icon>
                                    </span>
                                    <input
                                        type="text"
                                        value={address.address}
                                        onChange={(e) => {
                                            setAddress({
                                                ...address,
                                                address: e.target.value,
                                            });
                                        }}
                                        placeholder="Enter Address"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            {addressValidation.address && (
                                <p className="text-danger">
                                    {addressValidation.address}
                                </p>
                            )}
                            <div className="row">
                                <div className="col-md-6 mb-1">
                                    <label>State:</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <ion-icon name="location-sharp"></ion-icon>
                                        </span>
                                        <input
                                            type="text"
                                            value={address.state}
                                            onChange={(e) => {
                                                setAddress({
                                                    ...address,
                                                    state: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter State"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    {addressValidation.state && (
                                        <p className="text-danger">
                                            {addressValidation.state}
                                        </p>
                                    )}
                                </div>
                                <div className="col-md-6 mb-1">
                                    <label>Country:</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <ion-icon name="location-sharp"></ion-icon>
                                        </span>
                                        <input
                                            type="text"
                                            value={address.country}
                                            onChange={(e) => {
                                                setAddress({
                                                    ...address,
                                                    country: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter Country"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    {addressValidation.country && (
                                        <p className="text-danger">
                                            {addressValidation.country}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-1">
                                    <label>City:</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <ion-icon name="location-sharp"></ion-icon>
                                        </span>
                                        <input
                                            type="text"
                                            value={address.city}
                                            onChange={(e) => {
                                                setAddress({
                                                    ...address,
                                                    city: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter City"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    {addressValidation.city && (
                                        <p className="text-danger">
                                            {addressValidation.city}
                                        </p>
                                    )}
                                </div>
                                <div className="col-md-6 mb-1">
                                    <label>Zip:</label>
                                    <div className="input-group">
                                        <span className="input-group-text">
                                            <ion-icon name="location-sharp"></ion-icon>
                                        </span>
                                        <input
                                            type="tel"
                                            value={address.zip}
                                            onChange={(e) => {
                                                setAddress({
                                                    ...address,
                                                    zip: e.target.value,
                                                });
                                            }}
                                            placeholder="Enter ZIP code"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    {addressValidation.zip && (
                                        <p className="text-danger">
                                            {addressValidation.zip}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {addressValidation.sameaddress && (
                                <p className="text-danger">
                                    {addressValidation.sameaddress}
                                </p>
                            )}
                            <button
                                ref={ModalCloseButtonRef}
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={(e) => {
                                    setAddress({
                                        address: "",
                                        city: "",
                                        country: "",
                                        latitude: null,
                                        longitude: null,
                                        name: "",
                                        phone: "",
                                        state: "",
                                        zip: "",
                                    });
                                    setAddressValidation({
                                        address: "",
                                        city: "",
                                        country: "",
                                        name: "",
                                        phone: "",
                                        state: "",
                                        zip: "",
                                        sameaddress: "",
                                    });
                                    setSearchQuery("");
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    handleSave();
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeliveryAddress