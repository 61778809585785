import React from "react";
import { Link, useParams } from "react-router-dom";

const BrandSelectionSideBar = ({ brandList }) => {
  const params = useParams();
  console.log(params);

  return (
    brandList && (
      <div className="" id="accordionExample">
        {brandList.map((brandName, index) => {
        
          const unique = `brandid-${brandList.id}`;
          return (
            <div className="brands-list shadow-sm web-primary-color-button focus-ring-primary"  key={unique}>
              <Link
                to={`/brands/${brandName.name}/${brandName.id}`}
                className='text-decoration-none'
                style={{ color: "black" }}
              >
                
              <div className="d-flex flex-row p-2 align-items-center">
                
                  <img
                    src={brandName.icon}
                    width="50"
                    height="50"
                    alt="category icon"
                  />
                  <h2 className="ms-2 h4">{brandName.name}</h2>
              
              </div>
            </Link>
              
            </div>
          );
        })}
      </div>
    )
  );
};

export default BrandSelectionSideBar;
