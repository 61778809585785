import React, { useEffect, useRef, useState } from 'react';
import { CreateOutline, Star, TrashOutline } from 'react-ionicons';
import useAPI from '../../../utils/api';
import { useLoader } from '../../../utils/contexts';
import { getAuthToken } from '../../../utils/storageService';
import useFetch from '../../../utils/useFetch';
import Loader from '../../common/Loader';

const ProductReview = ({prdId, userId}) => {
  const API = useAPI();
  const loader = useLoader();
  const [reviewData, setReviewData] = useState({
    comment: "",
    star: null,
    fromAdd: true
  });
  const [selectedReviewData, setSelectedReviewData] = useState({
    comment: "",
    star: null,
  });
  const [reviewDataValidation, setReviewDataValidation] = useState({
    star: ""
  });
  const ModalCloseRef = useRef();

  const [currUserReview, setCurrUserReview] = useState(null);
  const [update, setUpdate] = useState(false);
  const {data, status, loading} = useFetch("produtRatings", prdId, update);

  useEffect(() => {
    if(!loading && status === "fetched" && data && data.data && getAuthToken()){
      setCurrUserReview(prev => {
        let res = null;
        if(userId) {
          
          for(let i = 0; i<data.data.length; i++){
            if(userId === data.data[i].user_id){
              res = data.data[i];
              break;
            }
          }
          
        }
        return res;
    })
    }
  }, [data, status, loading])

  useEffect(() => {
    if(currUserReview){
      setReviewData({
        comment: currUserReview.comment,
        star: currUserReview.star,
        fromAdd: false
      });
      setSelectedReviewData({
        comment: currUserReview.comment,
        star: currUserReview.star,
      });
    }
  }, [currUserReview]);

  const handleUpdateReview = async () =>{
    if(!reviewData.star){
      setReviewDataValidation({
        star: "Minimum one star is required."
      });
      return;
    }else{
        setReviewDataValidation({
            star: ""
          });
      ModalCloseRef.current.click();
    }
    loader.setLoaderOpen(true);
    const [ok, response] = reviewData.fromAdd ? await API.addReview(
      {
          product_id: prdId,
          comment: reviewData.comment,
          star: reviewData.star
      }
      ) :
      await API.updateReview(
          {
            product_id: prdId,
            comment: reviewData.comment,
            star: reviewData.star
          },
      );
      if(ok && response){
        setReviewData({
          comment: "",
          star: null,
          fromAdd: true
        });
        setUpdate(!update);
      }else{
          if(response && response.error){
              alert(response.error);
          }
      }
      loader.setLoaderOpen(false);
  }

  const handleDeleteReview = async () =>{
    loader.setLoaderOpen(true);
    const [ok, response] = await API.deleteReview(prdId) 
    if(ok && response){
        setUpdate(!update);
        setReviewData({
          comment: "",
          star: null,
          fromAdd: true
        })
        setSelectedReviewData({
            comment: "",
            star: null,
          })
    }else{
        if(response && response.error){
            alert(response.error);
        }
    }
    loader.setLoaderOpen(false);
  }

  return (<>
    <Loader isOpen={loading}/>
    <div className='mt-5 prd-details-padding'>
      <h4 className="mb-3">
        <b>Product Reviews</b>
      </h4>
      {getAuthToken() && reviewData.fromAdd && <button type="button" className="btn web-primary-color-button text-uppercase" data-bs-toggle="modal" data-bs-target="#reviewModal" onClick={e=> {
        setReviewDataValidation({star: ""});
        setReviewData({...reviewData, ...selectedReviewData})
      }}
      >Add product review</button>}
      {!loading && status === "fetched" && data && data.data && data.data.length > 0 ? data.data.map(
        (review) => <div className="card my-4 opp-tans">
        <div className="card-body">
          <h5 className="card-title d-flex justify-content-between">
            <span>{review.user_name}</span>
            {getAuthToken() && userId && userId === review.user_id &&  <span className='ml-auto'>
              <span data-bs-toggle="modal" data-bs-target="#reviewModal" onClick={e=>{
                setReviewData({
                  comment: review.comment,
                  star: review.star,
                  fromAdd: false
                });
                setSelectedReviewData({
                    comment: review.comment,
                  star: review.star,
                })
              }}><CreateOutline/></span>
              <span onClick={e=>{handleDeleteReview()}}><TrashOutline /></span>
            </span>}
          </h5>
          {review.star && Array.from(Array(review.star), (e) => {
            return <Star color="#ffce00"/>
          })}
          <p className='mb-0 mt-1'>{review.comment}</p>
        </div>
      </div>)
      :
      <div className="card my-2 opp-tans">
        <div className="card-body">
          This product does not have any review.
        </div>
      </div>
      }
    </div>
    <div className="modal fade" id="reviewModal" tabindex="-1" aria-labelledby="reviewModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">{reviewData.fromAdd ? 'Add Review' : 'Update Review'}</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div>Rate this Product</div>
          {reviewData.star ? <>
          {Array.from(Array(reviewData.star), (e, i) => {
              return <span className='mx-1' onClick={e=>{setReviewData(prev => {return {...prev, star: i + 1}})}}><Star color="#ffce00"/></span>
          })}
          {Array.from(Array(5 - reviewData.star), (e, i) => {
              return <span className='mx-1' onClick={e=>{setReviewData(prev => {return {...prev, star: i + 1 + reviewData.star}})}}><Star color="#989aa2"/></span>
          })}
          </> : 
          Array.from(Array(5), (e, i) => {
              return <span className='mx-1' onClick={e=>{setReviewData(prev => {return {...prev, star: i + 1}})}}><Star color="#989aa2"/></span>
          })}
          <p className='text-danger'>{reviewDataValidation.star}</p>
        <div>Review this Product</div>
        <textarea rows="5" className='w-100' value={reviewData.comment} onChange={e=> setReviewData(prev=> {return {...prev, comment: e.target.value}})}></textarea>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary text-uppercase" data-bs-dismiss="modal" ref={ModalCloseRef} onClick={() => setReviewData({...reviewData, 
          comment: "",
          star: null,
        })}>Cancel</button>
        <button type="button" className="btn btn-primary text-uppercase" onClick={e=> {handleUpdateReview()}}>{reviewData.fromAdd ? 'ADD REVIEW' : 'UPDATE REVIEW'}</button>
      </div>
    </div>
  </div>
</div>
  </>)
}

export default ProductReview