import React, { useEffect, useRef, useState } from 'react';
import { CheckmarkCircleOutline } from 'react-ionicons';
import { Link, useLocation } from 'react-router-dom';
import emptyCart from '../../images/emptycart.png';
import useAPI, { REACT_APP_API_HOST } from '../../utils/api';
import { useCart, useLoader } from '../../utils/contexts';
import { deleteQuickOrder, getQuickOrderImage, setQuickOrder } from '../../utils/storageService';
import Breadcrumb from '../common/Breadcrumb';
import Footer from '../common/Footer';
import Header from '../common/Header';
import CartCards from './components/CartCards';
import CartPrice from './components/CartPrice';
import CartTable from './components/CartTable';
import CartUserDetails from './components/CartUserDetails';
import Coupons from './components/Coupons';
import DateTime from './components/DateTime';
import PaymentMethods from './components/PaymentMethods';

const CartCheckout = () => {
    const API = useAPI();
    const cartData = useCart();
    const loader = useLoader();
    const [quickImages, setQuickImages] = useState(getQuickOrderImage());
    const [paymentMethod, setPaymentMethod] = useState("");
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [isOrderPlaced, setIsOrderPlaced] = useState(false);
    const [deliveryTime, setDeliveryTime] = useState(null);
    const [message, setMessage] = useState("");
    const [orderValidation, setOrderValidation] = useState("");
    const [address, setAddress] = useState(null);
    const [cartOption, setCartOption] = useState(cartData.myCartData.products.length > 0 ? "cart" : (quickImages && quickImages.length > 0) ? "quick" : "");

    const location = useLocation();
    const fileInputRef = useRef();

    // useEffect(() => {
    //     setCartOption(cartData.myCartData.products.length > 0 ? "cart" : (quickImages && quickImages.length > 0) ? "quick" : "");
    // }, [cartData, quickImages]);

    useEffect(() => {
        if (cartOption === 'quick') {
            setPaymentMethod('CASH_ON_DELIVERY');
        } else {
            setPaymentMethod("");
        }
    }, [cartOption]);

    useEffect(() => {
        const validateCoupon = async () => {
            loader.setLoaderOpen(true);
            const [ok, response] = await API.couponsValid(
                cartData.myCartData.coupon);

            if (ok && response) {
                if (
                    response.data &&
                    response.data.min_order_value > cartData.myCartData.pricing_breakup.subTotal
                ) {
                    const [ok, responseData] = await API.deleteCoupon();
                    if (ok && responseData) {
                        cartData.setMyCartData(responseData.data);
                    }
                }
            }
            loader.setLoaderOpen(false);
        };
    
        if (cartData.myCartData.coupon) {
            validateCoupon();
        }
    }, []);    

    const onQuickOrderFileAdded = async (e) =>{
        loader.setLoaderOpen(true);
        const [ok, response] = await API.uploadImage(e.target.files[0]);
        if(ok && response && response.platform_path){
            setQuickOrder(response.platform_path);
            e.target.value = "";
            setQuickImages([...quickImages, response.platform_path])
        }else{
            if(response && response.error){
                alert(response.error);
            }
        }
        loader.setLoaderOpen(false);
    }

    const removeQuickImage = (value) => {
        deleteQuickOrder(value);
        let temp = [...quickImages];
        const index = temp.indexOf(value);
        if(index !== -1){
            temp.splice(index, 1);
        }
        setQuickImages(temp);

        if(temp.length === 0){
            setCartOption("cart");
        }
    }

    const handlePlaceOrder = async () => {
        loader.setLoaderOpen(true);
        let res = true;
        if(!address){
            setOrderValidation("Please select the valid address before placing order.");
            res = false;
        }
        if(address && !address.latitude && !address.longitude){
            setOrderValidation("Latitude and longitude are not available for this address. Please select the valid address before placing order.");
            res = false;
        }

        if(res){
            let body = {
                custom_message: message,
                delivery_address: address.address,
                delivery_city: address.city,
                delivery_country: address.country,
                delivery_latitude: address.latitude,
                delivery_longitude: address.longitude,
                delivery_zip: address.zip,
                schedule_order_date: deliveryDate,
                schedule_order_time: deliveryTime,
                
                store_id: cartData.myCartData.store_id,
                user_id: address.user_id,
                user_name: address.name,
                user_phone: address.phone
            }
            if(cartOption === 'cart'){
                let orderPaymentMethod = "";
                if ( paymentMethod == "ONLINE_CASHFREE" || paymentMethod == "ONLINE_PHONEPE" || paymentMethod == "ONLINE_RAZORPAY") {
                    orderPaymentMethod = "ONLINE";
                } 
                else if (paymentMethod == "CASH_ON_DELIVERY") {
                    orderPaymentMethod = paymentMethod
                }
                body.coupon = cartData.myCartData.coupon
                body.is_quick_order = 0
                body.payment_method = orderPaymentMethod
                body.shipping_cost = cartData.myCartData.pricing_breakup.totalPrice
            }
            if(cartOption === 'quick'){
                body.is_quick_order = 1
                body.payment_method = "CASH_ON_DELIVERY"
                body.shipping_cost = ""
                body.order_images = quickImages.map(val => {return {image_path: val}})
            }
            setOrderValidation("");
            const [ok, response] = await API.placeOrder(body);
            if(ok && response){
                if(response.id && response.type === "order"){
                    if (paymentMethod == "ONLINE_CASHFREE" || paymentMethod == "ONLINE_PHONEPE" || paymentMethod == "ONLINE_RAZORPAY") {
                        const payResponse = await API.onlinePayData({
                            gateway: paymentMethod,
                            "ik-payment-token": response["ik-payment-token"],
                            "redirect-url": `${window.location.origin}/thankyou/${paymentMethod == "ONLINE_CASHFREE" ? '1' : `2?order_id=${response['id']}`}`
                        });
                        if(payResponse[0]){
                            if (payResponse[1] && payResponse[1].gateway && payResponse[1].gateway === "ONLINE_CASHFREE") {
                                let paymentSessionId = payResponse[1].payment_session_id;
                                if (!paymentSessionId) {
                                    alert("Cashfree account is blocked by seller. Please try any other method of payment.");
                                } else {
                                    const cashfree = new window.Cashfree(paymentSessionId);
                                    cashfree.redirect();
                                }
                            } else if (payResponse[1] && payResponse[1].gateway && payResponse[1].gateway === "ONLINE_PHONEPE") {
                                let phonepe_url = payResponse[1].url;
                                if (!phonepe_url) {
                                    alert("Phonepe account is blocked by seller. Please try any other method of payment.");
                                } else {
                                    window.location.href = phonepe_url;
                                }
                            } else if (payResponse[1] && payResponse[1].gateway && payResponse[1].gateway === "ONLINE_RAZORPAY") {
                                let options = payResponse[1].options;
                                if (!options) {
                                    alert("Razorpay account is blocked by seller. Please try any other method of payment.");
                                } else {
                                    let successurl;

                                    let handler = function(handleRes) {
                                        fetch(
                                            `${REACT_APP_API_HOST}pay/confirm?order_id=${
                                                response[
                                                    "id"
                                                ]
                                            }&gateway=${paymentMethod}`,
                                            {
                                                headers: {
                                                    Authorization: `Bearer ${
                                                        JSON.parse(
                                                            localStorage.getItem(
                                                                "auth"
                                                            )
                                                        )
                                                            .token
                                                    }`,
                                                    "x-ik-domain": localStorage.getItem(
                                                        "ik-domain"
                                                    ),
                                                    "X-Razorpay-Payment-Id":
                                                        handleRes.razorpay_payment_id,
                                                    "X-Razorpay-Signature":
                                                        handleRes.razorpay_signature,
                                                },
                                            }
                                        )
                                        .then(res => res.json())
                                        .then(
                                            (
                                                confirmRes
                                            ) => {
                                                successurl = `${
                                                    window
                                                        .location
                                                        .origin
                                                }/thankyou/3?order_id=${
                                                    response[
                                                        "id"
                                                    ]
                                                }&status=${
                                                    confirmRes.status
                                                }`;

                                                window.location.href = successurl;
                                            }
                                        );
                                    };
                                    options = { ...options, handler };

                                    const rzp1 = new window.Razorpay(options);
                                    rzp1.open();

                                    rzp1.on(
                                        "payment.failed",
                                        function(
                                            err
                                        ) {
                                            alert(
                                                err
                                                    .error
                                                    .description
                                            );
                                        }
                                    );
                                }
                            }
                        }
                    }else{
                        if(cartOption === 'cart'){
                            const newCart = await API.deleteCart();
                            if(newCart[0] && newCart[1] && newCart[1].data){
                                cartData.setMyCartData(newCart[1].data);
                            }
                        }
                        if(cartOption === 'quick'){
                            localStorage.removeItem("quick-order-image");
                        }
                        setIsOrderPlaced(true);
                        setMessage("");
                        setPaymentMethod("CASH_ON_DELIVERY");
                        setDeliveryDate(null);
                        setDeliveryTime(null);
                        setAddress(null);
                    }
                }
            }else{
                if(response.message){
                    setOrderValidation(response.message);
                }else{
                    alert('There is some issue with connection. Please try again after some time.')
                }
            }
        }
        loader.setLoaderOpen(false);
    }

    return ( isOrderPlaced ?
        <>
            <div className="text-center pt-5">
                <CheckmarkCircleOutline color="#008000" width="120px" height="120px"/>
                <p>Order Placed Successfully!</p>
                <Link to={`/`} className="btn web-primary-color-button" onClick={e=>{setIsOrderPlaced(false)}}>Continue Shopping</Link>
            </div>
        </>
        :
        <>
            <Header/>
            <Breadcrumb data={[
                {
                name: "Home",
                link: "/"
                },
                { 
                name: "Cart",
                link: "",
                }
            ]}/>
            {(cartData.myCartData.products.length > 0 || (quickImages && quickImages.length > 0)) && cartOption ? <>
                {quickImages && quickImages.length > 0 && <div><div className="row m-0 px-3">
                    {cartData.myCartData.products.length > 0 && <div className={`${(quickImages && quickImages.length > 0) ? 'col-6' : 'col-12'}`}>
                        <div className="d-grid">
                            <button className={`btn ${cartOption === "cart" ? 'web-secondary-color-button' : 'btn-secondary' }`} type="button" onClick={e=> {setCartOption("cart")}}>Cart</button>
                        </div>
                    </div>}
                    { (quickImages && quickImages.length > 0) && <div className={`${cartData.myCartData.products.length > 0 ? 'col-6' : 'col-12'}`}>
                        <div className="d-grid">
                            <button className={`btn ${cartOption === "quick" ? 'web-secondary-color-button' : 'btn-secondary' }`} type="button" onClick={e=> {setCartOption("quick"); setOrderValidation("")}}>Quick order</button>
                        </div>
                    </div>}
                </div> </div>}
                {cartOption === "cart" && <>
                <CartTable className="d-none d-lg-block"/>
                <CartCards className="d-block d-lg-none m-3"/>
                <Coupons cart={cartData}/>
                <CartPrice cart={cartData} paymentMethod={paymentMethod} /></>}
                <CartUserDetails address={address} setAddress={setAddress} selectedAddress={location.state && location.state.addressId}/>
                {cartOption === "quick" && 
                <>
                    <div className='border m-3 p-2 d-flex flex-wrap justify-content-around'>
                        {quickImages.length > 0 && quickImages.map((value) => <div className='d-flex flex-column'>
                            <img src={'https://cdn.intelikart.com/uploads' + value} alt="product" width={'200px'} height={'200px'}/>
                            <button type="button" className='btn btn-danger mt-1' onClick={e=> {removeQuickImage(value)}}>Remove</button>
                        </div>)
                        }
                    </div>
                        <input className='d-none' type='file' onChange={(e) => onQuickOrderFileAdded(e)} ref={fileInputRef} />
                        <button className='btn web-primary-color-button d-flex justify-content-center align-items-center mx-auto' onClick={() => fileInputRef.current && fileInputRef.current.click()} style={{ fontSize: '16px' }}>
                            <ion-icon name='camera-outline' style={{ fontSize: '18px', marginRight: '.7rem' }}/>
                            Add more
                        </button>
                </>
                }
                <PaymentMethods paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} cartOption={cartOption}/>
                { cartData && cartData.myCartData.schedule_status === "active" && <DateTime deliveryDate={deliveryDate} setDeliveryDate={setDeliveryDate} deliveryTime={deliveryTime} setDeliveryTime={setDeliveryTime}/>}

                <div className="border rounded shadow m-3 p-2">
                    <div>
                        <h5 className="card-title mb-3 p-3 web-secondary-color-button">Your Instructions:</h5>
                    </div>
                    <div className='mx-3'>
                        <textarea type='text' className='form-control' value={message} onChange={e=> setMessage(e.target.value)} placeholder='Notes for your order. We will try to fulfill.'></textarea>
                    </div>
                </div>
                {orderValidation && <p className='text-danger m-3'>{orderValidation}</p>}
                <div className='text-center'><button className="btn h4 web-primary-color-button rounded my-3" onClick={e=> {handlePlaceOrder()}}>Place Order</button></div>
            </>
            :
            <>
                <div className="card opp-tans">
                    <div className="card-body text-center">
                        <img src={emptyCart} alt="Empty cart" width="150px" height="150px"/>
                        <p>No products in cart</p>
                        <Link to="/" className="btn web-primary-color-button">Continue Shopping</Link>
                    </div>
                </div>
            </>
            }
            <Footer/>
        </>
    )
}

export default CartCheckout