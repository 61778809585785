import React from 'react';
import { getCurrencySymbol } from '../../../utils/currency';

const CartPrice = ({cart, paymentMethod}) => {
    const priceBreakup = cart.myCartData.pricing_breakup;
    const taxAndCharges = paymentMethod === "CASH_ON_DELIVERY" ? priceBreakup.taxesCharges : priceBreakup.taxesPrepaidCharges;

    return (
        <div className="border rounded shadow m-3 p-2">
            <div className="card-body">
                <div>
                    <h5 className="card-title mb-3 p-3 web-secondary-color-button">Price Details</h5>
                </div>
                <div className='px-2 px-md-4'>
                    {!localStorage.getItem('quick-order-image') && 
                    <div className='d-flex justify-content-between'>
                        <h5> Cart total :</h5>
                        <h5>
                        {getCurrencySymbol()} {priceBreakup.subTotal.toFixed(2)}
                        </h5>
                    </div>}

                    {priceBreakup.discounts && priceBreakup.discounts.coupon_name &&
                        <div className='d-flex justify-content-between '>
                            <h5>Coupon ({
                                priceBreakup.discounts.coupon_name
                            })</h5>
                            <h5>
                            {`${priceBreakup.discounts.coupon_value_display.slice(0, 1)} ${getCurrencySymbol()} ${priceBreakup.discounts.coupon_value_display.slice(1)}`}
                            </h5>
                        </div>
                    }

                    {taxAndCharges && taxAndCharges.map(taxValue => 
                        <div className='d-flex justify-content-between '>
                            <h5>{taxValue.tax_name}</h5>
                            <h5>{getCurrencySymbol()}{" "} { taxValue.tax_value_display }</h5>
                        </div>
                    )}

                    <hr/>
                    <div className='d-flex justify-content-between '>
                        <h5> Total price :</h5>
                        <h5>
                        {getCurrencySymbol()} {paymentMethod === "CASH_ON_DELIVERY" ? priceBreakup.totalPrice: priceBreakup.totalPricePrepaid}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartPrice