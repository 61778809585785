import React, { useEffect, useState } from "react";
import useAPI from "../../../utils/api";
import { useLoader } from "../../../utils/contexts";
import useFetch from "../../../utils/useFetch";
import Loader from "../../common/Loader";

const Coupons = ({ cart }) => {
    const priceBreakup = cart.myCartData.pricing_breakup;

    const API = useAPI();
    const loader = useLoader();

    const [couponCode, setCouponCode] = useState("");
    const [couponValid, setCouponValid] = useState("");

    const { data, status, loading } = useFetch("coupons");

    // Uncomment if you wanted to autofill coupon
    useEffect(() => {
        if (cart.myCartData && cart.myCartData.coupon) {
            setCouponCode(cart.myCartData.coupon);
            setCouponValid("");

            if (priceBreakup.discounts && priceBreakup.discounts.message) {
                setCouponValid(priceBreakup.discounts.message);
            }
        }
        // else{
        //     if(!loading && status === "fetched" && data.data && data.data.length > 0){
        //         setCouponCode(`${data.data[0].code}`);
        //     }
        // }
    }, [data, status, loading, priceBreakup.discounts]);

    useEffect(() => {
        if (couponCode) {
            revalidateCoupon();
        }
    }, [priceBreakup.subTotal]);

    const revalidateCoupon = async () => {
        if (couponCode) {
            loader.setLoaderOpen(true);
            const [ok, response] = await API.couponsValid(couponCode);
            if (ok && response) {
                if (
                    response.data &&
                    response.data.min_order_value > priceBreakup.subTotal
                ) {
                    setCouponValid(
                        `Order above ${response.data.min_order_value} to apply this coupon.`
                    );
                    const [ok, responseData] = await API.deleteCoupon();
                    if (ok && responseData) {
                        cart.setMyCartData(responseData.data);
                    }
                } else {
                    setCouponValid("");
                }
            }
            loader.setLoaderOpen(false);
        }
    };
    
    const handleCouponValueChange = (e) => {
        setCouponCode(e.target.value);
    };

    const checkValidation = async () => {
        if (couponCode) {
            loader.setLoaderOpen(true);
            const [ok, response] = await API.couponsValid(couponCode);
            if (ok && response) {
                if (response.message === "no valid coupon") {
                    setCouponValid("Coupon code is not valid.");
                } else if (
                    response.data &&
                    response.data.min_order_value > priceBreakup.subTotal
                ) {
                    setCouponValid(
                        `Order above ${response.data.min_order_value} to apply this coupon`
                    );
                } else {
                    setCouponValid("");
                    const updateValue = await API.updateCoupon({
                        coupon: couponCode,
                    });
                    if (updateValue[0] && updateValue[1]) {
                        cart.setMyCartData(updateValue[1].data);

                        if (
                            updateValue[1].data.pricing_breakup.discounts &&
                            updateValue[1].data.pricing_breakup.discounts
                                .message
                        ) {
                            setCouponValid(
                                updateValue[1].data.pricing_breakup.discounts
                                    .message
                            );
                        }
                    }
                }
            }
            loader.setLoaderOpen(false);
        } else {
            setCouponValid("Coupon code is required.");
        }
    };

    const removeCoupon = async () => {
        loader.setLoaderOpen(true);
        const [ok, response] = await API.deleteCoupon();
        if (ok && response) {
            cart.setMyCartData(response.data);

            setCouponCode("");
            setCouponValid("");
        }
        loader.setLoaderOpen(false);
    };

    return (
        <>
            <Loader isOpen={loading} />
            <div className="border rounded shadow m-3 p-2">
                <div className="card-body">
                    <div>
                        <h5 className="card-title mb-3 p-3 web-secondary-color-button">
                            Coupons
                        </h5>
                    </div>
                    <div className="px-2 px-md-4">
                        <input
                            placeholder="Coupon"
                            value={couponCode}
                            onChange={(e) => {
                                handleCouponValueChange(e);
                            }}
                            type="text"
                            className="form-control d-inline"
                        />
                        {couponValid && (
                            <div className="text-danger">{couponValid}</div>
                        )}
                        {cart.myCartData &&
                        cart.myCartData.coupon &&
                        cart.myCartData.coupon === couponCode &&
                        priceBreakup.discounts &&
                        priceBreakup.discounts.coupon_name ? (
                            <button className="btn btn-outline-success mt-2">
                                Applied!
                            </button>
                        ) : (
                            <button
                                className="btn web-primary-color-button mt-2"
                                onClick={(e) => checkValidation()}
                            >
                                Apply
                            </button>
                        )}
                        <button
                            className="btn btn-danger ms-2 mt-2"
                            onClick={(e) => {
                                removeCoupon();
                            }}
                        >
                            Remove
                        </button>
                    </div>
                    <div>
                        <h5 className="mt-3 ms-2 ms-md-4">Available Coupons</h5>
                        <div className="d-flex ms-md-4 flex-wrap">
                            {!loading &&
                            status === "fetched" &&
                            data.data &&
                            data.data.length > 0
                                ? data.data.map((coupon) => (
                                      <button
                                          onClick={(e) => {
                                              setCouponCode(coupon.code);
                                          }}
                                          className="btn btn-outline-secondary m-2"
                                      >
                                          {coupon.code}
                                      </button>
                                  ))
                                : "No coupons available"}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Coupons;
