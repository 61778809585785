import React from 'react';
import { Checkmark } from 'react-ionicons';
import { Link } from 'react-router-dom';

const CategorySelectionModal = ({categoryList, selectedCateName, selectedCateId}) => {
    return (categoryList && <>
    <div className="d-grid gap-2">
        <button type="button" className="btn web-primary-color-button mx-1" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <span className="d-flex justify-content-between align-items-center">
          <span>{selectedCateName}</span>
          <span className='ms-auto'><ion-icon
            class="web-primary-icon-color"
            name="caret-down"
          /></span>
        </span>
        </button>
    </div>
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Choose Category</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      {categoryList.map(parentCate =>
        
        <div className="my-2">

<div className="p-2">
        <div className="border-bottom mb-2">
            <img src={parentCate.icon} width="60" height="60" alt="category icon"/>
            <h5 className='d-inline ms-2'>{parentCate.name}</h5>
        </div>
    {parentCate.subCategory.length !== 0 && parentCate.subCategory.map(subCate => <Link key={subCate.id} to={`/category/${subCate.name}/${subCate.id}`} className=" text-decoration-none text-dark"><div className="d-flex align-items-center border-bottom mb-1 ms-4" data-bs-dismiss="modal">
            <img src={subCate.icon} width="60" height="60" alt="category icon"/>
            <span className='flex-fill ms-2'>{subCate.name}</span> 
            <span className={`ms-auto ${subCate.id == selectedCateId ? "" : "d-none"}`}><Checkmark/></span>
        </div></Link>
    )}
</div>
</div>)}
      </div>
    </div>
  </div>
</div>
    </>)
}

export default CategorySelectionModal