import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useAPI from '../../../utils/api';
import { useCart, useStore } from '../../../utils/contexts';
import useFetch from '../../../utils/useFetch';
import ProductCard from '../../home-components/cards/ProductCard';

const BrandProductSection = ({ brandId }) => {
     console.log('brandId', brandId);
    const [productList, setProductList] = useState([]);
    const [pageCount, setPageCount] = useState(2);
    const [hasMore, setHasMore] = useState(true);

    const cartData = useCart();
    const API = useAPI();
    const storeData = useStore();
    console.log('API',API);
     console.log('CART',cartData);
     console.log('STORE', storeData);
  console.log(productList);


    const {data,loading,status} = useFetch("searchProductsByBrand", brandId);
    console.log('res1',data);

    useEffect(() => {
        if (!loading && status === "fetched" && data.data && data.meta) {
             console.log(data);
            setProductList(data.data);
            setHasMore(data.meta.pagination.current_page < data.meta.pagination.total_pages);
        }
    }, [data, status, loading])
    

    async function loadProducts() {
        const [ok, response] = await API.searchProducts(brandId);
        if (ok && response) {
             
            setProductList([...productList, ...response.data]);
            setHasMore(response.meta.pagination.current_page < response.meta.pagination.total_pages);
            setPageCount(prev=> prev + 1)
        }
    }

    return (
        //  <>hello</>
         !loading && status === "fetched" && <>
         {productList.length !== 0 ?
         <InfiniteScroll
             dataLength={productList.length}
             next={loadProducts}
             hasMore={hasMore}
             loader={<div className="spinner-border" role="status"></div>}
             className="overflow-hidden"
         >
             <div className='d-flex flex-wrap'>
                 {productList.map(product=> {
                     return product.variants && product.variants[0] && <ProductCard className="mb-3 product-card-for-category" cart={cartData} key={product.id} color={{
                         primary_color: storeData.data.store.primary_color,
                         secondary_color: storeData.data.store.secondary_color
                     }} product={product} category="category"></ProductCard>
                 })
                 }
             </div>
         </InfiniteScroll> : 
         <div className='d-flex flex-wrap justify-content-center align-items-center'>
                 <p>No product available in this brand.</p>
             </div>}
         </>
    )
}

export default BrandProductSection