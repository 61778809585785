import {
    Splide,
    SplideSlide
} from '@splidejs/react-splide';
import React from 'react';
import styled from "styled-components";
import { useCart, useStore } from '../../utils/contexts';
import { useWindowSize } from '../../utils/useWindowSize';
import ProductCard from './cards/ProductCard';

const RecentProductSlides = ({customizedData, color, primary_color, secondary_color}) => {
    const storeData = useStore();
    const cartData = useCart();
    const [width, height] = useWindowSize();
    const productSlidesSettings = {
        pagination: false,
        drag: 'free',
        snap: true,
        perMove: 1,
        perPage: 6,
        padding: "50px",
        autoplay: true,
        focus  : 'center',
        breakpoints: {
            1330: {
                perPage: 5
            },
            1128: {
                perPage: 4
            },
            920: {
                perPage: 3
            },
            730: {
                perPage: 3
            },
            545: {
                perPage: 2
            },
            371: {
                perPage: 1
            }
        },
    };

    const isSlideNeeded = () => {
        const arr = Object.keys(productSlidesSettings.breakpoints).reverse();
        let result;
        for(let i=0; i < arr.length; i++){
            if(width >= arr[i]){
                result = productSlidesSettings.breakpoints[arr[i]].perPage;
                break;
            }
        }
        return result;
    }

    const ProductContainer = styled.div`
        margin-top: 20px;
        margin-bottom: 20px;
        & .splide__arrow{
            background-color: ${(primary_color || (color && color.primary_color)) + '!important'};
        }

        & .splide__arrow svg{
            fill: white;
        }
    `;

    return (<ProductContainer>
        <h4 className='ms-4 ms-sm-5'>
            {customizedData && customizedData.component_heading}
        </h4>
        { storeData && storeData.data && storeData.data.products &&
        <>
        {storeData.data.products.length -1 <= isSlideNeeded() ?
            <div className="d-flex">
                {storeData.data.products && storeData.data.products.map((value, index)=>{
                    return value.variants && value.variants[0] && <ProductCard cart={cartData} key={index} color={color} product={value}></ProductCard>
                })}
            </div>
            :
            <Splide options={productSlidesSettings}>
                {storeData.data.products && storeData.data.products.map((value, index)=>{
                    return value.variants && value.variants[0] && <SplideSlide><ProductCard cart={cartData} key={index} color={color} product={value}></ProductCard></SplideSlide>
                })}
            </Splide>}
        </>}
    </ProductContainer>)
}

export default RecentProductSlides