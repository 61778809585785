import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import styled from "styled-components";
import BrandCard from "./cards/BrandCard";
import { useCart,useStore } from "../../utils/contexts";
import { useWindowSize } from "../../utils/useWindowSize";

const BrandSlides = ({
  customizedData,
  color,
  primary_color,
  secondary_color,
}) => {
  const storeData = useStore();
  const [width, height] = useWindowSize();

  const brandSlidesSettings = {
    pagination: false,
    drag: "free",
    snap: true,
    perMove: 1,
    perPage: 6,
    padding: "50px",
    autoplay: true,
    focus: "center",
    breakpoints: {
      1330: {
        perPage: 5,
      },
      1128: {
        perPage: 4,
      },
      920: {
        perPage: 3,
      },
      730: {
        perPage: 3,
      },
      545: {
        perPage: 2,
      },
      371: {
        perPage: 1,
      },
    },
  };

  const isSlideNeeded = () => {
    const arr = Object.keys(brandSlidesSettings.breakpoints).reverse();
    let result;
    for (let i = 0; i < arr.length; i++) {
      if (width >= arr[i]) {
        result = brandSlidesSettings.breakpoints[arr[i]].perPage;
        break;
      }
    }
    return result;
  };

  const BrandContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    & .splide__arrow {
      background-color: ${(primary_color || (color && color.primary_color)) +
      "!important"};
    }

    & .splide__arrow svg {
      fill: white;
    }
  `;

  return (
    <BrandContainer>
      {storeData && storeData.data && storeData.data.brands && storeData.data.brands.length > 0 && (
        <h4 className="ms-4 ms-sm-5 border-bottom pb-1 mb-2">
          {customizedData && customizedData.component_heading}
        </h4>
      )}
      {storeData && storeData.data && storeData.data.brands && (
        <>
          {storeData.data.brands.length - 1 <= isSlideNeeded() ? (
            <div className="d-flex ms-4 ms-sm-5">
              {storeData.data.brands &&
                storeData.data.brands.map((value, index) => {
                  return (
                    <BrandCard
                      key={index}
                      color={color}
                      brand={value}
                    ></BrandCard>
                  );
                })}
            </div>
          ) : (
            <Splide options={brandSlidesSettings}>
              {storeData.data.brands &&
                storeData.data.brands.map((value, index) => {
                  return (
                    <SplideSlide key={index}>
                      <BrandCard
                        color={color}
                        brand={value}
                      ></BrandCard>
                    </SplideSlide>
                  );
                })}
            </Splide>
          )}
        </>
      )}
    </BrandContainer>
  );
};

export default BrandSlides;
