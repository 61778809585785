import React from "react";
import styled from "styled-components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useWindowSize } from "../../utils/useWindowSize";
import SmallCategory from "./cards/SmallCategory";

const StickyCategorySlides = ({
    sticky_category_sliders,
    customizedData,
    color,
    primary_color,
    secondary_color,
}) => {
    const [width, height] = useWindowSize();

    const categorySlidesSettings = {
        pagination: false,
        drag: "free",
        snap: true,
        perMove: 1,
        perPage: 8,
        padding: "50px",
        autoplay: true,
        focus: "center",
        breakpoints: {
            1330: { perPage: 7 },
            1235: { perPage: 6 },
            1060: { perPage: 5 },
            920: { perPage: 4 },
            650: { perPage: 3 },
            470: { perPage: 2 },
            371: { perPage: 1 },
        },
    };

    const isSlideNeeded = () => {
        const arr = Object.keys(categorySlidesSettings.breakpoints).reverse();
        let result;
        for (let i = 0; i < arr.length; i++) {
            if (width >= arr[i]) {
                result = categorySlidesSettings.breakpoints[arr[i]].perPage;
                break;
            }
        }
        return result;
    };

    const CategoryContainer = styled.div`
        margin-top: 20px;
        margin-bottom: 20px;

        & .splide__arrow {
            background-color: ${(primary_color || (color && color.primary_color)) + "!important"};
        }

        & .splide__arrow svg {
            fill: white;
        }

        @media screen and (max-width: 701px) {
            & .splide__slide {
                margin-right: 5px;
                margin-left: 70px;
            }
        }
    `;

    return (
        
        <CategoryContainer>
            <h4 className="ms-4 ms-sm-5">
                {sticky_category_sliders && sticky_category_sliders.component_heading}
            </h4>
            {customizedData && (
                <>
                    {customizedData.length - 1 <= isSlideNeeded() ? (
                        <div className="d-flex mx-4 mx-sm-5 my-4">
                            {customizedData &&
                                customizedData.map((value, index) => (
                                    <SmallCategory key={index} categoryData={value} />
                                ))}
                        </div>
                    ) : (
                        <Splide options={categorySlidesSettings}>
                            {customizedData &&
                                customizedData.map((value, index) => (
                                    <SplideSlide key={index}>
                                        <SmallCategory categoryData={value} />
                                    </SplideSlide>
                                ))}
                        </Splide>
                    )}
                </>
            )}
        </CategoryContainer>
    );
};

export default StickyCategorySlides;
