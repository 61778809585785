import {
    Splide,
    SplideSlide
} from '@splidejs/react-splide';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import ChristmasCard from '../ChristmasCard';
import Newyear from '../Newyear';
import SmallCategory from './cards/SmallCategory';

const CategorySlides = ({ addon, customizedData, categories, color, primary_color }) => {
    const [subcategory, setSubcategory] = useState();
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const categorySlidesSettings = {
        pagination: false,
        drag: 'free',
        snap: true,
        perMove: 1,
        perPage: 8,
        padding: "50px",
        autoplay: true,
        focus: 'center',
        breakpoints: {
            1380: {
                perPage: 7
            },
            1235: {
                perPage: 6
            },
            1060: {
                perPage: 5
            },
            920: {
                perPage: 4
            },
            650: {
                perPage: 3
            },
            470: {
                perPage: 2
            },
            371: {
                perPage: 1
            },
        },
    };

    const CategoryContainer = styled.div`
        margin-top: 20px;
        margin-bottom: 20px;
        & .splide__arrow{
            background-color: ${(primary_color || (color && color.primary_color)) + '!important'};
        }

        & .splide__arrow svg{
            fill: white;
        }
        @media screen and (max-width: 701px) {
            & .splide__slide {
                
                margin-right: 5px;  
                margin-left: 70px;   
            }
        }
    `;

    useEffect(() => {
        setSubcategory(() => {
            let temp = [];
            categories.forEach(category => {
                category.subCategory.forEach(subCate => {
                    temp.push(subCate);
                })
            })
            return temp;
        })
    }, []);
    return (<CategoryContainer>
    
        
        {(addon && addon.status === "active") ? <Newyear /> :''}
        
        {(addon && addon.christmas_theme === "active") ? <ChristmasCard /> : ""}    
        
    
        
        {customizedData.display === 1 ? <>
            <h4 className='ms-4 ms-sm-5'>
            {customizedData && customizedData.component_heading}
        </h4>
        <Splide options={categorySlidesSettings}>
            {subcategory && subcategory.map((value, index)=>{
                return <SplideSlide style={{margin:"0px"}}><SmallCategory key={index} categoryData={value}></SmallCategory></SplideSlide>
            })}
        </Splide> 
        </>:''}

        

    </CategoryContainer>)
}

export default CategorySlides
 