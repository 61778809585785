import React, { useEffect, useState } from "react";
import { CheckmarkCircleOutline } from "react-ionicons";
import { Link, useHistory } from "react-router-dom";
import useAPI, { REACT_APP_API_HOST } from "../../utils/api";
import { useCart, useLoader } from "../../utils/contexts";
import { getAuthToken } from "../../utils/storageService";

const PaymentStatusPage = () => {
    const history = useHistory();
    const API = useAPI();
    const cartData = useCart();
    const loader = useLoader();
    const [paymentStatus, setPaymentStatus] = useState();
    
    const readCookie = (name) => {
        const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(name));

    const cookieContent = cookieValue ? cookieValue.split("=")[1] : null;

    return cookieContent ? decodeURIComponent(cookieContent) : null;
    };

    const token = readCookie("token");
    const auth = getAuthToken();


    async function getConfirmation(){
        const queryString = window.location.search;
        if (queryString.includes("order_id")) {
            const queryParams = new URLSearchParams(window.location.search);
            let order_id = queryParams.get("order_id");
            let params = window.location.pathname.split("/")[2];
            let gateway, status;
            if (params == 1) {
                gateway = "ONLINE_CASHFREE";
            } else if (params == 2) {
                gateway = "ONLINE_PHONEPE";
            } else if (params == 3) {
                gateway = "ONLINE_RAZORPAY";
                status = queryParams.get("status");
            }

            if(token && !auth && (gateway === "ONLINE_CASHFREE" || gateway === "ONLINE_PHONEPE")) {
                loader.setLoaderOpen(true);
                const response = await fetch(
                    `${REACT_APP_API_HOST}pay/confirm?order_id=${order_id}&gateway=${gateway}`,
                    {
                        headers: {
                            Authorization: `Bearer ${
                                token
                            }`,
                            "x-ik-domain": localStorage.getItem(
                                "ik-domain"
                            ),
                        },
                    }
                )
                const confirmRes = await response.json()
                if(confirmRes.status == "success") {
                    window.location.href=`${
                        window.location.origin
                    }/success/${params}?order_id=${order_id}`
                } else {
                    window.location.href=`${
                        window.location.origin
                    }/failure/${params}?order_id=${order_id}`
                }
                loader.setLoaderOpen(false);
            }

            if(token && !auth && gateway === "ONLINE_RAZORPAY") {
                if (status == 'success') {
                    window.location.href=`${
                        window.location.origin
                    }/success/${params}?order_id=${order_id}`
                } else {
                    window.location.href=`${
                        window.location.origin
                    }/failure/${params}?order_id=${order_id}`
                }
            }

            if(auth && gateway == "ONLINE_RAZORPAY") {
                if (status == 'success') {
                    const newCart = await API.deleteCart();
                    if(newCart[0] && newCart[1] && newCart[1].data){
                        cartData.setMyCartData(newCart[1].data);
                    }
                } else {
                    alert("Payment Failed");
                    history.push("/cart");
                }
                setPaymentStatus(status)
            }


            if (auth && (gateway === "ONLINE_CASHFREE" || gateway === "ONLINE_PHONEPE")) {
                loader.setLoaderOpen(true);
                const [ok, response] = await API.paymentConfirmation(order_id, gateway);
                if(ok && response){
                    if (response.status == 'success') {
                        const newCart = await API.deleteCart();
                            if(newCart[0] && newCart[1] && newCart[1].data){
                                cartData.setMyCartData(newCart[1].data);
                            }
                    } else {
                        alert("Payment Failed");
                        history.push("/cart");
                    }
                    setPaymentStatus(response.status)
                }else{
                    if(response && response.error){
                        alert(response.error);
                    }
                }
                loader.setLoaderOpen(false);
            }
        } else {
            console.log("doesn't have order_id");
            history.push("/cart");
        }
    }

    useEffect(() => {
        getConfirmation();
    }, []);

    return (
        <div>
            { paymentStatus == 'success' && auth ? <div className="text-center pt-5">
                <CheckmarkCircleOutline color="#008000" width="120px" height="120px"/>
                <p>Payment is successful. Order Placed Successfully!</p>
                <Link to={`/`} className="btn web-primary-color-button">Continue Shopping</Link>
            </div> : ''}
        </div>
    );
};

export default PaymentStatusPage;
