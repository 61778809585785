import React from "react";
import { WhatsappIcon } from "react-share";

const WhatsappChatWidget = ({ url, phone }) => {
    const whatsappText = encodeURIComponent(`${url} Hello, I need some help.`);
    return (
        <span
            className="mr-md-4 mb-md-5"
            style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                boxShadow: "0 0 10px -4px",
                marginBottom: "16px",
                marginRight: "16px"
            }}
        >
            <a
                href = {`https://wa.me/${phone}?text=${whatsappText}`}
                target="_blank"
            >
                <WhatsappIcon size="3rem" borderRadius={5} />
            </a>
        </span>
    );
};

export default WhatsappChatWidget;
