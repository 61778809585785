import { createContext, useContext } from 'react';

export const StoreContext = createContext({});

export const useStore = () => {
  return useContext(StoreContext);
};

export const CartContext = createContext({});

export const useCart = () => {
  return useContext(CartContext);
};

export const LoaderContext = createContext({});

export const useLoader = () => {
  return useContext(LoaderContext);
};