import React from 'react';
import useFetch from '../../../utils/useFetch';

const DateTime = ({ deliveryDate, setDeliveryDate, deliveryTime, setDeliveryTime }) => {
    const today = new Date().toISOString().split('T')[0];
    const { data, status, loading } = useFetch("getDateTime");
    // console.log(data, status, loading)
    
    return (status === "fetched" && !loading &&
        <div className="border rounded shadow m-3 p-2">
            <div className="card-body">
                <div>
                    <h5 className="card-title mb-3 p-3 web-secondary-color-button">Select Delivery Date:</h5>
                </div>
                <div className="row">
                    <div className="col-sm-6 my-1 my-sm-0">
                        <input
                            type="date"
                            className="form-control"
                            value={deliveryDate}
                            onChange={e => setDeliveryDate(e.target.value)}
                            min={today}
                            placeholder="Select delivery date"
                        />
                    </div>
                    <div className="col-sm-6 my-1 my-sm-0">
                        <select
                            className="form-select"
                            value={deliveryTime}
                            onChange={e => setDeliveryTime(e.target.value)}
                            required
                        >
                            <option value="">Select a Time Slot</option>
                            {data && data.time_slot && data.time_slot.map((time) => {
                                return <option value={time}> {time} </option>
                            })
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateTime