export const getAuthToken = () =>{
    const auth = localStorage.getItem("auth") && JSON.parse(localStorage.getItem("auth"));
    return auth && auth.token
}

export const getUserId = () =>{
    const auth = localStorage.getItem("auth") && JSON.parse(localStorage.getItem("auth"));
    return auth && auth.user && auth.user.id
}

export const getDomain = () =>{
    return localStorage.getItem("ik-domain");
}

export const getQuickOrderImage = () =>{
    return localStorage.getItem("quick-order-image") && JSON.parse(localStorage.getItem("quick-order-image"));
}

export const getCartProducts = () =>{
    const storeData = (localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"))) || [];
    return {data: {products: storeData}}
}

export const updateCartItem = (productDetails, qty) => {
    let storeData = (localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"))) || [];
    let preExistingProduct = false;
    if(storeData) {
        storeData.forEach((cartItem)=> {
            if(cartItem.productId === productDetails.id && cartItem.variant_index === productDetails.variant_index) {
                preExistingProduct = true;
                cartItem.qty = qty;
            }
        });
        if(preExistingProduct) {
            localStorage.setItem("cart", JSON.stringify(storeData));
        } else{
            storeData.push({...productDetails, qty: qty});
            localStorage.setItem("cart", JSON.stringify(storeData));
        }
        return storeData;
    }
}

export const deleteCartItem = (productId, variant_index) => {
    let storeData = (localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"))) || [];
    if(storeData) {
        const filterData = storeData.filter((cartItem)=> (cartItem.productId !== productId || cartItem.variant_index !== variant_index));
        localStorage.setItem("cart", JSON.stringify(filterData));
        return filterData;
    }
    return null;
}

export const setQuickOrder = (image) => {
    let storeData = (localStorage.getItem("quick-order-image") && JSON.parse(localStorage.getItem("quick-order-image"))) || [];
    storeData.push(image);
    localStorage.setItem('quick-order-image', JSON.stringify(storeData));  
}

export const deleteQuickOrder = (value) => {
    let storeData = (localStorage.getItem("quick-order-image") && JSON.parse(localStorage.getItem("quick-order-image"))) || [];
    if(storeData){
        const index = storeData.indexOf(value);
        if(index !== -1){
            storeData.splice(index, 1);
        }
    }
    localStorage.setItem('quick-order-image', JSON.stringify(storeData));  
}
